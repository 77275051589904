import { format, isSameDay } from "date-fns";
import { ja } from "date-fns/locale";
import { FC, useCallback, useState } from "react";
import { Backdrop, Box, CircularProgress, Grid, Typography } from "@mui/material";
import { EventInput, EventSourceInput } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import allLocales from "@fullcalendar/core/locales-all";
import { useMutation, useQuery } from "react-query";
import { date2string, time2string } from "../../utils/converter";
import { getPeriodDateList } from "../../utils/datetime";
import Time from "../../types/Time";
import useClinicHomePageState from "./useClinicHomePageState";
import {
  getReservationBaseFrames,
  getReservationBaseSettings,
  getReservationDayFrames,
  getReservationDaySettingsFromDate
} from "../../repositories/reservationSettingRepository";
import IReservationSetting from "../../interfaces/IReservationSetting";
import { getReservationInformationAtDate } from "../../repositories/reserveRepository";
import { getClinicInformation } from "../../repositories/clinicRepository";
import IReservationInformation from "../../interfaces/IReservationInformation";
import IReservationFrame from "../../interfaces/IReservationFrame";
import { calcCurrentNumberOfPeople, calcReservePoints, numOfPeopleStr } from "../../utils/points";

type CalendarContentProps = {
  clinicId: string;
}

/**
 * カレンダー表示のコンテンツコンポーネント
 * @param clinicId クリニックID
 * @constructor
 * @group Components
 * @category features/HomePage
 */
const CalendarContent: FC<CalendarContentProps> = ({ clinicId }) => {
  const [ isEventLoading, setIsEventLoading ] = useState(false);
  const [ events, setEvents ] = useState<EventInput[]>([]);
  
  const { onChangeCurrentDate } = useClinicHomePageState();
  const [ slotMinTime, setSlotMinTime ] = useState('');
  const [ slotMaxTime, setSlotMaxTime ] = useState('');
  const [ tapEvent, setTapEvent ] = useState('');
  
  /**
   * 予約済み合計消費ポイント数(各予約枠)
   */
  const frameSumPoints = useCallback((reservationInformation: IReservationInformation[], startTime: Time): number =>
      reservationInformation
        .filter((item) => item.startHour === startTime.hour && item.startMinute === startTime.minute)
        .filter((item) => item.reserveStatus !== 9)
        .reduce((sum, el) => sum + el.point, 0),
    []
  );
  
  /**
   * 予約枠設定の合計ポイント数
   */
  const dayFrameSumPeople = useCallback(
    (reservationFrames: IReservationFrame[]) => {
      let sum = 0;
      for (let i = 0; i < reservationFrames.length; i += 1) {
        const frame = reservationFrames[i];
        sum += frame.numberOfPeople;
      }
      return sum;
    }, []
  );
  
  /**
   * 予約済み合計消費ポイント数(選択中の日付全体)
   */
  const daySumPeople = useCallback(
    (reservationFrames: IReservationFrame[], reservationInformation: IReservationInformation[]) => {
      let sum = 0;
      for (let i = 0; i < reservationFrames.length; i += 1) {
        const frame = reservationFrames[i];
        const framePoints = frameSumPoints(reservationInformation, frame.startAt);
        sum += calcCurrentNumberOfPeople(framePoints, calcReservePoints(frame.numberOfPeople, frame.points))
      }
      return sum;
    },
    [ frameSumPoints ]
  );
  
  /**
   * 予約枠基本設定の取得クエリ
   */
  const { data: reservationBaseSettings } = useQuery([ 'getReservationBaseSettings', clinicId ],
    () => getReservationBaseSettings(clinicId),
  );
  
  const { data: clinicInfo } = useQuery(
    [ 'getClinicInformation', clinicId ],
    () => getClinicInformation(clinicId)
  );
  
  /**
   * 診療時間（Min）情報
   */
  const getSlotMinTime = useCallback((daySettings: IReservationSetting[]) => {
    if (daySettings == null) {
      return '';
    }
    if (reservationBaseSettings?.length !== 7) {
      return '';
    }
    const daySettingsStartAt: Time[] = [];
    daySettings.forEach(daySetting => {
      daySettingsStartAt.push(daySetting.openAt);
    });
    const startAtList = [ ...daySettingsStartAt, reservationBaseSettings[0].openAt, reservationBaseSettings[1].openAt,
      reservationBaseSettings[2].openAt, reservationBaseSettings[3].openAt, reservationBaseSettings[4].openAt,
      reservationBaseSettings[5].openAt, reservationBaseSettings[6].openAt ].filter(e => e !== undefined);
    startAtList.sort((first, second) => {
      if (!first || !second) {
        return 0;
      }
      if (first.hour < second.hour) {
        return -1;
      }
      if (first.hour === second.hour) {
        if (first.minute < second.minute) {
          return -1;
        }
      }
      return 1;
    });
    const minTime = startAtList[0];
    let result
    if (minTime.minute < 30) {
      result = `${minTime.hour}:00`
    } else {
      result = `${minTime.hour}:30`
    }
    return result;
  }, [ reservationBaseSettings ]);
  
  /**
   * 診療時間（Max）情報
   */
  const getSlotMaxTime = useCallback((daySettings: IReservationSetting[]) => {
    if (daySettings == null) {
      return '';
    }
    if (reservationBaseSettings?.length !== 7) {
      return '';
    }
    const daySettingsEndAt: Time[] = [];
    daySettings.forEach(daySetting => {
      daySettingsEndAt.push(daySetting.closeAt);
    });
    const endAtList = [ ...daySettingsEndAt, reservationBaseSettings[0].closeAt, reservationBaseSettings[1].closeAt,
      reservationBaseSettings[2].closeAt, reservationBaseSettings[3].closeAt, reservationBaseSettings[4].closeAt,
      reservationBaseSettings[5].closeAt, reservationBaseSettings[6].closeAt ].filter(e => e !== undefined);
    endAtList.sort((first, second) => {
      if (!first || !second) {
        return 0;
      }
      if (first.hour > second.hour) {
        return -1;
      }
      if (first.hour === second.hour) {
        if (first.minute > second.minute) {
          return -1;
        }
      }
      return 1;
    });
    return time2string(endAtList[0]);
  }, [ reservationBaseSettings ]);
  
  /**
   * 予約枠設定がない日のイベントデータ
   */
  const getNoneEvent = useCallback((date: Date) => ({
    start: `${date2string(date)}`,
    display: "background",
    color: "#d3d3d3",
  }), []);
  
  /**
   * イベントデータ１件分
   */
  const getEvent = useCallback((setting: IReservationSetting, reservePeople: number, totalPeople: number, date: Date) => ({
    id: `${date2string(date)}`,
    title: `${numOfPeopleStr(reservePeople)} / ${numOfPeopleStr(totalPeople)}`,
    startStr: time2string(setting.openAt),
    endStr: time2string(setting.closeAt),
    start: `${date2string(date)} ${time2string(setting.openAt)}`,
    end: `${date2string(date)} ${time2string(setting.closeAt)}`,
    backgroundColor: setting.isReserveFramesShow ? '#9FDAF8' : '#d3d3d3',
    borderColor: setting.isReserveFramesShow ? '#9FDAF8' : '#d3d3d3',
    color: setting.isReserveFramesShow ? '#9FDAF8' : '#d3d3d3',
    allDay: true,
    description: setting.isReserveFramesShow ? '' : '非公開'
  }), []);
  
  /**
   * カレンダーに表示するPt集計イベントの取得処理
   * @param start 開始日
   * @param end 終了日
   */
  const getReservationEvents = async (start: Date, end: Date) => {
    const dayReservationEvents: EventSourceInput | undefined = [];
    const baseReservationEvents: EventSourceInput | undefined = [];
    const noneEvents: EventSourceInput | undefined = [];
    
    // StartからEndまでの指定日予約枠情報取得
    const daySettings = await getReservationDaySettingsFromDate(clinicId, start.getTime(), end.getTime());
    setSlotMinTime(getSlotMinTime(daySettings));
    setSlotMaxTime(getSlotMaxTime(daySettings));
    
    const dateList = getPeriodDateList(start, end);
    for (let i = 0; i < dateList.length; i += 1) {
      const date = dateList[i];
      // DaySettingの存在判定
      const daySettingIndex = daySettings.findIndex((value) => isSameDay(value.dayOfWeekOrDate, date))
      const setting = daySettingIndex !== -1 ? daySettings[daySettingIndex] : undefined;
      // この日の予約を取得
      const reserves = await getReservationInformationAtDate(clinicId, date);
      
      if (setting) {
        // DaySettingあり
        const frames = await getReservationDayFrames(clinicId, date)
        const reservesPeople = daySumPeople(frames, reserves)
        const totalPeople = dayFrameSumPeople(frames)
        dayReservationEvents.push(getEvent(setting, reservesPeople, totalPeople, date))
      } else {
        // DaySetting無し
        const dateFormat = 'EEE';
        const dayOfWeek = format(date, dateFormat, { locale: ja });
        if (!reservationBaseSettings) {
          noneEvents.push(getNoneEvent(date))
          continue;
        }
        switch (dayOfWeek) {
          case '日':
            if (reservationBaseSettings[0].isOpen) {
              const reservationFrames = await getReservationBaseFrames(clinicId, 'sun');
              const reservesPeople = daySumPeople(reservationFrames, reserves)
              const totalPeople = dayFrameSumPeople(reservationFrames)
              baseReservationEvents.push(getEvent(reservationBaseSettings[0], reservesPeople, totalPeople, date))
            } else {
              noneEvents.push(getNoneEvent(date));
            }
            break
          case '月':
            if (reservationBaseSettings[1].isOpen) {
              const reservationFrames = await getReservationBaseFrames(clinicId, 'mon');
              const reservesPeople = daySumPeople(reservationFrames, reserves)
              const totalPeople = dayFrameSumPeople(reservationFrames)
              baseReservationEvents.push(getEvent(reservationBaseSettings[1], reservesPeople, totalPeople, date))
            } else {
              noneEvents.push(getNoneEvent(date));
            }
            break
          case '火':
            if (reservationBaseSettings[2].isOpen) {
              const reservationFrames = await getReservationBaseFrames(clinicId, 'tue');
              const reservesPeople = daySumPeople(reservationFrames, reserves)
              const totalPeople = dayFrameSumPeople(reservationFrames)
              baseReservationEvents.push(getEvent(reservationBaseSettings[2], reservesPeople, totalPeople, date))
            } else {
              noneEvents.push(getNoneEvent(date));
            }
            break
          case '水':
            if (reservationBaseSettings[3].isOpen) {
              const reservationFrames = await getReservationBaseFrames(clinicId, 'wed');
              const reservesPeople = daySumPeople(reservationFrames, reserves)
              const totalPeople = dayFrameSumPeople(reservationFrames)
              baseReservationEvents.push(getEvent(reservationBaseSettings[3], reservesPeople, totalPeople, date))
            } else {
              noneEvents.push(getNoneEvent(date));
            }
            break
          case '木':
            if (reservationBaseSettings[4].isOpen) {
              const reservationFrames = await getReservationBaseFrames(clinicId, 'thu');
              const reservesPeople = daySumPeople(reservationFrames, reserves)
              const totalPeople = dayFrameSumPeople(reservationFrames)
              baseReservationEvents.push(getEvent(reservationBaseSettings[4], reservesPeople, totalPeople, date))
            } else {
              noneEvents.push(getNoneEvent(date));
            }
            break
          case '金':
            if (reservationBaseSettings[5].isOpen) {
              const reservationFrames = await getReservationBaseFrames(clinicId, 'fri');
              const reservesPeople = daySumPeople(reservationFrames, reserves)
              const totalPeople = dayFrameSumPeople(reservationFrames)
              baseReservationEvents.push(getEvent(reservationBaseSettings[5], reservesPeople, totalPeople, date))
            } else {
              noneEvents.push(getNoneEvent(date));
            }
            break
          case '土':
            if (reservationBaseSettings[6].isOpen) {
              const reservationFrames = await getReservationBaseFrames(clinicId, 'sat');
              const reservesPeople = daySumPeople(reservationFrames, reserves)
              const totalPeople = dayFrameSumPeople(reservationFrames)
              baseReservationEvents.push(getEvent(reservationBaseSettings[6], reservesPeople, totalPeople, date))
            } else {
              noneEvents.push(getNoneEvent(date));
            }
            break
          default:
            // eslint-disable-next-line no-console
            console.log('Not exists day of week')
        }
      }
    }
    return [ ...dayReservationEvents, ...baseReservationEvents, ...noneEvents ];
  }
  
  /**
   * カレンダーに表示するPt集計イベントの取得Mutation
   */
  const loadEventsMutate = useMutation(
    (val: { start: Date, end: Date }) => getReservationEvents(val.start, val.end),
    {
      onSuccess: (data) => {
        setEvents(data)
        setIsEventLoading(false)
      }
    }
  )
  
  /**
   * カレンダーのイベントクリック時の処理
   */
  const onClickEvent = useCallback((eventId: string) => {
    const beforeTappedEventId = tapEvent;
    const currentEvents = [ ...events ];
    if (eventId !== '') {
      const tapEventIndex = currentEvents.findIndex((el) => el.id === eventId);
      if (tapEventIndex !== -1) {
        const tmp = currentEvents[tapEventIndex];
        tmp.color = '#bc8f8f';
        tmp.backgroundColor = '#27414f';
        tmp.borderColor = '#27414f';
        currentEvents.splice(tapEventIndex, 1, tmp);
      }
      if (beforeTappedEventId !== '') {
        const beforeTapEventIndex = currentEvents.findIndex((el) => el.id === tapEvent);
        if (beforeTapEventIndex !== -1) {
          const tmp = currentEvents[beforeTapEventIndex];
          tmp.color = tmp.description !== '非公開' ? '#9FDAF8' : '#d3d3d3';
          tmp.backgroundColor = tmp.description !== '非公開' ? '#9FDAF8' : '#d3d3d3';
          tmp.borderColor = tmp.description !== '非公開' ? '#9FDAF8' : '#d3d3d3';
          currentEvents.splice(beforeTapEventIndex, 1, tmp);
        }
      }
      setTapEvent(eventId);
      setEvents(currentEvents);
    }
  }, [ events, tapEvent ])
  
  return (
    <>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item xs={12} md={10} lg={8} mt={2}>
          <Box style={{ overflow: 'auto', }}>
            <Box style={{ minWidth: '540px' }}>
              <FullCalendar
                plugins={[ listPlugin, timeGridPlugin, dayGridPlugin, interactionPlugin ]}
                initialView="dayGridMonth"
                locales={allLocales}
                locale="ja"
                height='auto'
                titleFormat={{
                  year: 'numeric',
                  month: 'short'
                }}
                eventTimeFormat={{ hour: "2-digit", minute: "2-digit" }}
                slotLabelFormat={[ { hour: "2-digit", minute: "2-digit" } ]}
                slotDuration="00:15:00"
                slotMinTime={slotMinTime}
                slotMaxTime={slotMaxTime}
                firstDay={clinicInfo?.calendarStart ?? 0}
                headerToolbar={{
                  start: 'today prev',
                  center: 'title',
                  end: 'next listMonth,dayGridMonth,timeGridWeek'
                }}
                initialDate={new Date()}
                events={events}
                buttonText={{
                  today: '今日',
                  month: '月',
                  day: '日',
                  listMonth: 'リスト',
                }}
                datesSet={(arg) => {
                  setIsEventLoading(true)
                  loadEventsMutate.mutate({ start: arg.start, end: arg.end });
                }}
                eventClick={
                  (arg) => {
                    if (tapEvent === arg.event.id) {
                      return;
                    }
                    onChangeCurrentDate(new Date(date2string(arg.event.start as Date)));
                    onClickEvent(arg.event.id);
                  }
                }
                dayHeaderContent={
                  (args) => {
                    if (args.view.type === "listMonth") {
                      return format(
                        args.date,
                        "MM/dd (EEE)",
                        {
                          locale: ja,
                        })
                    }
                    return format(
                      args.date,
                      "d(EEE)",
                      {
                        locale: ja,
                      })
                  }
                }
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} mb={2}>
          <Typography variant='caption' color='text.secondary'>
            カレンダーの情報を更新するには、”日ごとの予約状況”に切り替えてから、再度このタブへ切り替えてください。
          </Typography>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isEventLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default CalendarContent;
