import { useAuthUser } from '@react-query-firebase/auth';
import { ReactNode, FC, Suspense } from 'react';
import { Navigate } from 'react-router';
import { CircularProgress } from "@mui/material";
import { useQuery } from "react-query";
import { auth } from '../../../firebase';
import { getClinicInformation } from "../../../repositories/clinicRepository";
import RegisterPage from "../RegisterPage/RegisterPage";

type AuthGuardProps = {
  /** 子供コンポーネント */
  children: ReactNode;
  /** ログインしていない状態でしか表示できないページかどうか */
  isNotLoggedInOnly?: boolean;
};

const AuthGuardContent: FC<AuthGuardProps> = ({ children, isNotLoggedInOnly = false }) => {
  const user = useAuthUser([ 'user' ], auth);
  const { data, isLoading } = useQuery(
    [ 'getClinicInformation', user.data?.uid ?? '' ],
    () => getClinicInformation(user.data?.uid ?? '')
  );
  
  // ログインしていない状態でしか表示できないページ
  if (isNotLoggedInOnly) {
    if (user.data) {
      return <Navigate to="/" />;
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  
  if (!user.data) {
    // TODO: ログインしてくださいのアラート表示
    return <Navigate to="/login" />;
  }
  if (isLoading) {
    return <CircularProgress />;
  }
  if (data && !data.isInitialized) {
    return <RegisterPage />;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

/**
 * 認証ページアクセス制限コンポーネント
 * @param isNotLoggedInOnly ログインしていない状態でしか表示できないページかどうか
 * @param children 子供コンポーネント
 * @constructor
 * @group Components
 * @category features/auth
 */
const AuthGuard: FC<AuthGuardProps> = ({ isNotLoggedInOnly = false, children }) => <Suspense
  fallback={<CircularProgress />}>
  <AuthGuardContent isNotLoggedInOnly={isNotLoggedInOnly}>{children}</AuthGuardContent>
</Suspense>

export default AuthGuard;
