import { format } from "date-fns";
import { FirebaseError } from "firebase/app";
import { ReactNode } from "react";
import Time from "../types/Time";
import { Gender, GenderStr } from "../types/Gender";
import { ReserveStatus, ReserveStatusStr } from "../types/ReserveStatus";
import { DayOfWeek, DayOfWeekStr } from "../types/DayOfWeek";

/**
 * string型からTime型へ。
 * @param time string型
 * @return {Time} Time型が返る。
 * @category utils
 */
export const string2time = (time: string): Time => {
  const strArr = time.split(':');
  const hourStr = strArr[0];
  const minuteStr = strArr[1];
  const hour = parseInt(hourStr, 10);
  const minute = parseInt(minuteStr, 10);
  return { hour, minute, };
};

/**
 * Time型からstring型へ
 * @param time Time型
 * @return {string} string型(hh:mm)が返る。
 * @category utils
 */
export const time2string = (time: Time): string => {
  const hourStr = `${time.hour}`.padStart(2, '0');
  const minuteStr = `${time.minute}`.padStart(2, '0');
  return `${hourStr}:${minuteStr}`;
};

/**
 * Date型からstring型へ
 * @param date Date型
 * @return {string} string型(yyyy-MM-dd)が返る。
 * @category utils
 */
export const date2string = (date: Date): string => format(date, 'yyyy-MM-dd')

/**
 * Date型からDayOfWeek型へ
 * @param date Date型
 * @return {DayOfWeek} DayOfWeek型が返る。
 * @category utils
 */
export const date2DayOfWeek = (date: Date): DayOfWeek => {
  const dayOfWeek = date.getDay();
  return dayOfWeek as DayOfWeek;
}

/**
 * Date型からnumber型(EpochTime)へ
 * @param date Date型もしくはstring型
 * @return {number} number型(EpochTime)が返る。
 * @category utils
 */
export const date2epoch = (date: Date | string): number => {
  let tmpDate;
  if (typeof (date) === 'string') {
    tmpDate = new Date(date);
  } else {
    tmpDate = date;
  }
  return new Date(format(tmpDate, 'yyyy-MM-dd')).getTime();
}

/**
 * Gender型から性別のテキストに変換し返す。
 * @param index Gender型
 * @return {GenderStr} 性別のテキストが返る。
 * @category utils
 */
export const genderIndex2string = (index: Gender): GenderStr => {
  switch (index) {
    case 1:
      return '男性'
    case 2:
      return '女性'
    case 9:
      return 'その他'
    default:
      return 'その他'
  }
};

/**
 * ReserveStatus型から予約ステータスのテキストに変換し返す。
 * @param index ReserveStatus型
 * @return {ReserveStatusStr} 予約ステータスのテキストが返る。
 * @category utils
 */
export const reserveStatus2string = (index: ReserveStatus): ReserveStatusStr => {
  switch (index) {
    case 1:
      return '待機中'
    case 2:
      return '不在'
    case 3:
      return '診察中'
    case 4:
      return '診察済'
    case 9:
      return '予約キャンセル済'
    default:
      return '待機中'
  }
};

/**
 * DayOfWeek型からDayOfWeekStrへ
 * @param index DayOfWeek型
 * @return {DayOfWeekStr} DayOfWeekStrが返る。
 * @category utils
 */
export const dayOfWeekNum2string = (index: DayOfWeek): DayOfWeekStr => {
  switch (index) {
    case 0:
      return 'sun'
    case 1:
      return 'mon'
    case 2:
      return 'tue'
    case 3:
      return 'wed'
    case 4:
      return 'thu'
    case 5:
      return 'fri'
    case 6:
      return 'sat'
    default:
      return undefined
  }
}

/**
 * 曜日の日本語表現からからindexを返す。
 * @param dayOfWeekJa 曜日の日本語表現
 * @return {number} 曜日のindexが返る。
 * @category utils
 */
export const dayOfWeekJaStr2num = (dayOfWeekJa: string): number => {
  switch (dayOfWeekJa) {
    case "日":
      return 0;
    case "月":
      return 1;
    case "火":
      return 2;
    case "水":
      return 3;
    case "木":
      return 4;
    case "金":
      return 5;
    case "土":
      return 6;
    default:
      return 0;
  }
}

/**
 * 郵便番号をハイフン付きに変換
 * @param raw 郵便番号
 * @return {string} ハイフン付き郵便番号が返る。
 * @category utils
 */
export const postalCodeString = (raw: string): string => {
  const first = raw.substring(0, 3)
  const second = raw.substring(3)
  return `${first}-${second}`;
}

/**
 * 2つのTime型を比較し時間が後かどうか判別
 * @param time1 Time型
 * @param time2 Time型
 * @return {boolean} 時間が後かどうか。
 * @category utils
 */
export const isAfterTime = (time1: Time, time2: Time): boolean => {
  if (time1.hour > time2.hour) {
    return true;
  }
  if (time1.hour === time2.hour) {
    return time1.minute > time2.minute;
  }
  return false;
}

/**
 * 2つのTime型を比較し時間が前かどうか判別
 * @param time1 Time型
 * @param time2 Time型
 * @return {boolean} 時間が前かどうか。
 * @category utils
 */
export const isBeforeTime = (time1: Time, time2: Time): boolean => {
  if (time1.hour < time2.hour) {
    return true;
  }
  if (time1.hour === time2.hour) {
    return time1.minute < time2.minute;
  }
  return false;
}

/**
 * 2つのTime型を比較し同じかどうか判別
 * @param time1 Time型
 * @param time2 Time型
 * @return {boolean} 時間が同じかどうか。
 * @category utils
 */
export const isSameTime = (time1: Time, time2: Time): boolean =>
  time1.hour === time2.hour && time1.minute === time2.minute

/**
 * FirebaseErrorから対応するエラーメッセージを返す。
 * @param error FirebaseError型
 * @return {ReactNode} エラーメッセージのReactNodeが返る。
 * @category utils
 */
export const firebaseError2ErrorMessage = (error: FirebaseError): ReactNode => {
  switch (error.code) {
    case "auth/email-already-exists":
      return <div>メールアドレスをご確認の上、再度お試しください。<br />エラーコード: 101</div>;
    case "auth/id-token-expired":
    case "auth/id-token-revoked":
    case "auth/session-cookie-expired":
    case "auth/session-cookie-revoked":
      return <div>タイムアウトしました。ページを再読み込みして、再度お試しください。<br />エラーコード：102</div>;
    case "auth/invalid-argument":
      return <div>不正な値が送信されました。入力内容を確認して、再度お試しください。<br />エラーコード：103</div>;
    case "auth/email-already-in-use":
    case "auth/invalid-email":
      return <div>メールアドレスをご確認の上、再度お試しください。<br />エラーコード：104</div>;
    case "auth/invalid-password":
    case "auth/wrong-password":
      return <div>パスワードをご確認の上、再度お試しください。<br />エラーコード：105</div>;
    case "auth/too-many-requests":
      return <div>時間を空けてから、再度お試しください。<br />エラーコード：106</div>;
    case "auth/user-not-found":
      return <div>メールアドレスまたはパスワードが間違っています。<br />エラーコード：107</div>;
    default:
      return <div>不明なエラーが発生しました。管理者へお問い合わせ下さい。<br />エラーコード：${error.code}<br />内容：${error.message}</div>
  }
}