import { styled, TextField } from "@mui/material";
import { FC } from "react";
import useTimeSelectFormState from "./useTimeSelectFormState";
import { time2string } from "../../utils/converter";
import Time from "../../types/Time";

export type TimeSelectFormProps = {
  /** ラベル文字列 */
  label: string;
  /** 現在値 */
  value?: Time;
  /** 値が変化した時のコールバック */
  onChange?: (time: Time) => void;
  /** 横幅を広げるかどうか */
  fullWidth?: boolean;
  /** 無効化 */
  disabled?: boolean;
  /** 選択可能最小値 */
  min?: Time;
  /** 選択可能最大値 */
  max?: Time;
};

const STextField = styled(TextField)`
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    color: #362f5f;
    -webkit-text-fill-color: #362f5f !important;
  }
`;

/**
 * 時間選択フォームコンポーネント
 * @param label ラベル文字列
 * @param value 現在値
 * @param onChange 値が変化した時のコールバック
 * @param fullWidth 横幅を広げるかどうか
 * @param disabled 無効化
 * @param min 選択可能最小値
 * @param max 選択可能最大値
 * @constructor
 * @group Components
 * @category components
 */
const TimeSelectForm: FC<TimeSelectFormProps> = ({
  label,
  value = undefined,
  onChange = undefined,
  fullWidth = true,
  disabled = false,
  min = undefined,
  max = undefined
}) => {
  const { onChangeTimeValue } = useTimeSelectFormState();
  return <STextField
    label={label}
    type="time"
    value={value ? time2string(value) : undefined}
    fullWidth={fullWidth}
    disabled={disabled}
    InputLabelProps={{
      shrink: true,
    }}
    InputProps={{
      inputProps: {
        step: 300,
        min: min ? time2string(min) : undefined,
        max: max ? time2string(max) : undefined,
      }
    }}
    onChange={(e) => {
      onChangeTimeValue(e.target.value ?? '', onChange)
    }}
  />;
};

export default TimeSelectForm;