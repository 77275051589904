import { useRecoilState, useResetRecoilState } from "recoil";
import { useCallback, useEffect } from "react";
import createReservationFrameDialogStateAtom
  from "../../../../recoil/features/ReservationSettingsPage/CreateReservationFrameDialog";
import useResultAlertState from "../../../../components/ResultAlert/useResultAlertState";
import Time from "../../../../types/Time";
import IReservationFrame from "../../../../interfaces/IReservationFrame";
import { isAfterTime, isBeforeTime, isSameTime } from "../../../../utils/converter";
import { calcPointsFromTime } from "../../../../utils/points";
import { validateIsNumber } from "../../../../utils/validator";

/**
 * 予約枠追加ダイアログの状態管理やロジックをまとめたHooks
 * @group Components
 * @category features/ReservationSettingsPage
 */
const useCreateReservationFrameDialogState = () => {
  const [ state, setState ] = useRecoilState(createReservationFrameDialogStateAtom);
  const resetState = useResetRecoilState(createReservationFrameDialogStateAtom);
  
  useEffect(() => () => resetState(), [ resetState ]);
  
  const { openAlert } = useResultAlertState();
  
  /**
   * 予約枠追加ダイアログを開く。
   */
  const openDialog = useCallback(() => {
    setState((prev) => ({ ...prev, isOpen: true }))
  }, [ setState ]);
  
  /**
   * 予約枠追加のバリデーション。
   * エラーの場合、文字列が返る。
   */
  const validateFrameTime: (
    startAt: Time | undefined,
    endAt: Time | undefined,
    currentFrames: IReservationFrame[]
  ) => string | undefined = useCallback((
    startAt: Time | undefined,
    endAt: Time | undefined,
    currentFrames: IReservationFrame[]
  ) => {
    if (!startAt || !endAt) {
      return '先に、診療時間を設定してください。';
    }
    if (!state.startAt || Number.isNaN(state.startAt.hour) || Number.isNaN(state.startAt.minute)) {
      return '開始時刻を設定してください。';
    }
    if (!state.endAt || Number.isNaN(state.endAt.hour) || Number.isNaN(state.endAt.minute)) {
      return '終了時刻を設定してください。';
    }
    if (!state.availableNum || validateIsNumber(state.availableNum)) {
      return '診察可能人数を正しく設定してください。';
    }
    // 開始時刻と終了時刻の整合性バリデーション
    if (isSameTime(state.startAt, state.endAt)) {
      return '開始時刻は終了時刻よりも早い時刻に設定してください。';
    }
    if (isBeforeTime(state.endAt, state.startAt)) {
      return '開始時刻は終了時刻よりも早い時刻に設定してください。';
    }
    if (isAfterTime(state.startAt, state.endAt)) {
      return '終了時刻は開始時刻よりも遅い時刻に設定してください。';
    }
    // 診療時間外ではないかのバリデーション
    if (isBeforeTime(state.startAt, startAt)) {
      return '開始時刻は、診療開始時刻よりも遅い時刻に設定してください。';
    }
    if (isBeforeTime(state.endAt, startAt)) {
      return '終了時刻は、診療開始時刻よりも遅い時刻に設定してください。';
    }
    if (isAfterTime(state.startAt, endAt)) {
      return '開始時刻は、診療終了時刻よりも早い時刻に設定してください。';
    }
    if (isAfterTime(state.endAt, endAt)) {
      return '終了時刻は、診療終了時刻よりも早い時刻に設定してください。';
    }
    
    // 設定済み予約枠との被り等が無いかのバリデーション
    if (currentFrames.length === 0) {
      return undefined;
    }
    if (isSameTime(state.endAt, currentFrames[0].startAt) ||
      isBeforeTime(state.endAt, currentFrames[0].startAt)) {
      return undefined;
    }
    if (isSameTime(state.startAt, currentFrames[currentFrames.length - 1].endAt) ||
      isAfterTime(state.startAt, currentFrames[currentFrames.length - 1].endAt)) {
      return undefined;
    }
    let isNotError = false;
    for (let i = 0; i < currentFrames.length; i += 1) {
      if (isAfterTime(state.startAt, currentFrames[i].startAt)) {
        if (isSameTime(state.startAt, currentFrames[i].endAt) ||
          isAfterTime(state.startAt, currentFrames[i].endAt)) {
          if (currentFrames[i + 1]) {
            if (isSameTime(state.endAt, currentFrames[i + 1].startAt) ||
              isBeforeTime(state.endAt, currentFrames[i + 1].startAt)) {
              isNotError = true;
              break;
            }
          }
        }
      }
    }
    return isNotError ? undefined : '時刻設定が不正です。';
  }, [ state.availableNum, state.endAt, state.startAt ]);
  
  /**
   * 予約枠追加ダイアログを閉じる際の処理。
   * @param currentFrames 現在の予約枠設定リスト
   * @param onClose undefinedの場合、何もせずダイアログを閉じる。
   */
  const closeDialog = useCallback((
    startAt: Time | undefined,
    endAt: Time | undefined,
    currentFrames: IReservationFrame[],
    onClose?: ((reservationFrameItem: IReservationFrame
    ) => void) | undefined) => {
    if (onClose) {
      const error = validateFrameTime(startAt, endAt, currentFrames);
      if (error) {
        openAlert('error', error);
        return;
      }
      // バリデーション済み
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const points = calcPointsFromTime(state.startAt!, state.endAt!);
      const numberOfPeople = Number(state.availableNum);
      onClose({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: '', startAt: state.startAt!, endAt: state.endAt!, points, numberOfPeople,
      });
    }
    setState((prev) => ({ ...prev, isOpen: false, availableNum: undefined }))
  }, [ openAlert, setState, state.availableNum, state.endAt, state.startAt, validateFrameTime ]);
  
  /**
   * 予約枠追加ダイアログの開始時刻変更時
   */
  const onChangeStartAt = useCallback((startAt: Time) => {
    setState((prev) => ({ ...prev, startAt }))
  }, [ setState ]);
  
  /**
   * 予約枠追加ダイアログの終了時刻変更時
   */
  const onChangeEndAt = useCallback((endAt: Time) => {
    setState((prev) => ({ ...prev, endAt }))
  }, [ setState ]);
  
  const onChangeAvailableNum = useCallback((availableNum: string) => {
    setState((prev) => ({ ...prev, availableNum }))
  }, [ setState ]);
  
  return {
    state,
    openDialog,
    closeDialog,
    onChangeStartAt,
    onChangeEndAt,
    onChangeAvailableNum,
  };
};

export default useCreateReservationFrameDialogState;