import { atom } from "recoil";

export type ReservationSettingsPageTabsType = 'base' | 'day';

export type ReservationSettingsPageState = {
  /** 現在のタブ */
  currentTab: ReservationSettingsPageTabsType;
};

const initialState: ReservationSettingsPageState = {
  currentTab: 'base',
};

const reservationSettingsPageStateAtom = atom({
  key: 'ReservationSettingsPageState',
  default: initialState,
});

export default reservationSettingsPageStateAtom;