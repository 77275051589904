import { doc, getDoc, getDocs, query, setDoc, orderBy, collection, deleteDoc, where } from "firebase/firestore";
import { firestore } from "../firebase";
import IReservationSetting, { reservationSettingConverter } from "../interfaces/IReservationSetting";
import IReservationFrame, { reservationFrameConverter } from "../interfaces/IReservationFrame";
import { DayOfWeek, DayOfWeekStr } from "../types/DayOfWeek";
import { date2string, dayOfWeekNum2string, time2string } from "../utils/converter";
import Time from "../types/Time";
import { reserveStatusConverter } from "../interfaces/IReserveStatus";

/**
 * 指定したClinic IDの曜日別の予約枠設定をセットする。
 * @param clinicId クリニックのユーザーID
 * @param baseSetting 曜日別の予約枠設定
 * @category repositories
 */
export const setReservationBaseSettings = async (clinicId: string, baseSetting: IReservationSetting) => {
  const docId = dayOfWeekNum2string(baseSetting.dayOfWeekOrDate as DayOfWeek) as string;
  const docRef = doc(firestore, 'reservation_settings', clinicId, 'base_settings', docId)
    .withConverter(reservationSettingConverter);
  return setDoc(docRef, baseSetting);
}

/**
 * 指定したClinic IDの曜日別の予約枠一覧をセットする。
 * @param clinicId クリニックのユーザーID
 * @param dayOfWeek 曜日(docIdとなる)
 * @param reservationFrames 曜日別の予約枠一覧
 * @category repositories
 */
export const setReservationBaseFrames = async (clinicId: string, dayOfWeek: DayOfWeekStr, reservationFrames: IReservationFrame[]) => {
  for (let i = 0; i < reservationFrames.length; i += 1) {
    const reservationFrame = reservationFrames[i];
    const docRef = doc(firestore, 'reservation_settings', clinicId,
      'base_settings', dayOfWeek as string, 'reservation_frames', time2string(reservationFrame.startAt))
      .withConverter(reservationFrameConverter);
    await setDoc(docRef, reservationFrame);
  }
};

/**
 * 指定したClinic IDの日別の予約枠一覧をリセットする。
 * @param clinicId クリニックのユーザーID
 * @param dayOfWeek 曜日(docId)
 * @param beforeFrames 以前の予約枠
 * @category repositories
 */
export const resetReservationBaseFrames = async (clinicId: string, dayOfWeek: DayOfWeekStr, beforeFrames: IReservationFrame[]) => {
  for (let i = 0; i < beforeFrames.length; i += 1) {
    const reservationFrame = beforeFrames[i];
    const docRef = doc(firestore, 'reservation_settings', clinicId,
      'base_settings', dayOfWeek as string, 'reservation_frames', time2string(reservationFrame.startAt))
      .withConverter(reservationFrameConverter);
    await deleteDoc(docRef);
  }
};

/**
 * 指定したClinic IDのすべての曜日の予約枠設定を取得する。
 * @param clinicId クリニックのユーザーID
 * @return {Promise<IReservationSetting[]>} データが見つかればすべての曜日の予約枠設定がリストで返る。
 * @category repositories
 */
export const getReservationBaseSettings = async (clinicId: string): Promise<IReservationSetting[]> => {
  const BaseSettingsCol = collection(firestore, 'reservation_settings', clinicId, 'base_settings')
    .withConverter(reservationSettingConverter);
  const q = query(BaseSettingsCol, orderBy("dayOfWeekOrDate"));
  const snapshot = await getDocs(q);
  const reservationBaseSettings: IReservationSetting[] = [];
  if (snapshot.docs.length === 0) {
    return reservationBaseSettings;
  }
  snapshot.docs.forEach(itemDoc => {
    const item = itemDoc.data();
    reservationBaseSettings.push(item);
  })
  return reservationBaseSettings;
};

/**
 * 指定したClinic IDの指定曜日の予約枠基本設定を１件取得する。
 * @param clinicId クリニックのユーザーID
 * @param dayOfWeek 曜日(docId)
 * @return {Promise<IReservationSetting | null>} データが見つかれば指定曜日の予約枠基本設定が返る。
 * @category repositories
 */
export const getOneReservationBaseSettings = async (clinicId: string, dayOfWeek: DayOfWeekStr): Promise<IReservationSetting | null> => {
  if (dayOfWeek === undefined) {
    return null;
  }
  const docRef = doc(firestore, 'reservation_settings', clinicId, 'base_settings', dayOfWeek).withConverter(reservationSettingConverter);
  const snapshot = await getDoc(docRef);
  if (!snapshot.exists()) {
    return null;
  }
  return snapshot.data();
};

/**
 * 指定したClinic IDの指定曜日の予約枠一覧を取得する。
 * @param clinicId クリニックのユーザーID
 * @param dayOfWeek 曜日(docId)
 * @return {Promise<IReservationFrame[]>} データが見つかれば指定曜日の予約枠一覧が返る。
 * @category repositories
 */
export const getReservationBaseFrames = async (clinicId: string, dayOfWeek: DayOfWeekStr): Promise<IReservationFrame[]> => {
  const FramesCol = collection(firestore, 'reservation_settings',
    clinicId, 'base_settings', dayOfWeek as string, 'reservation_frames').withConverter(reservationFrameConverter);
  const q = query(FramesCol, orderBy("startHour", "asc"), orderBy("startMinute", "asc"));
  const snapshot = await getDocs(q);
  const reservationFrames: IReservationFrame[] = [];
  if (snapshot.docs.length === 0) {
    return reservationFrames;
  }
  snapshot.docs.forEach(itemDoc => {
    const item = itemDoc.data();
    reservationFrames.push(item);
  })
  return reservationFrames;
};

/**
 * 指定したClinic IDの日別の予約枠設定をセットする。
 * @param clinicId クリニックのユーザーID
 * @param date 日付(docIdとなる)
 * @param daySetting 日別の予約枠設定
 * @category repositories
 */
export const setReservationDaySettings = async (clinicId: string, date: Date, daySetting: IReservationSetting) => {
  const docRef = doc(firestore, 'reservation_settings', clinicId, 'day_settings', date2string(date))
    .withConverter(reservationSettingConverter);
  return setDoc(docRef, daySetting)
};

/**
 * 指定したClinic IDの日別の予約枠一覧をセットする。
 * @param clinicId クリニックのユーザーID
 * @param date 日付(docIdとなる)
 * @param reservationFrames 日別の予約枠一覧
 * @category repositories
 */
export const setReservationDayFrames = async (clinicId: string, date: Date, reservationFrames: IReservationFrame[]) => {
  for (let i = 0; i < reservationFrames.length; i += 1) {
    const reservationFrame = reservationFrames[i];
    const docRef = doc(firestore, 'reservation_settings', clinicId,
      'day_settings', date2string(date), 'reservation_frames', time2string(reservationFrame.startAt))
      .withConverter(reservationFrameConverter);
    await setDoc(docRef, reservationFrame);
  }
};

/**
 * 指定したClinic IDの日別の予約枠設定を削除する。
 * @param clinicId クリニックのユーザーID
 * @param date 日付(docId)
 * @category repositories
 */
export const deleteReservationDaySetting = async (clinicId: string, date: Date) => {
  const docRef = doc(firestore, 'reservation_settings', clinicId, 'day_settings', date2string(date))
    .withConverter(reservationFrameConverter);
  await deleteDoc(docRef);
};

/**
 * 指定したClinic IDの日別の予約枠一覧をリセットする。
 * @param clinicId クリニックのユーザーID
 * @param date 日付(docId)
 * @param beforeFrames 以前の予約枠
 * @category repositories
 */
export const resetReservationDayFrames = async (clinicId: string, date: Date, beforeFrames: IReservationFrame[]) => {
  for (let i = 0; i < beforeFrames.length; i += 1) {
    const reservationFrame = beforeFrames[i];
    const docRef = doc(firestore, 'reservation_settings', clinicId,
      'day_settings', date2string(date), 'reservation_frames', time2string(reservationFrame.startAt))
      .withConverter(reservationFrameConverter);
    await deleteDoc(docRef);
  }
};

/**
 * 指定したClinic IDの日別の予約枠設定を取得する。
 * @param clinicId クリニックのユーザーID
 * @param date 予約枠日(docId)
 * @return {Promise<IReservationSetting | null>} データが見つかれば指定日付の予約枠設定が返る。
 * @category repositories
 */
export const getReservationDaySettings = async (clinicId: string, date: Date): Promise<IReservationSetting | null> => {
  const docRef = doc(firestore, 'reservation_settings', clinicId, 'day_settings', date2string(date))
    .withConverter(reservationSettingConverter);
  const snapshot = await getDoc(docRef);
  if (!snapshot.exists()) {
    return null;
  }
  return snapshot.data();
};

/**
 * 指定したClinic IDの日別の予約枠一覧を取得する。
 * @param clinicId クリニックのユーザーID
 * @param date 日付(docId)
 * @return {Promise<IReservationSetting | null>} データが見つかれば指定日付の予約枠一覧が返る。
 * @category repositories
 */
export const getReservationDayFrames = async (clinicId: string, date: Date): Promise<IReservationFrame[]> => {
  const framesCol = collection(firestore, 'reservation_settings',
    clinicId, 'day_settings', date2string(date), 'reservation_frames').withConverter(reservationFrameConverter);
  const q = query(framesCol, orderBy("startHour", "asc"), orderBy("startMinute", "asc"));
  const snapshot = await getDocs(q);
  const reservationFrames: IReservationFrame[] = [];
  if (snapshot.docs.length === 0) {
    return reservationFrames;
  }
  snapshot.docs.forEach(itemDoc => {
    const item = itemDoc.data();
    reservationFrames.push(item);
  })
  return reservationFrames;
};

/**
 * 指定したClinic IDとある日程期間の予約枠日別設定情報一覧を取得する。
 * @param clinicId クリニックのユーザーID
 * @param startAt 予約枠日（日程期間の開始日）
 * @param endAt 予約枠日（日程期間の終了日）
 * @return {Promise<IReservationSetting[]>} データが見つかれば指定日程期間の予約枠日別設定情報の一覧が返る。
 * @category repositories
 */
export const getReservationDaySettingsFromDate = async (clinicId: string, startAt: number, endAt: number): Promise<IReservationSetting[]> => {
  const daySettingsCol = collection(firestore, 'reservation_settings', clinicId, 'day_settings').withConverter(reservationSettingConverter);
  const q = query(daySettingsCol, where('settingType', '==', 'day'),
    where('dayOfWeekOrDate', '>=', startAt), where('dayOfWeekOrDate', '<=', endAt));
  const snapshot = await getDocs(q);
  const daySettings: IReservationSetting[] = [];
  if (snapshot.docs.length === 0) {
    return daySettings;
  }
  snapshot.docs.forEach(itemDoc => {
    const item = itemDoc.data();
    daySettings.push(item);
  });
  return daySettings;
};

/**
 * 予約受付停止状態かどうかを取得する。
 * @param clinicId クリニックID
 * @param date 予約枠の日付
 * @param startTime 予約枠の開始時間
 * @return {Promise<boolean>} 予約受付停止状態かどうかが返る。
 * @category repositories
 */
export const isStopAccepting = async (clinicId: string, date: Date, startTime: Time): Promise<boolean> => {
  if (clinicId === '') {
    return false
  }
  const docId = `${date2string(date)} ${time2string(startTime)}`
  const stopAcceptingDoc = doc(firestore, 'reservation_settings', clinicId, 'stop_accepting', docId);
  const snapshot = await getDoc(stopAcceptingDoc);
  return !!snapshot.exists();
}

/**
 * 予約枠に予約可能な空きがあるかどうかを取得する。
 * @param clinicId クリニックID
 * @param date 予約枠の日付
 * @param startTime 予約枠の開始時間
 * @param perPoints 一人当たりのポイント
 * @return {Promise<boolean>} 予約枠に予約可能な空きがあるかどうかが返る。
 * @category repositories
 */
export const isReserveAvailable = async (clinicId: string, date: Date, startTime: Time, perPoints: number): Promise<boolean> => {
  if (clinicId === '') {
    return false;
  }
  const docId = `${date2string(date)} ${time2string(startTime)}`
  const docRef = doc(firestore, "clinics", clinicId, "status", docId).withConverter(reserveStatusConverter)
  const snapshot = await getDoc(docRef);
  if (snapshot.exists()) {
    const statusData = snapshot.data();
    return statusData.currentPoints + perPoints <= statusData.totalPoints;
  }
  return true
}

/**
 * 予約受付停止状態を追加する。
 * @param clinicId クリニックID
 * @param date 予約枠の日付
 * @param startTime 予約枠の開始時間
 * @category repositories
 */
export const addStopAccepting = async (clinicId: string, date: Date, startTime: Time) => {
  const docId = `${date2string(date)} ${time2string(startTime)}`
  const stopAcceptingDoc = doc(firestore, 'reservation_settings', clinicId, 'stop_accepting', docId);
  await setDoc(stopAcceptingDoc, { createAt: new Date() })
}

/**
 * 予約受付停止状態を解除する。
 * @param clinicId クリニックID
 * @param date 予約枠の日付
 * @param startTime 予約枠の開始時間
 * @category repositories
 */
export const removeStopAccepting = async (clinicId: string, date: Date, startTime: Time) => {
  const docId = `${date2string(date)} ${time2string(startTime)}`
  const stopAcceptingDoc = doc(firestore, 'reservation_settings', clinicId, 'stop_accepting', docId);
  await deleteDoc(stopAcceptingDoc);
}