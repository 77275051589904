import Time from "../types/Time";

/**
 * 開始時刻と終了時刻の差分を1分あたり1ポイントとしてポイント計算し返す。
 * @param startAt 開始時刻
 * @param endAt 終了時刻
 * @return {number} 時刻差分のポイントが返る。
 * @category utils
 */
export const calcPointsFromTime = (startAt: Time, endAt: Time): number => {
  const hour = endAt.hour - startAt.hour;
  const minute = endAt.minute - startAt.minute;
  return hour * 60 + minute;
};

/**
 * １予約に必要となるポイントを計算し返す。
 * @param numberOfPeople 予約可能人数
 * @param numberOfPoints 予約枠ポイント
 * @return {number} １予約に必要となるポイントが返る。
 * @category utils
 */
export const calcReservePoints = (numberOfPeople: number, numberOfPoints: number): number =>
  (numberOfPoints - numberOfPoints % numberOfPeople) / numberOfPeople;

/**
 * 予約ポイント集計と１予約に必要となるポイントを用いて計算し、予約人数合計を返す。
 * @param sumPoints 予約ポイント集計
 * @param reservePoints １予約に必要となるポイント
 * @return {number} 予約人数合計が返る。
 * @category utils
 */
export const calcCurrentNumberOfPeople = (sumPoints: number, reservePoints: number): number =>
  sumPoints / reservePoints;

/**
 * 人数をUI表示に適したテキストへ変換し返す。
 * @param people 人数
 * @return {string} 人数のUIテキストが返る。
 * @category utils
 */
export const numOfPeopleStr = (people: number): string => {
  if (Number.isNaN(people)) {
    return "-人";
  }
  if (people === 0) {
    return "0人";
  }
  return `${people}人`
}
