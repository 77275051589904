import { Box, Container, Toolbar, } from "@mui/material";
import { FC, ReactNode } from "react";
import NavDrawer from "../../components/NavDrawer/NavDrawer";
import useBreakpoint from "../../utils/useBreakPoint";
import NavDrawerMobile from "../../components/NavDrawer/NavDrawerMobile";
import ClinicAppBar from "../../components/AppBar/ClinicAppBar";

export type ClinicPageBaseProps = {
  /** ページタイトル */
  pageTitle: string;
  /** 子供コンポーネント */
  children: ReactNode;
};

/**
 * クリニック側ページのベースコンポーネント
 * @param pageTitle ページタイトル
 * @param children
 * @constructor
 * @group Components
 * @category features/PageBase
 */
const ClinicPageBase: FC<ClinicPageBaseProps> = ({ pageTitle, children }) => {
  const { breakpoint } = useBreakpoint();
  
  const desktopSizeWidgets = <Box sx={{ display: 'flex' }}>
    <ClinicAppBar title={pageTitle} />
    <NavDrawer />
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Container maxWidth='xl'>
        <Toolbar />
        <Box my={1} />
        {children}
      </Container>
    </Box>
  </Box>;
  
  const tabletSizeWidgets = <Box>
    <ClinicAppBar title={pageTitle} />
    <NavDrawerMobile />
    <Box component="main" maxWidth='100%'>
      <Container maxWidth='xl'>
        <Toolbar />
        <Box my={2} />
        {children}
      </Container>
    </Box>
  </Box>;
  
  switch (breakpoint) {
    // TODO: Mobile
    case "xs":
    case "sm":
      // Tablet size
      return tabletSizeWidgets;
    case "md":
    case "lg":
    case "xl":
      return desktopSizeWidgets;
    default:
      return desktopSizeWidgets;
  }
}

export default ClinicPageBase;