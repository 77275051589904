import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box, Card, CardContent, Grid, Stack, Toolbar, Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { resetPasswordPath } from "../../layout/urls";
import ClinicPageBase from "../PageBase/ClinicPageBase";
import InputForm from "../../components/InputForm/InputForm";
import usePasswordSettingPageState from "./usePasswordSettingPageState";

/**
 * パスワード設定ページ
 * @constructor
 * @group Components
 * @category features/PasswordSettingPage
 */
const PasswordSettingPage = () => {
  const {
    state,
    isLoading,
    onChangeCurrentPassword,
    onChangeNewPassword,
    onClickPasswordSetting
  } = usePasswordSettingPageState();
  return <ClinicPageBase pageTitle='パスワード設定'>
    <Grid container justifyContent='center' alignItems='start' rowSpacing={3}>
      <Grid item xs={12} md={4}>
        <Box mx={2} mt={2}>
          <Stack direction='column' spacing={2}
                 sx={{
                   textAlign: {
                     'xs': 'center',
                     'md': 'start',
                   },
                 }}>
            <Typography variant='h6' fontWeight='bold'>
              パスワード設定
            </Typography>
            <Typography variant='caption'>
              パスワードの変更を行います。<br />
              現在のパスワードと新しいパスワードを入力してください。
            </Typography>
            <Typography
              component={Link}
              to={resetPasswordPath}
              variant="caption"
            >
              現在のパスワードを忘れた場合
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12} md={8} mb={2}>
        <Card sx={{ padding: 2 }}>
          <CardContent>
            <Stack direction='column' spacing={4} mb={6}>
              <InputForm
                label='現在のパスワード'
                required password
                value={state.currentPassword}
                onChange={onChangeCurrentPassword}
                error={state.errors?.currentPassword}
              />
              <InputForm
                label='新しいパスワード'
                required password
                value={state.newPassword}
                onChange={onChangeNewPassword}
                error={state.errors?.newPassword}
              />
              <LoadingButton
                color='info'
                loading={isLoading}
                loadingIndicator='読込中...'
                fullWidth
                variant="contained"
                sx={{
                  borderRadius: '24px',
                  color: '#FFFFFF',
                  elevation: 3,
                  py: '8px',
                }}
                onClick={onClickPasswordSetting}
              >
                この内容で変更する
              </LoadingButton>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
    <Toolbar />
  </ClinicPageBase>
};

export default PasswordSettingPage;