import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  FormControl, FormControlLabel,
  Grid, Radio,
  RadioGroup,
  Stack,
  Toolbar,
  Typography
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import InputForm from "../../components/InputForm/InputForm";
import ClinicPageBase from "../PageBase/ClinicPageBase";
import useBasicSettingsPageState from "./useBasicSettingsPageState";

/**
 * クリニック基本情報の設定ページのレイアウト
 * @group Components
 * @category features/BasicSettingsPage
 */
const BasicSettingsPageLayout = () => {
  const {
    state,
    isSaving,
    onChangeClinicName,
    onChangePostalCode,
    onChangeAddress,
    onChangePhoneNumber,
    onChangeDepartments,
    onChangeFreeInput,
    onChangeReservationPeriod,
    onChangeIsAvailableSameDayReserve,
    onChangeSameDayReservePeriod,
    onChangeCalendarStart,
    onClickSaveSettings,
    getAddressByPostalCode,
    onClickRefresh,
  } = useBasicSettingsPageState();
  
  return <ClinicPageBase pageTitle='基本設定'>
    <Grid container justifyContent='center' alignItems='start'>
      <Grid item xs={12} md={4}>
        <Box mx={2} mt={2} mb={4}>
          <Stack direction='column' spacing={2} sx={{
            textAlign: {
              'xs': 'center',
              'md': 'start',
            },
          }}>
            <Typography variant='h6' fontWeight='bold'>
              基本情報
            </Typography>
            <Typography variant='caption'>
              病院や、クリニック、診療所等に関する情報を設定します。<br />
              ここで設定した内容は、患者様に表示される予約ページ等にも表示されます。
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12} md={8} mb={2}>
        <Card sx={{ padding: 2 }}>
          <CardContent>
            <Stack direction='column' spacing={2} mb={6}>
              <Typography variant='h6' fontWeight='bold' textAlign='start'>名称</Typography>
              <Typography variant='body1' textAlign='start'>
                名称を設定します。
              </Typography>
              <InputForm
                label='名称'
                required
                maxLines={1}
                value={state.clinicName}
                onChange={onChangeClinicName}
                error={state.errors?.clinicName} />
            </Stack>
            
            <Stack direction='column' spacing={2} mb={6}>
              <Typography variant='h6' fontWeight='bold' textAlign='start'>住所</Typography>
              <Typography variant='body1' textAlign='start'>
                住所（所在地）を設定します。
              </Typography>
              <Stack direction='row' justifyContent="start" alignItems="start" spacing={2}>
                <InputForm
                  label='郵便番号'
                  maxLines={1}
                  type='number'
                  value={state.postalCode}
                  onChange={onChangePostalCode}
                  error={state.errors?.postalCode}
                  startAdornmentStr="〒"
                />
                <LoadingButton variant='outlined' loading={isSaving}
                               sx={{ borderRadius: '8px', fontSize: "12px" }}
                               onClick={getAddressByPostalCode}>
                  郵便番号から住所取得
                </LoadingButton>
              </Stack>
              <InputForm
                label='住所'
                multiline
                minLines={2}
                value={state.address}
                onChange={onChangeAddress}
                error={state.errors?.address}
              />
            </Stack>
            
            <Stack direction='column' spacing={2} mb={6}>
              <Typography variant='h6' fontWeight='bold' textAlign='start'>電話番号</Typography>
              <Typography variant='body1' textAlign='start'>
                電話番号を設定します。
              </Typography>
              <InputForm
                label='電話番号'
                type='tel'
                maxLines={1}
                value={state.phoneNumber}
                onChange={onChangePhoneNumber}
                error={state.errors?.phoneNumber}
              />
            </Stack>
            
            <Stack direction='column' spacing={2} mb={6}>
              <Typography variant='h6' fontWeight='bold' textAlign='start'>診療科目</Typography>
              <Typography variant='body1' textAlign='start'>
                診療科目を設定します。
              </Typography>
              <InputForm
                label='診療科目'
                value={state.departments}
                onChange={onChangeDepartments}
                error={state.errors?.departments}
                maxLines={1}
              />
            </Stack>
            
            <Stack direction='column' spacing={2} mb={6}>
              <Typography variant='h6' fontWeight='bold' textAlign='start'>予約可能期間</Typography>
              <Typography variant='body1' textAlign='start'>
                何か月先まで予約を受け付けるかを設定します。
              </Typography>
              <InputForm
                label='予約可能期間'
                type='number'
                value={state.reservationPeriod}
                onChange={onChangeReservationPeriod}
                error={state.errors?.reservationPeriod}
                maxLines={1}
                endAdornmentStr="ヵ月"
              />
            </Stack>
            
            <Stack direction='column' spacing={2} mb={6}>
              <Typography variant='h6' fontWeight='bold' textAlign='start'>当日予約受付</Typography>
              <Typography variant='body1' textAlign='start'>
                当日の予約を受け付けるかどうかを設定します。
              </Typography>
              <FormControl>
                <RadioGroup
                  aria-labelledby="same-day-reserve-radio"
                  defaultValue={state.isAvailableSameDayReserve}
                  value={state.isAvailableSameDayReserve}
                  name="same-day-reserve-radio"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeIsAvailableSameDayReserve(event.target.value);
                  }}
                >
                  <FormControlLabel value="enable" control={<Radio />} label="当日予約を受け付ける" />
                  <FormControlLabel value="disable" control={<Radio />} label="当日予約は受け付けない" />
                </RadioGroup>
              </FormControl>
            </Stack>
            
            <Stack direction='column' spacing={2} mb={6}>
              <Typography variant='h6' fontWeight='bold' textAlign='start'>当日予約受付締切時間</Typography>
              <Typography variant='body1' textAlign='start'>
                当日予約を受け付ける場合に、予約枠の終了時間から何分前まで当日予約を受け付けるかを設定します。
              </Typography>
              <InputForm
                label='当日予約受付締切時間'
                type='number'
                value={state.sameDayReservePeriod}
                onChange={onChangeSameDayReservePeriod}
                error={state.errors?.sameDayReservePeriod}
                maxLines={1}
                endAdornmentStr="分"
              />
            </Stack>
            
            <Stack direction='column' spacing={2} mb={6}>
              <Typography variant='h6' fontWeight='bold' textAlign='start'>カレンダー開始曜日設定</Typography>
              <Typography variant='body1' textAlign='start'>
                カレンダー表示の開始曜日を設定します。
              </Typography>
              <FormControl>
                <RadioGroup
                  aria-labelledby="start-dow-calendar"
                  defaultValue={state.calendarStart}
                  value={state.calendarStart}
                  name="start-dow-calendar"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeCalendarStart(parseInt(event.target.value, 10))
                  }}
                >
                  <FormControlLabel value="0" control={<Radio />} label="日曜日" />
                  <FormControlLabel value="1" control={<Radio />} label="月曜日" />
                </RadioGroup>
              </FormControl>
            </Stack>
            
            <Stack direction='column' spacing={2} mb={2}>
              <Typography variant='h6' fontWeight='bold' textAlign='start'>自由入力欄</Typography>
              <Typography variant='body1' textAlign='start'>
                アクセス情報や、駐車場情報など、患者様にお知らせしたい内容を設定します。
              </Typography>
              <InputForm
                label='自由入力欄'
                multiline
                minLines={3}
                value={state.freeInputs}
                onChange={onChangeFreeInput}
                error={state.errors?.freeInputs}
              />
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
    <Toolbar />
    <AppBar position='fixed' color='secondary' sx={{ top: 'auto', bottom: 0, }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction='row' spacing={4}>
          <Button variant='text' color='inherit' onClick={onClickRefresh}>
            キャンセル
          </Button>
          <Box mx='24px'>
            <LoadingButton
              loading={isSaving}
              onClick={onClickSaveSettings}
              color='inherit'
              variant='outlined'
              loadingIndicator='保存中...'
              sx={{ px: 4 }}
            >
              保存する
            </LoadingButton>
          </Box>
        </Stack>
      </Toolbar>
    </AppBar>
  </ClinicPageBase>
};

export default BasicSettingsPageLayout;