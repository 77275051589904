import { atom } from "recoil";
import { getToday } from "../../../utils/datetime";

export type clinicHomePageState = {
  /** 指定中の日付 */
  currentDate: Date;
  /** データピッカー表示かどうか */
  isOpenDatePicker: boolean;
  /** アンカーエレメント */
  anchorEl: null | HTMLElement;
};

const initialState: clinicHomePageState = {
  currentDate: getToday(),
  isOpenDatePicker: false,
  anchorEl: null,
};

const clinicHomePageStateAtom = atom({
  key: 'clinicHomePageState',
  default: initialState,
});

export default clinicHomePageStateAtom;