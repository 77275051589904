import { atom } from "recoil";
import IReservationInformation from "../../../../interfaces/IReservationInformation";

export type ListContentState = {
  /** 予約情報一覧 */
  reservationInformation: IReservationInformation[],
}

const initialState: ListContentState = {
  reservationInformation: [],
};

const listContentStateAtom = atom({
  key: "listContentStateAtom",
  default: initialState,
})

export default listContentStateAtom;