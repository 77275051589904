import { atom } from "recoil";
import IReservationInformation from "../../../../interfaces/IReservationInformation";
import IReservationFrame from "../../../../interfaces/IReservationFrame";

export type ReservationDetailDialogState = {
  /** ダイアログ表示かどうか */
  isOpen: boolean;
  /** 予約情報 */
  reservationInfo: IReservationInformation | undefined,
  /** 予約枠 */
  reservationFrame: IReservationFrame | undefined,
  /** 指定中の日付 */
  currentDate: Date | undefined,
};

const initialState: ReservationDetailDialogState = {
  isOpen: false,
  reservationInfo: undefined,
  reservationFrame: undefined,
  currentDate: undefined,
};

const reservationDetailDialogStateAtom = atom({
  key: 'ReservationDetailDialogState',
  default: initialState,
});

export default reservationDetailDialogStateAtom;