import { Box, Card, CardContent, CircularProgress, Stack, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { Suspense } from "react";
import ClinicPageBase from "../PageBase/ClinicPageBase";
import useReservationSettingsPageState from "./useReservationSettingsPageState";
import ReservationBaseSettingsContent from "./ReservationBaseSettingsContent/ReservationBaseSettingsContent";
import ReservationDaySettingsLayout from "./ReservationDaySettingsContent/ReservationDaySettingsLayout";

/**
 * 予約枠設定ページ
 * @constructor
 * @group Components
 * @category features/ReservationSettingsPage
 */
const ReservationSettingsPage = () => {
  const { state, handleTabChange } = useReservationSettingsPageState();
  return <ClinicPageBase pageTitle='予約枠設定'>
    <Box mx={2} mt={2} mb={4}>
      <Stack direction='column' justifyContent='center' spacing={2} style={{ textAlign: 'center' }}>
        <Typography variant='h6' fontWeight='bold'>
          予約枠設定
        </Typography>
        <Typography variant='caption'>
          診療時間や、予約可能枠に関する情報を設定します。<br />
          ここで設定した内容は、患者様に表示される予約ページ等にも反映されます。
        </Typography>
      </Stack>
    </Box>
    <Card>
      <CardContent>
        <TabContext value={state.currentTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={state.currentTab}
              onChange={handleTabChange}
              variant='fullWidth'
              aria-label="reservation settings tab"
            >
              <Tab label="基本設定（曜日ごとに設定）" style={{ fontWeight: 'bold', fontSize: '16px' }} value="base" />
              <Tab label="特定日設定（日付単位で設定）" style={{ fontWeight: 'bold', fontSize: '16px' }} value="day" />
            </Tabs>
          </Box>
          <TabPanel value='base' sx={{ padding: 0 }}>
            <Suspense fallback={<CircularProgress />}>
              <ReservationBaseSettingsContent />
            </Suspense>
          </TabPanel>
          <TabPanel value='day' sx={{ padding: 0 }}>
            <ReservationDaySettingsLayout />
          </TabPanel>
        </TabContext>
      </CardContent>
    </Card>
    <div style={{ marginTop: '16px' }} />
    <Toolbar />
  </ClinicPageBase>
};

export default ReservationSettingsPage;