import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { FC } from "react";
import useNotSaveConfirmDialogState from "./useNotSaveConfirmDialogState";

export type ChangeDayConfirmDialogProps = {
  onClickNotSave: (() => void);
};

/**
 * 保存せず移動するか確認用ダイアログのコンポーネント
 * @param onClickNotSave 保存せず移動するときのコールバック
 * @constructor
 * @group Components
 * @category features/ReservationSettingsPage
 */
const NotSaveConfirmDialog: FC<ChangeDayConfirmDialogProps> = ({ onClickNotSave }) => {
  const { notSaveConfirmState, closeDialog } = useNotSaveConfirmDialogState();
  return <Dialog open={notSaveConfirmState.isOpen}>
    <DialogTitle>予約枠設定の変更があります。</DialogTitle>
    <DialogContent>
      <Stack direction='column' spacing={2} justifyContent='center' alignItems='center'>
        予約枠設定を保存せずに移動しようとしています。<br />
        それでもよろしいでしょうか？
      </Stack>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => closeDialog()}>
        閉じる
      </Button>
      <Box pl={1} />
      <Button autoFocus variant='contained' onClick={onClickNotSave}>
        保存せず次へ
      </Button>
    </DialogActions>
  </Dialog>
};

export default NotSaveConfirmDialog;