import { useEffect, useState } from 'react';
import lodash from 'lodash';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const getBreakpoint = (width: number): Breakpoint => {
  if (width < 600) {
    return 'xs';
  }
  if (width < 900) {
    return 'sm';
  }
  if (width < 1280) {
    return 'md';
  }
  if (width < 1920) {
    return 'lg';
  }
  
  return 'xl';
};

const useBreakpoint = () => {
  const [ innerWidth, setInnerWidth ] = useState(window.innerWidth);
  const [ innerHeight, setInnerHeight ] = useState(window.innerHeight);
  // ブレイクポイントを設定
  const [ breakpoint, setBreakpoint ] = useState(
    getBreakpoint(window.innerWidth),
  );
  
  useEffect(() => {
    // resize の度に処理しないように throttle で間引く
    const calcInnerSize = lodash.throttle(() => {
      setBreakpoint(getBreakpoint(window.innerWidth));
      setInnerWidth(window.innerWidth);
      setInnerHeight(window.innerHeight);
    }, 200);
    
    window.addEventListener('resize', calcInnerSize);
    
    // アンマウント時にクリーンアップ
    return () => window.removeEventListener('resize', calcInnerSize);
  }, []);
  
  return { innerHeight, innerWidth, breakpoint };
};

export default useBreakpoint;