import { useRecoilState } from "recoil";
import React, { useCallback, useEffect, useMemo } from "react";
import { useAuthUser } from "@react-query-firebase/auth";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { format, isSameDay } from "date-fns";
import { ja } from "date-fns/locale";
import * as lodash from 'lodash';
import { useConfirm } from "material-ui-confirm";
import { Stack, Typography } from "@mui/material";
import reservationBaseSettingsContentStateAtom
  from "../../../recoil/features/ReservationSettingsPage/ReservationBaseSettingsContent";
import { auth } from "../../../firebase";
import Time from "../../../types/Time";
import useResultAlertState from "../../../components/ResultAlert/useResultAlertState";
import IReservationSetting, { initialReservationSettings } from "../../../interfaces/IReservationSetting";
import {
  getReservationBaseFrames,
  getReservationBaseSettings,
  getReservationDaySettings,
  resetReservationBaseFrames,
  setReservationBaseFrames,
  setReservationBaseSettings,
  setReservationDayFrames,
  setReservationDaySettings
} from "../../../repositories/reservationSettingRepository";
import IReservationFrame from "../../../interfaces/IReservationFrame";
import {
  date2DayOfWeek,
  date2epoch,
  dayOfWeekNum2string,
  isAfterTime,
  isBeforeTime,
  isSameTime
} from "../../../utils/converter";
import useNotSaveConfirmDialogState from "./ChangeDayConfirmDialog/useNotSaveConfirmDialogState";
import { getClinicInformation } from "../../../repositories/clinicRepository";
import { getReservationInformationByDayOfWeek } from "../../../repositories/reserveRepository";

const _ = lodash;

/**
 * 曜日ごとの予約枠設定のレイアウトの状態管理やロジックをまとめたHooks
 * @group Components
 * @category features/ReservationSettingsPage
 */
const useReservationBaseSettingsContentState = () => {
    const queryClient = useQueryClient();
    const confirm = useConfirm();
    
    // ログイン中のユーザー情報の監視
    const clinicUser = useAuthUser([ 'user' ], auth);
    const [ state, setState ] = useRecoilState(reservationBaseSettingsContentStateAtom);
    const { openDialog, closeDialog, notSaveConfirmState } = useNotSaveConfirmDialogState();
    const { openAlert } = useResultAlertState();
    
    const { data: clinicInfo } = useQuery(
      [ 'getClinicInformation', clinicUser.data?.uid ?? "" ],
      () => getClinicInformation(clinicUser.data?.uid ?? ""),
    );
    
    /**
     * セーブされている状態を判別するフラグ
     */
    const isSave = useMemo(() => {
      if (state.currentSetting === undefined || state.initSetting === undefined) {
        return true;
      }
      return state.currentSetting.isOpen === state.initSetting.isOpen && state.currentSetting.openAt.hour === state.initSetting.openAt.hour
        && state.currentSetting.openAt.minute === state.initSetting.openAt.minute && state.currentSetting.closeAt.hour === state.initSetting.closeAt.hour
        && state.currentSetting.closeAt.minute === state.initSetting.closeAt.minute && state.currentSetting.isReserveFramesShow === state.initSetting.isReserveFramesShow
        && state.currentFrames === state.initFrames
    }, [ state.currentFrames, state.currentSetting, state.initFrames, state.initSetting ])
    
    /**
     * 日付がクリックされた際の処理
     */
    const onClickDay = useCallback((day: Date) => {
      // 設定に変更があれば設定を保存しなくて良いか確認する
      if (isSave) {
        setState((prev) => ({ ...prev, currentDate: day }))
        return;
      }
      if (!isSameDay(state.currentDate, day)) {
        openDialog(day);
      }
    }, [ isSave, openDialog, setState, state.currentDate ]);
    
    /**
     * 保存しないで別日付の選択した場合の処理
     */
    const onClickNotSave = () => {
      if (notSaveConfirmState.day === undefined) {
        return;
      }
      const date = notSaveConfirmState.day;
      setState((prev) => ({ ...prev, currentDate: date }));
      closeDialog();
    }
    
    /**
     * 初回時にFirestore上のすべての曜日の予約枠設定（初期値）を取得するクエリ。
     */
    const { data: baseSettings } = useQuery(
      [ 'getReservationBaseSettings', clinicUser.data?.uid ],
      () => getReservationBaseSettings(clinicUser.data?.uid ?? ''),
    );
    
    /**
     * 編集前のFirestore上の曜日別(選択中の曜日)の予約枠設定（初期値）
     */
    const currentBaseReservationSetting = useMemo(() => {
      if (baseSettings) {
        return baseSettings[date2DayOfWeek(state.currentDate)]
      }
      return undefined;
    }, [ baseSettings, state.currentDate ]);
    
    /**
     * 編集前のFirestore上の曜日別の予約枠一覧（初期値）を取得するクエリ。
     */
    const { data: currentBaseReservationFrames } = useQuery(
      [ 'getReservationBaseFrames', clinicUser.data?.uid, state.currentDate ],
      () => getReservationBaseFrames(clinicUser.data?.uid ?? '', dayOfWeekNum2string(date2DayOfWeek(state.currentDate)))
    );
    
    useEffect(() => {
      if (currentBaseReservationSetting) {
        setState((prev) => ({
          ...prev,
          currentSetting: currentBaseReservationSetting,
          initSetting: currentBaseReservationSetting,
        }))
      }
    }, [ currentBaseReservationSetting, setState ]);
    
    useEffect(() => {
      if (currentBaseReservationFrames) {
        setState((prev) => ({
          ...prev,
          currentFrames: currentBaseReservationFrames,
          initFrames: currentBaseReservationFrames,
        }))
      }
    }, [ currentBaseReservationFrames, setState ]);
    
    /**
     * 曜日に応じて、アイテムの更新を状態に反映させる。
     * @param reservationSetting 変更後の状態の設定
     */
    const updateSetting = (reservationSetting: IReservationSetting) => {
      setState((prev) => ({ ...prev, currentSetting: reservationSetting }))
    };
    
    /**
     * 曜日に応じて、アイテムの更新を状態に反映させる。
     * @param frames
     */
    const updateFrames = (frames: IReservationFrame[]) => {
      setState((prev) => ({ ...prev, currentFrames: frames }))
    };
    
    /**
     * 開始時刻が変更された時
     * @param time TimeSelectFromから通知される、変更後の時刻
     */
    const onChangeOpenAt = (time: Time) => {
      const beforeSetting = _.cloneDeep(state.currentSetting);
      if (beforeSetting) {
        beforeSetting.openAt = time;
        updateSetting(beforeSetting);
      }
    };
    
    /**
     * 終了時刻が変更された時
     * @param time TimeSelectFormから通知される、変更後の時刻
     */
    const onChangeCloseAt = (time: Time) => {
      const beforeSetting = _.cloneDeep(state.currentSetting);
      if (beforeSetting) {
        beforeSetting.closeAt = time;
        updateSetting(beforeSetting);
      }
    };
    
    /**
     * 診療日・休診日のスイッチが切り替えられた際の処理
     */
    const onChangeIsOpen = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const beforeSetting = _.cloneDeep(state.currentSetting);
        if (beforeSetting) {
          beforeSetting.isOpen = true;
          updateSetting(beforeSetting);
        }
      } else {
        switch (format(state.currentDate, 'EEE', { locale: ja })) {
          case '日':
            updateSetting(initialReservationSettings(false, 'base', 0));
            break
          case '月':
            updateSetting(initialReservationSettings(false, 'base', 1));
            break
          case '火':
            updateSetting(initialReservationSettings(false, 'base', 2));
            break
          case '水':
            updateSetting(initialReservationSettings(false, 'base', 3));
            break
          case '木':
            updateSetting(initialReservationSettings(false, 'base', 4));
            break
          case '金':
            updateSetting(initialReservationSettings(false, 'base', 5));
            break
          case '土':
            updateSetting(initialReservationSettings(false, 'base', 6));
            break
          default:
        }
        updateFrames([]);
      }
    };
    
    /**
     * 患者側に予約枠を表示・非表示のスイッチが切り替えられた際の処理
     */
    const onChangeIsReserveFramesShow = (event: React.ChangeEvent<HTMLInputElement>) => {
      const beforeSetting = _.cloneDeep(state.currentSetting);
      if (!beforeSetting) {
        return;
      }
      beforeSetting.isReserveFramesShow = event.target.checked;
      updateSetting(beforeSetting);
    }
    
    /**
     * 予約枠追加ダイアログが閉じられた時の処理
     * @param reservationFrameItem ダイアログで設定された休憩時間
     */
    const onCloseCreateReservationFrameDialog = (reservationFrameItem: IReservationFrame) => {
      const beforeFrames = _.cloneDeep(state.currentFrames);
      beforeFrames?.push(reservationFrameItem);
      beforeFrames.sort((a, b) => {
          const startA = new Date();
          startA.setHours(a.startAt.hour, a.startAt.minute, 0, 0);
          const startB = new Date();
          startB.setHours(b.startAt.hour, b.startAt.minute, 0, 0);
          if (startA < startB) {
            return -1;
          }
          if (startA > startB) {
            return 1;
          }
          return 0;
        }
      );
      updateFrames(beforeFrames);
    };
    
    /**
     * 予約枠Frameの削除ボタンが押された際の処理
     * @param index
     */
    const onDeleteReservationFrame = (index: number) => {
      const beforeFrames = _.cloneDeep(state.currentFrames);
      beforeFrames?.splice(index, 1);
      updateFrames(beforeFrames);
    };
    
    /**
     * 予約枠設定情報のリフレッシュ
     */
    const onClickCancel = () => {
      void queryClient.resetQueries([ 'getReservationBaseSettings', clinicUser.data?.uid ]);
      void queryClient.resetQueries([ 'getReservationBaseFrames', clinicUser.data?.uid, state.currentDate ]);
    }
    
    /**
     * 予約枠基本設定情報をFirestoreに保存する。
     */
    const saveReservationBaseSettings = useCallback(async () => {
      if (!clinicUser.data?.uid) {
        return;
      }
      if (!state.currentSetting) {
        return;
      }
      if (state.currentSetting.isOpen && (
        isAfterTime(state.currentSetting.openAt, state.currentSetting.closeAt) ||
        isSameTime(state.currentSetting.openAt, state.currentSetting.closeAt)
      )) {
        throw new Error("診療時間の終了時刻は開始時刻より後の時刻を設定してください。");
      }
      if (state.currentSetting.isOpen && state.currentFrames.length > 0 && state.currentFrames[0].startAt
        && (isAfterTime(state.currentSetting.openAt, state.currentFrames[0].startAt))) {
        throw new Error("予約枠の時刻設定は、診療時間の開始時刻より後の時刻かつ終了時刻より先の時刻を設定してください。");
      }
      if (state.currentSetting.isOpen && state.currentFrames.length > 0 && state.currentFrames[state.currentFrames.length - 1].endAt
        && (isBeforeTime(state.currentSetting.closeAt, state.currentFrames[state.currentFrames.length - 1].endAt))) {
        throw new Error("予約枠の時刻設定は、診療時間の開始時刻より後の時刻かつ終了時刻より先の時刻を設定してください。");
      }
      // 既に指定曜日の予約枠設定されている場合、その予約枠設定に予約情報があるか確認する
      const reservedDateList: Date[] = [];
      if (state.initSetting) {
        const reservedList = await getReservationInformationByDayOfWeek(clinicUser.data.uid, state.initSetting.dayOfWeekOrDate);
        // eslint-disable-next-line no-restricted-syntax
        for (const reservation of reservedList) {
          const tmpDate = new Date(reservation.reservationDate);
          const result = await getReservationDaySettings(clinicUser.data.uid, tmpDate);
          if (result === null) {
            // 指定曜日の予約枠設定に予約情報がある場合、その日付を詰め込む
            if (date2DayOfWeek(tmpDate) === state.initSetting.dayOfWeekOrDate) {
              reservedDateList.push(tmpDate);
            }
          }
        }
      }
      // 既に予約されている予約枠の日付があれば、特定日予約枠を作成する旨をconfirm出す
      if (reservedDateList.length > 0) {
        try {
          await confirm({
            title: "曜日ごとの予約枠設定の変更保存の前に確認",
            description: <Stack direction='column' spacing={0}>
              <Typography variant='body1' textAlign='start'>
                {`既に設定されている${format(state.currentDate, 'EEE', { locale: ja })}曜日の予約枠設定に予約情報があります。`}
              </Typography>
              <Typography variant='body1' textAlign='start'>
                下記の日付で特定日予約枠設定が自動作成されますが、本当によろしいですか？
              </Typography>
              <Stack direction='column' spacing={1} mt={1}>
                {reservedDateList.map((date) => <Typography variant='caption' textAlign='start'>
                  {`・${format(date, 'yyyy/MM/dd', { locale: ja })}`}
                </Typography>)}
              </Stack>
            </Stack>,
            allowClose: false
          });
          // eslint-disable-next-line no-restricted-syntax
          for (const reservedDate of reservedDateList) {
            const tmpSetting = _.cloneDeep(state.initSetting);
            if (tmpSetting) {
              tmpSetting.dayOfWeekOrDate = date2epoch(reservedDate);
              tmpSetting.isOpen = true;
              tmpSetting.settingType = 'day';
              await setReservationDaySettings(clinicUser.data.uid, reservedDate, tmpSetting);
              await setReservationDayFrames(clinicUser.data.uid, reservedDate, state.initFrames);
            }
          }
        } catch (e) {
          throw new Error("設定保存がキャンセルされました。");
        }
      }
      
      await setReservationBaseSettings(clinicUser.data.uid, state.currentSetting);
      if (currentBaseReservationFrames !== undefined) {
        // 予約枠を一旦リセット
        await resetReservationBaseFrames(clinicUser.data.uid, dayOfWeekNum2string(date2DayOfWeek(state.currentDate)), currentBaseReservationFrames)
      }
      await setReservationBaseFrames(clinicUser.data.uid, dayOfWeekNum2string(date2DayOfWeek(state.currentDate)), state.currentFrames);
      setState((prev) => ({ ...prev, initFrames: state.currentFrames, initSetting: state.currentSetting }))
    }, [ clinicUser.data?.uid, state.currentSetting, state.initSetting, state.currentDate, state.currentFrames, state.initFrames, confirm, currentBaseReservationFrames, setState ]);
    
    /**
     * Firestoreに予約枠基本設定情報を保存する処理。
     */
    const saveReservationBaseSettingsMutate = useMutation<void, Error>(
      () => saveReservationBaseSettings(),
      {
        onSuccess: () => {
          void queryClient.resetQueries([ 'getReservationBaseSettings', clinicUser.data?.uid ]);
          void queryClient.resetQueries([ 'getReservationBaseFrames', clinicUser.data?.uid, state.currentDate ]);
          openAlert('success', '設定を保存しました。');
        },
        onError: (error) => {
          void queryClient.resetQueries([ 'getReservationBaseSettings', clinicUser.data?.uid ]);
          void queryClient.resetQueries([ 'getReservationBaseFrames', clinicUser.data?.uid, state.currentDate ]);
          openAlert('error', error.message)
        }
      })
    
    /**
     * 保存するボタンが押下された際の処理
     */
    const onClickSaveReservationBaseSettings = useCallback(() => {
      void saveReservationBaseSettingsMutate.mutate()
    }, [ saveReservationBaseSettingsMutate ]);
    
    const onClickCopy = useCallback(() => {
      setState((prev) => ({ ...prev, copySetting: prev.currentSetting, copyFrames: prev.currentFrames }));
    }, [ setState ])
    
    const onClickPaste = useCallback(() => {
      const copySetting = _.cloneDeep(state.copySetting);
      if (!copySetting) {
        return;
      }
      copySetting.dayOfWeekOrDate = date2DayOfWeek(state.currentDate);
      setState((prev) => ({ ...prev, currentSetting: copySetting, currentFrames: prev.copyFrames ?? [] }))
    }, [ setState, state ])
    
    return {
      state,
      clinicInfo,
      isSave,
      onClickDay,
      onClickNotSave,
      baseSettings,
      currentSetting: state.currentSetting ?? currentBaseReservationSetting,
      currentFrames: state.currentFrames ?? currentBaseReservationFrames,
      isSaving: saveReservationBaseSettingsMutate.isLoading,
      onClickCancel,
      onClickSaveReservationBaseSettings,
      onChangeOpenAt,
      onChangeCloseAt,
      onChangeIsOpen,
      onChangeIsReserveFramesShow,
      onCloseCreateReservationFrameDialog,
      onDeleteReservationFrame,
      onClickCopy,
      onClickPaste,
    };
  }
;

export default useReservationBaseSettingsContentState;