import { useRecoilState } from "recoil";
import navDrawerMobileStateAtom from "../../recoil/components/NavDrawer";

/**
 * スマートフォン向けナビゲーションドロワーの表示制御
 * @group Components
 * @category components
 */
const useNavDrawerMobileState = () => {
  const [ state, setState ] = useRecoilState(navDrawerMobileStateAtom);
  
  /**
   * 表示を隠す
   */
  const handleClose = () => {
    setState((prev) => ({ ...prev, isOpen: false }));
  };
  
  /**
   * 表示状態を切り替える
   */
  const handleToggle = () => {
    setState((prev) => ({ ...prev, isOpen: !prev.isOpen }));
  }
  
  return { state, handleToggle, handleClose };
};

export default useNavDrawerMobileState;