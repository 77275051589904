import { Typography } from "@mui/material";
import useTimeTextState from "./useTimeTextState";

/**
 * 現在時刻表示コンポーネント
 * @constructor
 * @group Components
 * @category components
 */
const TimeText = () => {
  const time = useTimeTextState();
  return <Typography
    variant='h5'
    fontWeight='bold'
    fontSize='24px'
    sx={{
      flexGrow: {
        'xs': 1,
        'md': 0,
      },
      display: {
        'xs': 'none',
        'sm': 'block',
      },
    }}
  >
    現在時刻:&nbsp;&nbsp;{time}
  </Typography>
};

export default TimeText;