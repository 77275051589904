import { atom } from "recoil";
import { PasswordSettingErrors } from "../../../interfaces/IAuthInformation";

export type PasswordSettingPageState = {
  /** 現在のパスワード */
  currentPassword: string;
  /** 新しいパスワード */
  newPassword: string;
  /** バリデーションエラー */
  errors: PasswordSettingErrors | undefined;
};

const initialState: PasswordSettingPageState = {
  currentPassword: '',
  newPassword: '',
  errors: undefined,
};

const passwordSettingPageStateAtom = atom({
  key: 'PasswordSettingPageState',
  default: initialState,
});

export default passwordSettingPageStateAtom;