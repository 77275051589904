import { useRecoilState } from "recoil";
import { useAuthUser } from "@react-query-firebase/auth";
import React, { useCallback, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as lodash from 'lodash';
import { useNavigate } from "react-router";
import { FirebaseError } from "firebase/app";
import notificationSettingsPageStateAtom from "../../recoil/features/NotificationSettingsPage";
import { auth } from "../../firebase";
import { getNotificationSetting, setNotificationSetting } from "../../repositories/notificationSettingRepository";
import INotificationSetting, {
  initialNotificationSettings,
  validateNotificationSetting
} from "../../interfaces/INotificationSetting";
import useResultAlertState from "../../components/ResultAlert/useResultAlertState";
import { loginPagePath } from "../../layout/urls";
import { firebaseError2ErrorMessage } from "../../utils/converter";

const _ = lodash;

/**
 * 通知設定ページの状態管理やロジックをまとめたHooks
 * @group Components
 * @category features/NotificationSettingsPage
 */
const useNotificationSettingsPageState = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  
  // ログイン中のユーザー情報の監視
  const clinicUser = useAuthUser([ 'user' ], auth);
  const [ state, setState ] = useRecoilState(notificationSettingsPageStateAtom);
  const { openAlert } = useResultAlertState();
  
  const { data: notificationSetting } = useQuery(
    [ 'getNotificationSetting', clinicUser.data?.uid ?? '' ],
    () => getNotificationSetting(clinicUser.data?.uid ?? ''),
  )
  
  useEffect(() => {
    if (notificationSetting) {
      setState((prev) => ({ ...prev, currentSetting: notificationSetting }))
    } else {
      setState((prev) => ({ ...prev, currentSetting: initialNotificationSettings(clinicUser.data?.uid ?? '') }))
    }
  }, [ clinicUser.data?.uid, notificationSetting, setState ]);
  
  /**
   * アイテムの更新を状態に反映させる。
   * @param newNotificationSetting 設定値更新後のNotificationSetting
   */
  const updateSetting = (newNotificationSetting: INotificationSetting) => {
    setState((prev) => ({ ...prev, currentSetting: newNotificationSetting }))
  }
  
  /**
   * 通知設定全般ON/OFのコントロールフラグ
   * @param beforeSetting 設定値更新前のNotificationSetting
   * @param targetChecked 通知設定全般ON/OF
   */
  const controlIsEnable = (beforeSetting: INotificationSetting, targetChecked: boolean) => {
    const temp = beforeSetting;
    if (targetChecked) {
      temp.isEnable = true;
    }
    if (!beforeSetting.beforeXEnable &&
      !beforeSetting.completedEnable &&
      !beforeSetting.canceledEnable &&
      !beforeSetting.prevDayEnable) {
      temp.isEnable = false;
    }
    return temp;
  }
  
  /**
   * 通知機能のON/OFF設定変更
   * @param event onChangeイベント
   */
  const onChangeIsEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    const beforeSetting = _.cloneDeep(state.currentSetting);
    if (beforeSetting) {
      if (event.target.checked) {
        beforeSetting.isEnable = event.target.checked;
      } else {
        beforeSetting.isEnable = event.target.checked;
        beforeSetting.beforeXEnable = false;
        beforeSetting.completedEnable = false;
        beforeSetting.canceledEnable = false;
        beforeSetting.prevDayEnable = false;
      }
      updateSetting(beforeSetting);
    }
  };
  
  /**
   * 予約順のX番前になったときのX番目の設定変更
   * @param value X番目
   */
  const onChangeBeforeXNumber = (value: number) => {
    const beforeSetting = _.cloneDeep(state.currentSetting);
    if (beforeSetting) {
      beforeSetting.beforeXNum = value;
      updateSetting(beforeSetting);
    }
  }
  
  /**
   * 予約順のX番前になったときの通知のON/OFF設定変更
   * @param event onChangeイベント
   */
  const onChangeBeforeXEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    const beforeSetting = _.cloneDeep(state.currentSetting);
    if (beforeSetting) {
      beforeSetting.beforeXEnable = event.target.checked;
      const result = controlIsEnable(beforeSetting, event.target.checked);
      updateSetting(result);
    }
  };
  
  /**
   * 予約順のX番前になったときの通知メッセージの設定変更
   * @param value 通知メッセージ
   */
  const onChangeBeforeXMessage = (value: string) => {
    const beforeSetting = _.cloneDeep(state.currentSetting);
    if (beforeSetting) {
      beforeSetting.beforeXMessage = value;
      updateSetting(beforeSetting);
    }
  }
  
  /**
   * 予約完了時の通知のON/OFF設定変更
   * @param event onChangeイベント
   */
  const onChangeCompletedEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    const beforeSetting = _.cloneDeep(state.currentSetting);
    if (beforeSetting) {
      beforeSetting.completedEnable = event.target.checked;
      const result = controlIsEnable(beforeSetting, event.target.checked);
      updateSetting(result);
    }
  };
  
  /**
   * 予約完了時の通知メッセージの設定変更
   * @param value 通知メッセージ
   */
  const onChangeCompletedMessage = (value: string) => {
    const beforeSetting = _.cloneDeep(state.currentSetting);
    if (beforeSetting) {
      beforeSetting.completedMessage = value;
      updateSetting(beforeSetting);
    }
  }
  
  /**
   * 予約キャンセル時の通知のON/OFF設定変更
   * @param event onChangeイベント
   */
  const onChangeCanceledEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    const beforeSetting = _.cloneDeep(state.currentSetting);
    if (beforeSetting) {
      beforeSetting.canceledEnable = event.target.checked;
      const result = controlIsEnable(beforeSetting, event.target.checked);
      updateSetting(result);
    }
  };
  
  /**
   * 予約キャンセル時の通知メッセージの設定変更
   * @param value 通知メッセージ
   */
  const onChangeCanceledMessage = (value: string) => {
    const beforeSetting = _.cloneDeep(state.currentSetting);
    if (beforeSetting) {
      beforeSetting.canceledMessage = value;
      updateSetting(beforeSetting);
    }
  }
  
  /**
   * 予約前日時の通知のON/OFF設定変更
   * @param event onChangeイベント
   */
  const onChangePrevDayEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    const beforeSetting = _.cloneDeep(state.currentSetting);
    if (beforeSetting) {
      beforeSetting.prevDayEnable = event.target.checked;
      const result = controlIsEnable(beforeSetting, event.target.checked);
      updateSetting(result);
    }
  };
  
  /**
   * 予約前日時の通知メッセージの設定変更
   * @param value 通知メッセージ
   */
  const onChangePrevDayMessage = (value: string) => {
    const beforeSetting = _.cloneDeep(state.currentSetting);
    if (beforeSetting) {
      beforeSetting.prevDayMessage = value;
      updateSetting(beforeSetting);
    }
  }
  
  /**
   * 通知設定のリフレッシュトリガー
   */
  const onClickCancel = () => {
    setState((prev) => ({ ...prev, errors: undefined }));
    void queryClient.resetQueries([ 'getNotificationSetting', clinicUser.data?.uid ?? '' ])
  }
  
  /**
   * 通知設定の更新を保存する。
   */
  const saveNotificationSetting = useCallback(async () => {
    if (!clinicUser.data?.uid) {
      navigate(loginPagePath);
      throw new Error("再認証してからお試しください。");
    }
    const errors = validateNotificationSetting(state.currentSetting);
    if (errors) {
      setState((prev) => ({ ...prev, errors }))
      throw new Error("入力情報を確認してください。");
    }
    setState((prev) => ({ ...prev, errors: undefined }));
    await setNotificationSetting(clinicUser.data.uid, state.currentSetting)
  }, [ clinicUser.data, navigate, setState, state.currentSetting ]);
  
  /**
   * 通知設定更新処理
   */
  const saveNotificationSettingMutate = useMutation<void, Error>(
    () => saveNotificationSetting(),
    {
      onSuccess: () => {
        void queryClient.resetQueries([ 'getNotificationSetting', clinicUser.data?.uid ?? '' ])
        openAlert('success', '設定を保存しました。');
      },
      onError: (error) => {
        if (error instanceof FirebaseError) {
          const message = firebaseError2ErrorMessage(error);
          openAlert("error", message);
          return;
        }
        openAlert('error', error.message);
      }
    }
  );
  
  /**
   * メッセージプレビューの変更を反映する処理
   */
  const messagePreview = useCallback((messageRaw: string) => {
    const beforeNumReplace = messageRaw.replace("{beforeNum}", `${state.currentSetting.beforeXNum}`)
    const beforeMinutesReplace = beforeNumReplace.replace("{beforeMinutes}", "15")
    return beforeMinutesReplace.replace("{time}", "YYYY年MM月DD日 HH:mm 頃に来院して下さい。");
  }, [ state.currentSetting.beforeXNum ])
  
  /**
   * 保存ボタンクリック時の処理
   */
  const onClickSaveSettings = useCallback(() => {
    void saveNotificationSettingMutate.mutate();
  }, [ saveNotificationSettingMutate ]);
  
  return {
    state,
    isSaving: saveNotificationSettingMutate.isLoading,
    onChangeIsEnabled,
    onChangeBeforeXNumber,
    onChangeBeforeXEnabled,
    onChangeBeforeXMessage,
    onChangeCompletedEnabled,
    onChangeCompletedMessage,
    onChangeCanceledEnabled,
    onChangeCanceledMessage,
    onChangePrevDayEnabled,
    onChangePrevDayMessage,
    onClickSaveSettings,
    onClickCancel,
    messagePreview,
  };
};

export default useNotificationSettingsPageState;