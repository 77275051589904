import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from "@mui/material";
import { FC } from "react";
import TimeSelectForm from "../../../../components/TimeSelectForm/TimeSelectForm";
import useCreateReservationFrameDialogState from "./useCreateReservationFrameDialogState";
import Time from "../../../../types/Time";
import InputForm from "../../../../components/InputForm/InputForm";
import IReservationFrame from "../../../../interfaces/IReservationFrame";

export type CreateReservationFrameDialogProps = {
  openAt: Time | undefined,
  closeAt: Time | undefined,
  currentFrames: IReservationFrame[],
  onClose?: ((reservationFrameItem: IReservationFrame) => void);
};

/**
 * 予約枠追加ダイアログのコンポーネント
 * @param openAt 予約枠の開始時刻
 * @param closeAt 予約枠の終了時刻
 * @param currentFrames 現在表示中の予約枠一覧
 * @param onClose ダイアログを閉じるときのコールバック
 * @constructor
 * @group Components
 * @category features/ReservationSettingsPage
 */
const CreateReservationFrameDialog: FC<CreateReservationFrameDialogProps> = ({
  openAt,
  closeAt,
  currentFrames,
  onClose = undefined
}) => {
  const {
    state,
    closeDialog,
    onChangeStartAt,
    onChangeEndAt,
    onChangeAvailableNum,
  } = useCreateReservationFrameDialogState();
  return <Dialog open={state.isOpen}>
    <DialogTitle>予約枠追加</DialogTitle>
    <DialogContent dividers>
      <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'>
        <TimeSelectForm fullWidth label='開始時刻' onChange={onChangeStartAt} min={openAt} max={closeAt} />
        <Typography variant='body1'>～</Typography>
        <TimeSelectForm fullWidth label='終了時刻' onChange={onChangeEndAt} min={openAt} max={closeAt} />
        <InputForm label="診察可能人数" type='number' value={state.availableNum}
                   onChange={(str) => onChangeAvailableNum(str)} />
      </Stack>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => closeDialog(openAt, closeAt, currentFrames, undefined)}>
        閉じる
      </Button>
      <Box pl={1} />
      <Button autoFocus variant='contained' onClick={() => closeDialog(openAt, closeAt, currentFrames, onClose)}>
        追加する
      </Button>
    </DialogActions>
  </Dialog>
};

export default CreateReservationFrameDialog;
