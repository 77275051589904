import { atom } from "recoil";
import { EmailSettingErrors } from "../../../interfaces/IAuthInformation";

export type EmailSettingPageState = {
  /** 現在のパスワード */
  currentPassword: string;
  /** 新しいメールアドレス */
  newEmail: string;
  /** 最終のメールアドレス */
  resultEmail: string;
  /** バリデーションエラー */
  errors: EmailSettingErrors | undefined;
};

const initialState: EmailSettingPageState = {
  currentPassword: '',
  newEmail: '',
  resultEmail: '',
  errors: undefined,
};

const emailSettingPageStateAtom = atom({
  key: 'EmailSettingPageState',
  default: initialState,
});

export default emailSettingPageStateAtom;