import { atom } from "recoil";
import Time from "../../../../types/Time";

export type CreateReservationFrameDialogState = {
  /** ダイアログ表示かどうか */
  isOpen: boolean;
  /** 予約枠の開始時刻の入力値 */
  startAt: Time | undefined;
  /** 予約枠の終了時刻の入力値 */
  endAt: Time | undefined;
  /** 予約可能人数の入力値 */
  availableNum: string | undefined;
};

const initialState: CreateReservationFrameDialogState = {
  isOpen: false,
  startAt: undefined,
  endAt: undefined,
  availableNum: undefined,
};

const createReservationFrameDialogStateAtom = atom({
  key: 'CreateReservationFrameDialogState',
  default: initialState,
});

export default createReservationFrameDialogStateAtom;