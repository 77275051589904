import { atom } from "recoil";
import IClinicInformation, { ClinicInformationErrors } from "../../../interfaces/IClinicInformation";

type BasicSettingsPageState = {
  /** クリニック名 */
  clinicName: string;
  /** 郵便番号 */
  postalCode: string;
  /** 住所 */
  address: string;
  /** 電話番号 */
  phoneNumber: string;
  /** 診療科目 */
  departments: string;
  /** 自由入力 */
  freeInputs: string;
  /** 何か月先まで予約可能か */
  reservationPeriod: string;
  /** 当日予約可能か */
  isAvailableSameDayReserve: string;
  /** 当日予約の受付締切 */
  sameDayReservePeriod: string;
  /** 日曜日スタート: 0, 月曜日スタート: 1 */
  calendarStart: number;
  /** クリニック基本設定 */
  clinicInfo: IClinicInformation | undefined;
  /** バリデーションエラー */
  errors: ClinicInformationErrors | undefined;
};

const initialState: BasicSettingsPageState = {
  clinicName: '',
  postalCode: '',
  address: '',
  phoneNumber: '',
  departments: '',
  freeInputs: '',
  reservationPeriod: '',
  isAvailableSameDayReserve: "disable",
  sameDayReservePeriod: '',
  calendarStart: 0,
  clinicInfo: undefined,
  errors: undefined,
};

const basicSettingsPageStateAtom = atom({
  key: 'BasicSettingsPageState',
  default: initialState,
});

export default basicSettingsPageStateAtom;