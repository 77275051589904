import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import Time from "../types/Time";
import { DayOfWeek } from "../types/DayOfWeek";
import ReservationSettingType from "../types/ReservationSettingType";

/**
 * 予約枠基本設定を表現するインターフェース
 */
export default interface IReservationSetting {
  /** 予約枠設定ID */
  id?: string,
  /** 診療日かどうか */
  isOpen: boolean,
  /** 診療時間の開始時刻 */
  openAt: Time,
  /** 診療時間の終了時刻 */
  closeAt: Time,
  /** 予約枠設定タイプ */
  settingType: ReservationSettingType,
  /** 曜日(dayOfWeek) or 日付(epoch) */
  dayOfWeekOrDate: DayOfWeek | number,
  /** 受付中かどうか */
  isReserveFramesShow: boolean,
};

/**
 * Firestoreからの情報取得用、データコンバータ。
 * Firestoreからのsnapshotと、予約枠基本設定の型を変換する。
 */
export const reservationSettingConverter: FirestoreDataConverter<IReservationSetting> = {
  toFirestore: (reservationSetting: IReservationSetting): DocumentData => ({
    isOpen: reservationSetting.isOpen,
    openHour: reservationSetting.openAt.hour,
    openMinute: reservationSetting.openAt.minute,
    closeHour: reservationSetting.closeAt.hour,
    closeMinute: reservationSetting.closeAt.minute,
    settingType: reservationSetting.settingType,
    dayOfWeekOrDate: reservationSetting.dayOfWeekOrDate,
    isReserveFramesShow: reservationSetting.isReserveFramesShow,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): IReservationSetting => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      isOpen: data.isOpen as boolean,
      openAt: { hour: data.openHour as number, minute: data.openMinute as number } as Time,
      closeAt: { hour: data.closeHour as number, minute: data.closeMinute as number } as Time,
      settingType: data.settingType as ReservationSettingType,
      dayOfWeekOrDate: data.dayOfWeekOrDate as number,
      isReserveFramesShow: data.isReserveFramesShow as boolean,
    } as IReservationSetting;
  },
};

/**
 * 予約枠基本設定の初期設定を返す。
 * @param isOpen 診療日かどうか
 * @param settingType 予約枠設定タイプ
 * @param dayOfWeekOrDate 曜日(dayOfWeek) or 日付(epoch)
 * @return {IReservationSetting} 予約枠基本設定の初期設定が返る。
 */
export const initialReservationSettings: (isOpen: boolean, settingType: ReservationSettingType, dayOfWeekOrDate: DayOfWeek | number) =>
  IReservationSetting = (isOpen: boolean, settingType, dayOfWeekOrDate) => {
  const openAt = { hour: 9, minute: 0 };
  const closeAt = { hour: 17, minute: 0 };
  return {
    isOpen,
    openAt,
    closeAt,
    settingType,
    dayOfWeekOrDate,
    isReserveFramesShow: true,
  } as IReservationSetting;
};
