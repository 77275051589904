import { useMutation } from "react-query";
import { useCallback } from "react";
import { useConfirm } from "material-ui-confirm";
import { deleteReserve, updateReserveStatus } from "../../../repositories/reserveRepository";
import IReservationInformation from "../../../interfaces/IReservationInformation";
import { ReserveStatus } from "../../../types/ReserveStatus";
import useResultAlertState from "../../../components/ResultAlert/useResultAlertState";

/**
 * 予約アイテムの状態管理やロジックをまとめたHooks
 * @group Components
 * @category features/HomePage
 */
const useReservationListItemState = () => {
  const confirm = useConfirm();
  const { openAlert } = useResultAlertState();
  
  /**
   * 診察予約の削除
   * ※データ削除を行います。
   */
  const deleteReserveMutate = useMutation(
    async (val: { reservationId: string }) => deleteReserve(val.reservationId),
  )
  
  /**
   * 診察ステータス更新ボタンクリック時の処理
   */
  const onClickDeleteReserve = useCallback((reservationInfo: IReservationInformation) => {
    void deleteReserveMutate.mutate({ reservationId: reservationInfo.id });
  }, [ deleteReserveMutate ]);
  
  /**
   * 診察ステータスの更新
   */
  const updateReserveStatusMutate = useMutation(
    async (
      val: { reservationId: string, status: ReserveStatus, before?: number }
    ) =>
      updateReserveStatus(val.reservationId, val.status, val.before),
    {
      onError: (error: Error) => {
        openAlert('error', error.message);
      }
    }
  )
  
  /**
   * 診察ステータス更新ボタンクリック時の処理
   */
  const onClickUpdateReserveStatus = useCallback((reservationInfo: IReservationInformation, nextStatus: ReserveStatus, before?: number) => {
    switch (nextStatus) {
      case 3:
        // 診察中
        if (before && before === 4) {
          confirm({
            title: "実行確認",
            description: "診察済の予約を、診察中ステータスへ戻します。リマインド通知は送信されません。よろしいですか？"
          })
            .then(() => {
              void updateReserveStatusMutate.mutate({ reservationId: reservationInfo.id, status: nextStatus, before });
            })
            .catch(() => {
              // eslint-disable-next-line no-console
              console.debug("operation canceled")
            })
        } else {
          confirm({
            title: "実行確認",
            description: "診察中ステータスへ移行します。リマインド通知をONに設定している場合は、通知が送信されます。"
          })
            .then(() => {
              void updateReserveStatusMutate.mutate({ reservationId: reservationInfo.id, status: nextStatus, before });
            })
            .catch(() => {
              // eslint-disable-next-line no-console
              console.debug("operation canceled")
            })
        }
        break;
      case 9:
        // キャンセル
        confirm({
          title: "実行確認",
          description: "この診察予約をキャンセルします。キャンセル通知をONに設定している場合は、通知が送信されます。"
        })
          .then(() => {
            void updateReserveStatusMutate.mutate({ reservationId: reservationInfo.id, status: nextStatus, before });
          })
          .catch(() => {
            // eslint-disable-next-line no-console
            console.debug("operation canceled")
          })
        break;
      case 1:
      case 2:
      case 4:
      default:
        void updateReserveStatusMutate.mutate({ reservationId: reservationInfo.id, status: nextStatus, before });
        break;
    }
  }, [ confirm, updateReserveStatusMutate ]);
  
  return {
    onClickDeleteReserve,
    onClickUpdateReserveStatus,
    updateReserveStatusMutate,
  }
}

export default useReservationListItemState;