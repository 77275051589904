import { useAuthUser } from "@react-query-firebase/auth";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useRecoilState, useResetRecoilState } from "recoil";
import React, { useCallback, useEffect, useMemo } from "react";
import _ from "lodash";
import { addDays, isSameDay, startOfWeek } from "date-fns";
import { FirebaseError } from "firebase/app";
import { auth } from "../../../firebase";
import daySettingsContentStateAtom
  from "../../../recoil/features/ReservationSettingsPage/ReservationDaySettingsContent";
import useResultAlertState from "../../../components/ResultAlert/useResultAlertState";
import useNotSaveConfirmDialogState
  from "../ReservationBaseSettingsContent/ChangeDayConfirmDialog/useNotSaveConfirmDialogState";
import {
  getReservationDaySettings,
  getReservationDayFrames,
  setReservationDaySettings,
  setReservationDayFrames,
  resetReservationDayFrames,
  getReservationDaySettingsFromDate, deleteReservationDaySetting
} from "../../../repositories/reservationSettingRepository";
import Time from "../../../types/Time";
import IReservationSetting, { initialReservationSettings } from "../../../interfaces/IReservationSetting";
import IReservationFrame from "../../../interfaces/IReservationFrame";
import {
  date2DayOfWeek,
  date2epoch,
  firebaseError2ErrorMessage,
  isAfterTime,
  isSameTime
} from "../../../utils/converter";
import { getClinicInformation } from "../../../repositories/clinicRepository";
import useWeeklyCalendarState from "../../../components/WeeklyCalendar/useWeeklyCalendarState";
import { getReservationInformationAtDate } from "../../../repositories/reserveRepository";

/**
 * 日付の予約枠設定のレイアウトの状態管理やロジックをまとめたHooks
 * @group Components
 * @category features/ReservationSettingsPage
 */
const useReservationDaySettingsContentState = () => {
  const queryClient = useQueryClient();
  
  // ログイン中のユーザー情報の監視
  const clinicUser = useAuthUser([ 'user' ], auth);
  const [ state, setState ] = useRecoilState(daySettingsContentStateAtom);
  const resetState = useResetRecoilState(daySettingsContentStateAtom);
  const { openDialog, closeDialog, notSaveConfirmState } = useNotSaveConfirmDialogState();
  const { openAlert } = useResultAlertState();
  const { state: weeklyCalendarState } = useWeeklyCalendarState();
  
  const { data: clinicInfo } = useQuery(
    [ 'getClinicInformation', clinicUser.data?.uid ?? "" ],
    () => getClinicInformation(clinicUser.data?.uid ?? ""),
  );
  const { data: currentReservationInfoList } = useQuery(
    [ "getReservationInformationAtDate", clinicUser.data?.uid ?? "", state.currentDate ],
    () => getReservationInformationAtDate(clinicUser.data?.uid ?? "", state.currentDate)
  )
  
  useEffect(() => () => resetState(), [ resetState ]);
  
  /**
   * 現在表示中の１週間の始まりの日付と終わりの日付
   */
  const startDay = useMemo(() => startOfWeek(weeklyCalendarState.currentMonth, {
      weekStartsOn: clinicInfo?.calendarStart as (0 | 2 | 1 | 3 | 4 | 5 | 6 | undefined) ?? 0
    }
  ), [ clinicInfo?.calendarStart, weeklyCalendarState.currentMonth ]);
  const endDay = useMemo(() => addDays(startDay, 7), [ startDay ])
  
  /**
   * 現在表示中の１週間の始まりから終わりまでに存在する指定日予約枠設定情報。
   */
  const { data: currentWeekReservationDaySettingsRaw } = useQuery(
    [ "getCurrentWeekReservationSettings", clinicUser.data?.uid ?? "", startDay, endDay ],
    () => getReservationDaySettingsFromDate(clinicUser.data?.uid ?? "", startDay.getTime(), endDay.getTime())
  )
  
  /**
   * 現在表示中の１週間の指定日予約枠情報を、曜日ごとに格納した配列。
   * 指定日予約枠情報が存在しない場合は、undefinedが入る。
   * WeeklyCalendarの表示のために利用する。
   */
  const currentWeekReservationDaySettings = useMemo(() => {
    if (!currentWeekReservationDaySettingsRaw) {
      return [];
    }
    const res = new Array<IReservationSetting | undefined>(7);
    for (let i = 0; i < 7; i += 1) {
      const day = addDays(startDay, i)
      const existsIndex = currentWeekReservationDaySettingsRaw.findIndex((value) => isSameDay(value.dayOfWeekOrDate, day))
      if (existsIndex !== -1) {
        const dow = date2DayOfWeek(day) as number;
        res[dow] = currentWeekReservationDaySettingsRaw[existsIndex];
      }
    }
    return res;
  }, [ currentWeekReservationDaySettingsRaw, startDay ])
  
  /**
   * セーブされている状態を判別するフラグ
   */
  const isSave = useMemo(() => {
    if (state.currentSetting === undefined) {
      return true;
    }
    if (state.initSetting === undefined) {
      return false;
    }
    return state.currentSetting.isOpen === state.initSetting.isOpen
      && state.currentSetting.openAt.hour === state.initSetting.openAt.hour
      && state.currentSetting.openAt.minute === state.initSetting.openAt.minute
      && state.currentSetting.closeAt.hour === state.initSetting.closeAt.hour
      && state.currentSetting.closeAt.minute === state.initSetting.closeAt.minute
      && state.currentFrames === state.initFrames
  }, [ state.currentFrames, state.currentSetting, state.initFrames, state.initSetting ])
  
  const isSettingDisable = useMemo(() => {
    if (currentReservationInfoList === undefined) {
      return false;
    }
    return currentReservationInfoList?.length > 0
  }, [ currentReservationInfoList ])
  
  /**
   * 日付がクリックされた際の処理
   */
  const onClickDay = useCallback((day: Date) => {
    // 設定に変更があれば設定を保存しなくて良いか確認する
    if (isSave) {
      setState((prev) =>
        ({ ...prev, currentDate: day, currentSetting: undefined, currentFrames: [] }))
      return;
    }
    if (!isSameDay(state.currentDate, day)) {
      openDialog(day);
    }
  }, [ isSave, openDialog, setState, state.currentDate ]);
  
  /**
   * 保存せずに別日選択へ異動する時の処理
   */
  const onClickNotSave = () => {
    if (notSaveConfirmState.day === undefined) {
      return;
    }
    const date = notSaveConfirmState.day;
    setState((prev) =>
      ({ ...prev, currentDate: date, currentSetting: undefined, currentFrames: [] }))
    closeDialog();
  }
  
  /**
   * 編集前の、現在の選択日のFirestore上の予約枠設定（初期値）を取得するクエリ。
   */
  const { data: currentDaySetting } = useQuery(
    [ 'getReservationDaySettings', clinicUser.data?.uid, state.currentDate ],
    () => getReservationDaySettings(clinicUser.data?.uid ?? '', state.currentDate)
  );
  
  /**
   * 編集前の、現在の選択日のFirestore上の予約枠一覧（初期値）を取得するクエリ。
   */
  const { data: currentDayReservationFrames } = useQuery(
    [ 'getReservationDayFrames', clinicUser.data?.uid, state.currentDate ],
    () => getReservationDayFrames(clinicUser.data?.uid ?? '', state.currentDate)
  );
  
  useEffect(() => {
    if (currentDaySetting) {
      setState((prev) =>
        ({ ...prev, currentSetting: currentDaySetting, initSetting: currentDaySetting }))
    } else {
      setState((prev) => ({ ...prev, initSetting: undefined }))
    }
  }, [ currentDaySetting, setState ]);
  
  useEffect(() => {
    if (currentDayReservationFrames) {
      setState((prev) => ({
        ...prev,
        currentFrames: currentDayReservationFrames,
        initFrames: currentDayReservationFrames
      }))
    }
  }, [ currentDaySetting, currentDayReservationFrames, setState ]);
  
  useEffect(() => {
    if (currentReservationInfoList) {
      setState((prev) => ({
        ...prev,
        currentReservationList: currentReservationInfoList,
      }))
    }
  }, [ currentReservationInfoList, setState ]);
  
  const updateSetting = (reservationSetting: IReservationSetting) => {
    setState((prev) => ({ ...prev, currentSetting: reservationSetting }))
  };
  
  const updateFrames = (frames: IReservationFrame[]) => {
    setState((prev) => ({ ...prev, currentFrames: frames }))
  };
  
  /**
   * 診療日・休診日のスイッチが切り替えられた際の処理
   */
  const onChangeIsOpen = (event: React.ChangeEvent<HTMLInputElement>) => {
    const beforeSetting = _.cloneDeep(state.currentSetting);
    if (beforeSetting) {
      beforeSetting.isOpen = event.target.checked;
      updateSetting(beforeSetting);
    } else {
      const item = _.cloneDeep(initialReservationSettings(false, 'day', date2epoch(state.currentDate)))
      item.isOpen = event.target.checked;
      updateSetting(item);
    }
    if (!event.target.checked) {
      updateFrames([])
    }
  };
  
  /**
   * 開始時刻が変更された時
   * @param time TimeSelectFromから通知される、変更後の時刻
   */
  const onChangeOpenAt = (time: Time) => {
    const beforeSetting = _.cloneDeep(state.currentSetting);
    if (beforeSetting) {
      beforeSetting.openAt = time;
      updateSetting(beforeSetting);
    } else {
      const setting = _.cloneDeep(initialReservationSettings(false, 'day', date2epoch(state.currentDate)))
      setting.openAt = time;
      updateSetting(setting);
    }
  };
  
  /**
   * 終了時刻が変更された時
   * @param time TimeSelectFormから通知される、変更後の時刻
   */
  const onChangeCloseAt = (time: Time) => {
    const beforeSetting = _.cloneDeep(state.currentSetting);
    if (beforeSetting) {
      beforeSetting.closeAt = time;
      updateSetting(beforeSetting);
    } else {
      const setting = _.cloneDeep(initialReservationSettings(false, 'day', date2epoch(state.currentDate)))
      setting.closeAt = time;
      updateSetting(setting);
    }
  };
  
  /**
   * 予約枠追加ダイアログが閉じられた時の処理
   * @param reservationFrameItem ダイアログで設定された予約枠設定
   */
  const onCloseCreateReservationFrameDialog = (reservationFrameItem: IReservationFrame) => {
    const beforeFrames = _.cloneDeep(state.currentFrames);
    beforeFrames?.push(reservationFrameItem);
    beforeFrames.sort((a, b) => {
        const startA = new Date();
        startA.setHours(a.startAt.hour, a.startAt.minute, 0, 0);
        const startB = new Date();
        startB.setHours(b.startAt.hour, b.startAt.minute, 0, 0);
        if (startA < startB) {
          return -1;
        }
        if (startA > startB) {
          return 1;
        }
        return 0;
      }
    );
    updateFrames(beforeFrames);
  };
  
  /**
   * 予約枠Frameの削除ボタンが押された際の処理
   * @param index
   */
  const onDeleteReservationFrame = (index: number) => {
    const beforeFrames = _.cloneDeep(state.currentFrames);
    beforeFrames?.splice(index, 1);
    updateFrames(beforeFrames);
  };
  
  /**
   * 予約枠設定情報のリフレッシュ
   */
  const onClickCancel = () => {
    setState((prev) =>
      ({ ...prev, currentSetting: undefined, currentFrames: [] }))
    void queryClient.resetQueries([ "getCurrentWeekReservationSettings", clinicUser.data?.uid ?? "", startDay, endDay ]);
    void queryClient.resetQueries([ 'getReservationDaySettings', clinicUser.data?.uid, state.currentDate ]);
    void queryClient.resetQueries([ 'getReservationDayFrames', clinicUser.data?.uid, state.currentDate ]);
  }
  
  /**
   * 予約枠指定日設定情報をFirestoreから削除する。
   */
  const deleteReservationDaySettings = useCallback(async () => {
    if (!clinicUser.data?.uid) {
      return;
    }
    if (!state.currentSetting) {
      return;
    }
    await deleteReservationDaySetting(clinicUser.data.uid, state.currentDate);
  }, [ clinicUser.data?.uid, state.currentDate, state.currentSetting ]);
  /**
   * Firestoreに予約枠の削除をする処理。
   */
  const deleteReservationSettingsMutate = useMutation<void, Error>(
    () => deleteReservationDaySettings(),
    {
      onSuccess: () => {
        onClickCancel();
        openAlert('success', '特定日予約枠を削除しました。');
      },
      onError: (error) => {
        if (error instanceof FirebaseError) {
          const message = firebaseError2ErrorMessage(error);
          openAlert("error", message);
          return;
        }
        openAlert('error', error.message)
      }
    })
  /**
   * 予約枠の削除ボタンが押下された際の処理
   */
  const onClickDeleteReservationSettings = useCallback(() => {
    void deleteReservationSettingsMutate.mutate();
  }, [ deleteReservationSettingsMutate ]);
  
  
  /**
   * 予約枠指定日設定情報をFirestoreに保存する。
   */
  const saveReservationDaySettings = useCallback(async () => {
    if (!clinicUser.data?.uid) {
      return;
    }
    if (!state.currentSetting) {
      await setReservationDaySettings(clinicUser.data.uid, state.currentDate,
        initialReservationSettings(false, 'day', date2epoch(state.currentDate)));
      return;
    }
    if (state.currentSetting.isOpen && (
      isAfterTime(state.currentSetting.openAt, state.currentSetting.closeAt) ||
      isSameTime(state.currentSetting.openAt, state.currentSetting.closeAt)
    )) {
      throw new Error("診療時間の終了時刻は開始時刻より後の時刻を設定してください。");
    }
    const reservationList = await getReservationInformationAtDate(clinicUser.data?.uid ?? "", state.currentDate);
    
    // 予約されている予約枠は変更できないように制御
    let isError = false;
    if (reservationList.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const reservation of reservationList) {
        if (state.currentFrames.filter((flame) =>
          flame.startAt.hour === reservation.startHour &&
          flame.startAt.minute === reservation.startMinute).length === 0) {
          isError = true
        }
      }
    }
    if (isError) {
      throw new Error("予約されている予約枠の変更はできません。");
    }
    await setReservationDaySettings(clinicUser.data.uid, state.currentDate, state.currentSetting);
    if (currentDayReservationFrames !== undefined) {
      // 予約枠を一旦リセット
      await resetReservationDayFrames(clinicUser.data.uid, state.currentDate, currentDayReservationFrames);
    }
    await setReservationDayFrames(clinicUser.data.uid, state.currentDate, state.currentFrames);
    setState((prev) => ({
      ...prev,
      initFrames: state.currentFrames,
      initSetting: state.currentSetting,
    }))
  }, [ clinicUser.data?.uid, state.currentSetting, state.currentDate, state.currentFrames, currentDayReservationFrames, setState ]);
  
  
  /**
   * Firestoreに予約枠基本設定情報を保存する処理。
   */
  const saveReservationDaySettingsMutate = useMutation<void, Error>(
    () => saveReservationDaySettings(),
    {
      onSuccess: () => {
        onClickCancel();
        openAlert('success', '設定を保存しました。');
      },
      onError: (error) => {
        if (error instanceof FirebaseError) {
          const message = firebaseError2ErrorMessage(error);
          openAlert("error", message);
          return;
        }
        openAlert('error', error.message)
      }
    })
  
  /**
   * 保存するボタンが押下された際の処理
   */
  const onClickSaveReservationDaySettings = useCallback(() => {
    void saveReservationDaySettingsMutate.mutate();
  }, [ saveReservationDaySettingsMutate ]);
  
  return {
    state,
    clinicInfo,
    currentSetting: state.currentSetting ?? currentDaySetting,
    currentFrames: state.currentFrames ?? currentDayReservationFrames,
    isSaving: saveReservationDaySettingsMutate.isLoading,
    onClickCancel,
    onClickDeleteReservationSettings,
    onClickSaveReservationDaySettings,
    onClickDay,
    isSave,
    isSettingDisable,
    onClickNotSave,
    onChangeIsOpen,
    onChangeOpenAt,
    onChangeCloseAt,
    onCloseCreateReservationFrameDialog,
    onDeleteReservationFrame,
    currentWeekReservationDaySettings,
  };
};

export default useReservationDaySettingsContentState;