import { useMutation } from "react-query";
import { useCallback, useEffect } from "react";
import { useAuthSignInWithEmailAndPassword } from "@react-query-firebase/auth";
import { useRecoilState, useResetRecoilState } from "recoil";
import { useNavigate } from "react-router";
import { FirebaseError } from "firebase/app";
import { auth } from "../../../firebase";
import loginPageStateAtom from "../../../recoil/features/auth/LoginPage/atom";
import useResultAlertState from "../../../components/ResultAlert/useResultAlertState";
import { ISignIn, validateSignIn } from "../../../interfaces/IAuthInformation";
import { checkClinicDeleted } from "../../../repositories/clinicRepository";
import { firebaseError2ErrorMessage } from "../../../utils/converter";

/**
 * ログインページの状態管理やロジックをまとめたHooks
 * @group Components
 * @category features/auth
 */
const useLoginPageState = () => {
  const [ state, setState ] = useRecoilState(loginPageStateAtom);
  const resetState = useResetRecoilState(loginPageStateAtom);
  useEffect(() => () => resetState(), [ resetState ]);
  
  const { openAlert } = useResultAlertState();
  const navigate = useNavigate();
  
  /**
   * メールアドレスの変更を状態に反映させる。
   */
  const onChangeEmail = useCallback((email: string) => {
    setState((prev) => ({ ...prev, email }))
  }, [ setState ]);
  
  /**
   * パスワードの変更を状態に反映させる。
   */
  const onChangePassword = useCallback((password: string) => {
    setState((prev) => ({ ...prev, password }))
  }, [ setState ]);
  
  const authSignInMutation = useAuthSignInWithEmailAndPassword(auth);
  
  /**
   * ログイン実行時の処理
   */
  const login = useCallback(async () => {
    const signInItem: ISignIn = {
      email: state.email,
      password: state.password,
    };
    const errors = validateSignIn(signInItem);
    if (errors) {
      setState((prev) => ({ ...prev, errors }));
      throw new Error("入力内容に間違いがあります。");
    }
    setState((prev) => ({ ...prev, errors: undefined }));
    const isDeleted = await checkClinicDeleted(state.email);
    if (isDeleted) {
      throw new Error("既にクリニックアカウントが削除されています。管理者にお問い合わせ下さい。");
    }
    const res = await authSignInMutation.mutateAsync(signInItem);
    return res.user;
  }, [ authSignInMutation, setState, state.email, state.password ]);
  
  /**
   * ログインの実行結果定義
   */
  const loginMutation = useMutation(
    login,
    {
      onSuccess: (data) => {
        if (!data) {
          openAlert("error", <div>不明なエラーが発生しました。管理者へお問い合わせ下さい。<br />エラーコード：199</div>)
          return
        }
        navigate('/');
      },
      onError: (error) => {
        if (error instanceof FirebaseError) {
          const message = firebaseError2ErrorMessage(error);
          openAlert("error", message);
          return;
        }
        if (error instanceof Error) {
          openAlert("error", error.message);
          return;
        }
        openAlert("error",
          `不明なエラーが発生しました。管理者へお問い合わせ下さい。\nエラーコード：199\n内容：${JSON.stringify(error)}`
        )
      }
    }
  )
  
  /**
   * ログインボタンクリック時の処理
   */
  const onClickLogin = useCallback(() => {
    void loginMutation.mutate();
  }, [ loginMutation ]);
  
  const { isLoading } = loginMutation;
  
  return {
    state, isLoading, onChangeEmail, onChangePassword, onClickLogin
  };
};

export default useLoginPageState;