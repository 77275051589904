import { validateEmail, validatePassword } from "../utils/validator";

/**
 * サインイン実行時のインターフェース
 */
export interface ISignIn {
  email: string,
  password: string,
}

export type SignInErrors = {
  email: string | undefined;
  password: string | undefined;
};
/**
 * サインイン時のバリデーション。
 * @return {SignInErrors | undefined} バリデーションエラーが無い場合は、undefinedが返る。
 * @param auth サインインに必要な情報
 */
export const validateSignIn = (auth: ISignIn): SignInErrors | undefined => {
  const errors: SignInErrors = {
    email: undefined,
    password: undefined,
  };
  errors.email = validateEmail(auth.email);
  errors.password = validatePassword(auth.password);
  if (Object.values(errors).findIndex((element) => typeof element === 'string') !== -1) {
    return errors;
  }
  return undefined;
};

/**
 * パスワード設定実行時のインターフェース
 */
export interface IPasswordSetting {
  currentPassword: string,
  newPassword: string,
}

export type PasswordSettingErrors = {
  currentPassword: string | undefined;
  newPassword: string | undefined;
};
/**
 * パスワード設定のバリデーションチェック
 * @param passwordSetting パスワード設定に必要な情報
 * @return {PasswordSettingErrors | undefined} バリデーションエラーが無い場合は、undefinedが返る。
 */
export const validatePasswordSetting = (passwordSetting: IPasswordSetting): PasswordSettingErrors | undefined => {
  const errors: PasswordSettingErrors = {
    currentPassword: undefined,
    newPassword: undefined,
  };
  errors.currentPassword = validatePassword(passwordSetting.currentPassword);
  errors.newPassword = validatePassword(passwordSetting.newPassword);
  if (Object.values(errors).findIndex((element) => typeof element === 'string') !== -1) {
    return errors;
  }
  return undefined;
};

/**
 * メールアドレス設定実行時のインターフェース
 */
export interface IEmailSetting {
  currentPassword: string,
  newEmail: string,
}

export type EmailSettingErrors = {
  currentPassword: string | undefined;
  newEmail: string | undefined;
};
/**
 * メールアドレス設定のバリデーションチェック
 * @param emailSetting メールアドレス設定に必要な情報
 * @return {EmailSettingErrors | undefined} バリデーションエラーが無い場合は、undefinedが返る。
 */
export const validateEmailSetting = (emailSetting: IEmailSetting): EmailSettingErrors | undefined => {
  const errors: EmailSettingErrors = {
    currentPassword: undefined,
    newEmail: undefined,
  };
  errors.currentPassword = validatePassword(emailSetting.currentPassword);
  errors.newEmail = validateEmail(emailSetting.newEmail);
  if (Object.values(errors).findIndex((element) => typeof element === 'string') !== -1) {
    return errors;
  }
  return undefined;
};