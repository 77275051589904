import { FC, useCallback } from "react";
import { Toolbar, Typography, AppBar, Box, IconButton } from "@mui/material";
import { useAuthSignOut } from "@react-query-firebase/auth";
import { useMutation } from "react-query";
import LoadingButton from "@mui/lab/LoadingButton";
import { Menu } from '@mui/icons-material';
import { auth } from "../../firebase";
import TimeText from "../TimeText/TimeText";
import useNavDrawerMobileState from "../NavDrawer/useNavDrawerMobileState";

export type AppBarProps = {
  /** ページタイトル */
  title: string;
};

/**
 * クリニックページ全般のアプリバー
 * @constructor
 * @group Components
 * @category components
 */
const ClinicAppBar: FC<AppBarProps> = ({ title }) => {
  // FirebaseAuthログアウト処理Mutation
  const authLogoutMutation = useAuthSignOut(auth);
  
  // ログアウト処理
  const signOutMutation = useMutation(async () => {
    await authLogoutMutation.mutateAsync();
    window.location.reload();
  })
  
  /**
   * ログアウトボタンクリック時
   */
  const onClickLogOut = useCallback(() => {
    void signOutMutation.mutate();
  }, [ signOutMutation ]);
  
  /**
   * モバイル表示時のナビゲーションメニュー表示切り替え
   */
  const { handleToggle } = useNavDrawerMobileState();
  
  return <AppBar
    color='default'
    position="fixed"
    sx={{
      zIndex: (theme) => theme.zIndex.drawer + 1,
      textAlign: {
        'xs': 'center',
        'md': 'start'
      },
    }}
    elevation={1}
  >
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        onClick={handleToggle}
        sx={{
          display: {
            md: 'none',
          }
        }}
      >
        <Menu />
      </IconButton>
      <TimeText />
      <Box m="auto">
        <Typography
          variant='h5'
          fontWeight='bold'
          sx={{
            display: {
              'xs': 'none',
              'md': 'block',
            }
          }}
        >
          {title}
        </Typography>
      </Box>
      <LoadingButton
        loading={signOutMutation.isLoading}
        onClick={onClickLogOut}
        loadingIndicator='読込中...'
      >
        <Typography variant='button' fontSize={16}>ログアウト</Typography>
      </LoadingButton>
    </Toolbar>
  </AppBar>
};

export default ClinicAppBar;