import { useQuery } from "react-query";
import { useRecoilState, useRecoilValue } from "recoil";
import { useAuthUser } from "@react-query-firebase/auth";
import { useCallback, useEffect, useMemo } from "react";
import {
  getOneReservationBaseSettings, getReservationBaseFrames, getReservationDayFrames,
  getReservationDaySettings
} from "../../../repositories/reservationSettingRepository";
import { date2DayOfWeek, dayOfWeekNum2string } from "../../../utils/converter";
import { auth } from "../../../firebase";
import clinicHomePageStateAtom from "../../../recoil/features/HomePage";
import { onSnapshotReservations } from "../../../repositories/reserveRepository";
import listContentStateAtom from "../../../recoil/features/HomePage/ListContent";
import Time from "../../../types/Time";
import IReservationInformation from "../../../interfaces/IReservationInformation";
import { getClinicInformation } from "../../../repositories/clinicRepository";
import { calcCurrentNumberOfPeople, calcReservePoints } from "../../../utils/points";

/**
 * 予約枠アイテム＆予約アイテムのリスト表示の状態管理やロジックをまとめたHooks
 * @group Components
 * @category features/HomePage
 */
const useListContentState = () => {
  const clinicUser = useAuthUser([ 'user' ], auth);
  const homeState = useRecoilValue(clinicHomePageStateAtom);
  const [ state, setState ] = useRecoilState(listContentStateAtom);
  
  /**
   * クリニック情報の取得
   */
  const { data: clinicInfo } = useQuery(
    [ 'getClinicInformation', clinicUser.data?.uid ],
    () => getClinicInformation(clinicUser.data?.uid ?? '')
  );
  
  /**
   * 指定曜日の予約枠設定取得
   */
  const { data: baseSetting } = useQuery(
    [ 'getOneReservationBaseSettings', clinicUser.data?.uid, homeState.currentDate ],
    () => getOneReservationBaseSettings(clinicUser.data?.uid ?? '', dayOfWeekNum2string(date2DayOfWeek(homeState.currentDate)))
  );
  
  /**
   * 指定日の予約枠設定取得
   */
  const { data: daySetting } = useQuery(
    [ 'getReservationDaySettings', clinicUser.data?.uid, homeState.currentDate ],
    () => getReservationDaySettings(clinicUser.data?.uid ?? '', homeState.currentDate)
  );
  
  /**
   * 指定曜日の予約枠一覧取得
   */
  const { data: baseFrames } = useQuery(
    [ 'getReservationBaseFrames', clinicUser.data?.uid, homeState.currentDate ],
    () => getReservationBaseFrames(clinicUser.data?.uid ?? '',
      dayOfWeekNum2string(date2DayOfWeek(homeState.currentDate))),
  );
  
  /**
   * 指定日の予約枠一覧取得
   */
  const { data: dayFrames } = useQuery(
    [ 'getReservationDayFrames', clinicUser.data?.uid, homeState.currentDate ],
    () => getReservationDayFrames(clinicUser.data?.uid ?? '', homeState.currentDate)
  );
  
  /**
   * 表示中の日付の予約一覧のリアルタイムリスナー登録
   */
  useEffect(() => {
    if (!clinicUser.data?.uid) {
      return () => {
        // eslint-disable-next-line no-console
        console.log('unmount')
      };
    }
    const sub = onSnapshotReservations(clinicUser.data?.uid, homeState.currentDate, (snapshot) => {
      if (snapshot.docs.length > 0) {
        const res = snapshot.docs.map((value) => value.data())
        setState((prev) => ({
          ...prev,
          reservationInformation: res,
        }))
      } else {
        setState((prev) => ({
          ...prev,
          reservationInformation: [],
        }))
      }
    })
    
    return () => {
      sub()
    }
  }, [ clinicUser.data?.uid, homeState.currentDate, setState ])
  
  /**
   * 選択中の日付の診療日設定情報
   */
  const reservationSetting = useMemo(() => {
    if (daySetting !== undefined && daySetting !== null && daySetting?.isOpen) {
      return daySetting;
    }
    if (baseSetting !== undefined && baseSetting !== null) {
      return baseSetting;
    }
    return undefined;
  }, [ baseSetting, daySetting ]);
  
  /**
   * 選択中の日付の予約枠設定情報
   */
  const reservationFrames = useMemo(() => {
    if (daySetting?.isOpen) {
      return dayFrames ?? [];
    }
    return baseFrames ?? [];
  }, [ baseFrames, dayFrames, daySetting?.isOpen ]);
  
  /**
   * 予約枠の開始日が一致する予約情報一覧を取得
   */
  const getReservationsByStartTime = useCallback((startTime: Time) => {
    const result: IReservationInformation[] = [];
    state.reservationInformation.forEach(reservation => {
        if (startTime.hour === reservation.startHour && startTime.minute === reservation.startMinute) {
          result.push(reservation);
        }
      }
    );
    return result;
  }, [ state.reservationInformation ]);
  
  /**
   * 予約済み合計消費ポイント数(各予約枠)
   */
  const frameSumPoints = useCallback((startTime: Time): number =>
      state.reservationInformation
        .filter((item) => item.startHour === startTime.hour && item.startMinute === startTime.minute)
        .filter((item) => item.reserveStatus !== 9)
        .reduce((sum, el) => sum + el.point, 0),
    [ state.reservationInformation ]
  );
  
  /**
   * 予約枠設定の合計ポイント数
   */
  const dayFrameSumPeople = useMemo(
    () => {
      let sum = 0;
      for (let i = 0; i < reservationFrames.length; i += 1) {
        const frame = reservationFrames[i];
        sum += frame.numberOfPeople;
      }
      return sum;
    }, [ reservationFrames ]
  );
  
  /**
   * 予約済み合計消費ポイント数(選択中の日付全体)
   */
  const daySumPeople = useMemo(
    () => {
      let sum = 0;
      for (let i = 0; i < reservationFrames.length; i += 1) {
        const frame = reservationFrames[i];
        const framePoints = frameSumPoints(frame.startAt);
        sum += calcCurrentNumberOfPeople(framePoints, calcReservePoints(frame.numberOfPeople, frame.points))
      }
      return sum;
    },
    [ frameSumPoints, reservationFrames ]
  );
  
  return {
    clinicInfo,
    getReservationsByStartTime,
    dayFrameSumPeople,
    daySumPeople,
    frameSumPoints,
    reservationSetting,
    reservationFrames
  };
}

export default useListContentState;