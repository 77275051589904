import { Suspense, useState } from "react";
import {
  Box, Card, CardContent, CircularProgress, Tab, Tabs
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import ClinicPageBase from "../PageBase/ClinicPageBase";
import CalendarContent from "./CalendarContent";
import ListContent from "./ListContent/ListContent";
import useClinicHomePageState from "./useClinicHomePageState";
import { getToday } from "../../utils/datetime";

/**
 * HOMEページ
 * @constructor
 * @group Components
 * @category features/HomePage
 */
const ClinicHomePage = () => {
  const { clinicUser, onChangeCurrentDate } = useClinicHomePageState();
  const [ currentTab, setCurrentTab ] = useState("day");
  
  
  const handleTabChange = (tab: string) => {
    setCurrentTab(tab);
    onChangeCurrentDate(getToday());
  }
  
  return <ClinicPageBase pageTitle='ホーム'>
    <Card>
      <CardContent>
        <TabContext value={currentTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={currentTab}
              onChange={(_, tab) => handleTabChange(tab as string)}
              variant='fullWidth'
              aria-label="reservation settings tab"
            >
              <Tab
                label="日ごとの予約状況"
                style={{ fontWeight: 'bold', fontSize: '20px' }}
                value="day"
              />
              <Tab
                label="月・週ごとの予約状況"
                style={{ fontWeight: 'bold', fontSize: '20px' }}
                value="calendar"
              />
            </Tabs>
          </Box>
          <TabPanel value="day">
            <ListContent />
          </TabPanel>
          <TabPanel sx={{ px: 0 }} value="calendar">
            <CalendarContent clinicId={clinicUser?.data?.uid ?? ''} />
            <Box my={2} />
            <Suspense fallback={<CircularProgress />}>
              <ListContent />
            </Suspense>
          </TabPanel>
        </TabContext>
      </CardContent>
    </Card>
  </ClinicPageBase>
};

export default ClinicHomePage