import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { addDays, format, isSameDay, startOfWeek } from "date-fns";
import { ja } from 'date-fns/locale';
import { FC, ReactNode } from "react";
import useWeeklyCalendarState from "./useWeeklyCalendarState";
import IReservationSetting from "../../interfaces/IReservationSetting";
import { dayOfWeekJaStr2num, time2string } from "../../utils/converter";
import useBreakpoint from "../../utils/useBreakPoint";

export type WeeklyCalendarProps = {
  /** 日曜日スタート: 0, 月曜日スタート: 1 */
  calendarStart: number;
  /** 現在の日付 */
  currentDate: Date;
  /** 曜日設定用 */
  isDayOfWeekOnly?: boolean;
  /** 日付選択時のコールバック */
  onClickDay?: (day: Date) => void;
  /** 営業日かどうか */
  isOpens?: (boolean | undefined)[];
  /** 予約枠設定 */
  reservationSettings?: (IReservationSetting | undefined)[];
  /** コンポーネント */
  children?: ReactNode;
};

/**
 * 週カレンダー表示コンポーネント制御
 * @param calendarStart 日曜日スタート: 0, 月曜日スタート: 1
 * @param currentDate 現在の日付
 * @param isDayOfWeekOnly 曜日設定用
 * @param onClickDay 日付選択時のコールバック
 * @param isOpens 営業日かどうか
 * @param reservationSettings 予約枠設定
 * @param children コンポーネント
 * @constructor
 * @group Components
 * @category components
 */
const WeeklyCalendar: FC<WeeklyCalendarProps> = ({
  calendarStart,
  currentDate,
  isDayOfWeekOnly = false,
  onClickDay = undefined,
  isOpens = undefined,
  reservationSettings = undefined,
  children = undefined
}) => {
  const { state, setNextWeek, setPrevWeek, onClickDate } = useWeeklyCalendarState();
  const { breakpoint } = useBreakpoint();
  
  const renderHeader = () => {
    const dateFormat = 'yyyy年M月d日';
    switch (breakpoint) {
      case "xs":
        return <Stack>
          <Typography variant='subtitle1'>{`${format(state.currentMonth, dateFormat)}の週`}</Typography>
          <Grid container justifyContent='space-around'>
            <Grid item>
              <Button variant='text' onClick={setPrevWeek}>前の週へ</Button>
            </Grid>
            <Grid item>
              <Button variant='text' onClick={setNextWeek}>次の週へ</Button>
            </Grid>
          </Grid>
        </Stack>
      case "sm":
      case "md":
      case "lg":
      case "xl":
      default:
        return <Grid container justifyContent='space-around'>
          <Grid item>
            <Button variant='text' onClick={setPrevWeek}>前の週へ</Button>
          </Grid>
          <Grid item>
            <Typography variant='subtitle1'>{`${format(state.currentMonth, dateFormat)}の週`}</Typography>
          </Grid>
          <Grid item>
            <Button variant='text' onClick={setNextWeek}>次の週へ</Button>
          </Grid>
        </Grid>
    }
  };
  
  const renderCells = () => {
    const dateFormat = "EEE";
    const days = [];
    const startDate = startOfWeek(state.currentMonth, { weekStartsOn: calendarStart as (0 | 1 | 2 | 3 | 4 | 5 | 6) });
    for (let i = 0; i < 7; i += 1) {
      const day = addDays(startDate, i);
      const dayOfWeekStr = format(day, dateFormat, { locale: ja });
      const dayOfWeekNum = dayOfWeekJaStr2num(dayOfWeekStr);
      
      let color: undefined | string;
      if (isOpens) {
        color = isOpens[dayOfWeekNum] ? undefined : '#E0E0E0';
      } else {
        color = undefined;
      }
      let dayOfWeekColor: undefined | string;
      let dayOfWeekBgColor: undefined | string;
      if (dayOfWeekNum === 6) {
        dayOfWeekColor = 'blue';
        dayOfWeekBgColor = '#eaf4ff';
      } else if (dayOfWeekNum === 0) {
        dayOfWeekColor = 'red';
        dayOfWeekBgColor = '#ffeaea';
      } else {
        dayOfWeekColor = undefined;
        dayOfWeekBgColor = undefined;
      }
      days.push(
        <Box width='calc(100% / 7)' minWidth='96px' key={dayOfWeekStr}>
          <Box sx={{ borderTop: 1, borderBottom: 1 }}>
            <Typography color={dayOfWeekColor} sx={{ backgroundColor: dayOfWeekBgColor }}>
              {dayOfWeekStr}
            </Typography>
          </Box>
          <Button
            onClick={() => {
              onClickDate(day)
              if (onClickDay) {
                onClickDay(day)
              }
            }}
            fullWidth
            sx={{
              borderLeft: 'none',
              borderRight: i === 6 ? 'none' : 1,
              borderBottom: 1,
              borderRadius: 0,
              height: '8em',
              opacity: 0.4,
              backgroundColor: isSameDay(day, currentDate) ? '#83BCE2' : color,
              ":hover": {
                backgroundColor: '#80929C',
              },
            }}>
            <Stack direction='column'>
              {!isDayOfWeekOnly ? <Typography
                variant='h6'
                component='div'
                position='absolute'
                top='0.75em'
                right='0.75em'
                fontWeight={700}
              >
                {format(day, 'd')}
              </Typography> : null
              }
              {!isDayOfWeekOnly && isSameDay(day, new Date()) ? <Typography
                variant='subtitle1'
                component='div'
                fontWeight={700}
              >
                今日
              </Typography> : null
              }
              {reservationSettings && reservationSettings[dayOfWeekNum] && reservationSettings[dayOfWeekNum]?.isOpen ?
                <Typography
                  variant='subtitle1'
                  component='div'
                  fontWeight={700}
                >
                  {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                  {time2string(reservationSettings[dayOfWeekNum]!.openAt)} ~ {time2string(reservationSettings[dayOfWeekNum]!.closeAt)}
                </Typography>
                : null
              }
              {!children ? children : null}
            </Stack>
          </Button>
        </Box>
      )
    }
    return <Box style={{ overflow: 'auto' }}>
      <Stack
        direction='row'
        spacing={0}
        my={1}
      >
        {days}
      </Stack>
    </Box>;
  }
  
  return <div>
    {isDayOfWeekOnly ? null : renderHeader()}
    {renderCells()}
  </div>;
};

export default WeeklyCalendar;