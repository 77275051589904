import { Chip, Typography } from "@mui/material";
import { FC } from "react";
import { Variant } from "@mui/material/styles/createTypography";
import { reserveStatus2string } from "../../utils/converter";
import { ReserveStatus } from "../../types/ReserveStatus";

type ReserveStatusChipProps = {
  reserveStatus: ReserveStatus;
  fontSize?: Variant;
}

/**
 * 予約ステータスの表示コンポーネント
 * @param reserveStatus 予約ステータス
 * @param fontSize フォントサイズ
 * @constructor
 * @group Components
 * @category features/HomePage
 */
const ReserveStatusChip: FC<ReserveStatusChipProps> = ({ reserveStatus, fontSize = 'h6' }) => {
  let color;
  switch (reserveStatus) {
    case 1:
      color = "#FFFACD"
      break;
    case 2:
      color = "#D6ECDC"
      break;
    case 3:
      color = "#32CEF0"
      break;
    case 4:
      color = "#FC7D7D"
      break;
    case 9:
      color = "#FC7D7D"
      break;
    default:
      color = "#FFFACD"
      break;
  }
  
  return <Chip label={<Typography variant={fontSize}>
    {reserveStatus2string(reserveStatus)}
  </Typography>} sx={{ px: "4px", backgroundColor: color }} />
}

export default ReserveStatusChip;