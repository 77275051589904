import { useRecoilState } from "recoil";
import { useCallback } from "react";
import notSaveConfirmDialogStateAtom
  from "../../../../recoil/features/ReservationSettingsPage/ChangeDayConfirmDialog/atom";

/**
 * 保存せず移動するか確認用ダイアログの状態管理やロジックをまとめたHooks
 * @group Components
 * @category features/ReservationSettingsPage
 */
const useNotSaveConfirmDialogState = () => {
  const [ notSaveConfirmState, setNotSaveConfirmState ] = useRecoilState(notSaveConfirmDialogStateAtom);
  
  /**
   * 保存せず移動するか確認用ダイアログを開く。
   */
  const openDialog = useCallback((day: Date) => {
    setNotSaveConfirmState((prev) => ({ ...prev, isOpen: true, day }))
  }, [ setNotSaveConfirmState ]);
  
  /**
   * 保存せず移動するか確認用ダイアログを閉じる。
   */
  const closeDialog = useCallback(() => {
    setNotSaveConfirmState((prev) => ({ ...prev, isOpen: false, day: undefined }))
  }, [ setNotSaveConfirmState ]);
  
  return {
    notSaveConfirmState,
    openDialog,
    closeDialog,
  };
};

export default useNotSaveConfirmDialogState;