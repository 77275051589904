import { useRecoilState } from "recoil";
import { useCallback } from "react";
import { useAuthUser } from "@react-query-firebase/auth";
import { auth } from "../../firebase";
import clinicHomePageStateAtom from "../../recoil/features/HomePage";

/**
 * HOMEページの状態管理やロジックをまとめたHooks
 * @group Components
 * @category features/HomePage
 */
const useClinicHomePageState = () => {
  // ログイン中のユーザー情報の監視
  const clinicUser = useAuthUser([ 'user' ], auth);
  const [ state, setState ] = useRecoilState(clinicHomePageStateAtom);
  
  /**
   * 表示日変更
   */
  const onChangeCurrentDate = useCallback((currentDate: Date) => {
    setState((prev) => ({ ...prev, currentDate }))
  }, [ setState ]);
  
  return {
    state,
    setState,
    clinicUser,
    onChangeCurrentDate,
  };
};

export default useClinicHomePageState;