import { atom } from "recoil";

export type ResetPasswordPageState = {
  /** メールアドレス */
  email: string;
  /** バリデーションエラー */
  emailValidateError: string | undefined;
};

const initialState: ResetPasswordPageState = {
  email: '',
  emailValidateError: undefined,
};

const resetPasswordPageStateAtom = atom({
  key: 'ResetPasswordPageState',
  default: initialState,
});

export default resetPasswordPageStateAtom;