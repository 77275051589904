import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import Time from "../types/Time";
import { calcPointsFromTime } from "../utils/points";

/**
 * 予約枠情報を表現するインターフェース
 */
export default interface IReservationFrame {
  /** 予約枠ID */
  id: string;
  /** 予約枠の開始時刻 */
  startAt: Time;
  /** 予約枠の終了時刻 */
  endAt: Time;
  /** 枠に対するポイント */
  points: number;
  /** 予約可能人数 */
  numberOfPeople: number;
}

/**
 * Firestoreからの情報取得用、データコンバータ。
 * Firestoreからのsnapshotと、予約枠の型を変換する。
 */
export const reservationFrameConverter: FirestoreDataConverter<IReservationFrame> = {
  toFirestore: (reservationFrame: IReservationFrame): DocumentData => ({
    startHour: reservationFrame.startAt.hour,
    startMinute: reservationFrame.startAt.minute,
    endHour: reservationFrame.endAt.hour,
    endMinute: reservationFrame.endAt.minute,
    points: reservationFrame.points,
    numberOfPeople: reservationFrame.numberOfPeople,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): IReservationFrame => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      startAt: { hour: data.startHour as number, minute: data.startMinute as number } as Time,
      endAt: { hour: data.endHour as number, minute: data.endMinute as number } as Time,
      points: data.points as number,
      numberOfPeople: data.numberOfPeople as number,
    } as IReservationFrame;
  },
};

/**
 * 予約枠一覧の初期設定を返す。
 * @return {IReservationFrame[]} 予約枠一覧の初期設定が返る。
 */
export const initialReservationBaseFrames = (): IReservationFrame[] => {
  const initialBaseFrames: IReservationFrame[] = [];
  for (let hour = 9; hour < 17; hour += 1) {
    const startAt: Time = {
      hour, minute: 0
    }
    const endAt: Time = {
      hour: hour + 1, minute: 0
    }
    initialBaseFrames.push({ id: '', startAt, endAt, points: calcPointsFromTime(startAt, endAt), numberOfPeople: 5 })
  }
  return initialBaseFrames;
};
