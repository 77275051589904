import { AppBar, Box, Button, Divider, Grid, Stack, Switch, Toolbar, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { ja } from "date-fns/locale";
import { format } from "date-fns";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Add, Info } from "@mui/icons-material";
import useCreateReservationFrameDialogState
  from "../ReservationBaseSettingsContent/CreateReservationFrameDialog/useCreateReservationFrameDialogState";
import useReservationDaySettingsContentState from "./useReservationDaySettingsContentState";
import useResultAlertState from "../../../components/ResultAlert/useResultAlertState";
import WeeklyCalendar from "../../../components/WeeklyCalendar/WeeklyCalendar";
import TimeSelectForm from "../../../components/TimeSelectForm/TimeSelectForm";
import ReservationFrameItem from "../ReservationBaseSettingsContent/ReservationFrameItem";
import CreateReservationFrameDialog
  from "../ReservationBaseSettingsContent/CreateReservationFrameDialog/CreateReservationFrameDialog";
import NotSaveConfirmDialog from "../ReservationBaseSettingsContent/ChangeDayConfirmDialog/NotSaveConfirmDialog";

/**
 * 日付単位の予約枠設定のレイアウト
 * @constructor
 * @group Components
 * @category features/ReservationSettingsPage
 */
const ReservationDaySettingsLayout = () => {
  const {
    state,
    clinicInfo,
    currentSetting,
    currentFrames,
    isSaving,
    onClickCancel,
    onClickDeleteReservationSettings,
    onClickSaveReservationDaySettings,
    onClickDay,
    isSave,
    isSettingDisable,
    onClickNotSave,
    onChangeIsOpen,
    onChangeOpenAt,
    onChangeCloseAt,
    onCloseCreateReservationFrameDialog,
    onDeleteReservationFrame,
    currentWeekReservationDaySettings,
  } = useReservationDaySettingsContentState();
  
  const dateFormat = 'M月d日(E)';
  const { openDialog: openReservationFrameDialog } = useCreateReservationFrameDialogState();
  const { openAlert } = useResultAlertState();
  
  const handleOpenFrameDialog = () => {
    if (currentSetting && currentSetting?.openAt && currentSetting.closeAt) {
      openReservationFrameDialog();
      return;
    }
    openAlert('error', '診療時間を設定してください。')
  };
  
  return <Stack direction='column' spacing={4}>
    <Typography variant='body1'>
      日付を指定して、その日個別の予約枠を設定します。
      <br />
      <Typography variant='caption' fontSize='11px'>
        （特定日設定する日付に対しては、基本設定の設定内容は反映されなくなります。）
      </Typography>
    </Typography>
    <Divider />
    <WeeklyCalendar
      calendarStart={clinicInfo?.calendarStart ?? 0}
      currentDate={state.currentDate}
      onClickDay={onClickDay}
      isOpens={currentWeekReservationDaySettings?.map((val) => val && val.isOpen)}
      reservationSettings={currentWeekReservationDaySettings}
    />
    <Grid container justifyContent='space-around' alignItems='center'>
      <Grid item>
        <div>
          <Typography variant='h5' display='inline'
                      fontWeight='bold'>{format(state.currentDate, dateFormat, { locale: ja })}</Typography>
          <Typography variant='h6' display='inline'>の予約枠設定</Typography>
          <Stack direction='row' spacing={0.5} justifyContent='center' alignItems='center'>
            {isSave ? <CheckCircleIcon fontSize="small" sx={{ color: '#898989' }} /> :
              <Info fontSize="small" color='error' />}
            <Typography mt={1} variant='body1' fontWeight='bold' color={isSave ? '#898989' : 'error'}>
              {`${isSave ? "設定が最新状態です。" : "未保存の設定があります。"}`}
            </Typography>
          </Stack>
        </div>
      </Grid>
      <Grid item>
        <Stack direction='row' alignItems='center' justifyContent='center' mr={2} spacing={2}>
          <Typography variant='subtitle1' fontWeight="bold" color={state.initSetting ? 'primary' : '#898989'}>
            {state.initSetting ? "特定日予約枠として設定中" : "特定日予約枠として未設定"}
          </Typography>
          {state.initSetting && !isSettingDisable ? <LoadingButton
            variant='outlined'
            color='primary'
            loadingIndicator='読込中...'
            onClick={onClickDeleteReservationSettings}
          >
            特定日予約枠を削除
          </LoadingButton> : null}
        </Stack>
      </Grid>
      <Grid item>
        <Stack direction='row' alignItems='center' justifyContent='center'>
          <Typography variant='body1'>休診日</Typography>
          <Switch disabled={isSettingDisable} checked={currentSetting?.isOpen ?? false} onChange={onChangeIsOpen} />
          <Typography variant='body1'>診療日</Typography>
        </Stack>
      </Grid>
      
      {currentSetting?.isOpen ?
        <>
          <Grid item xs={12} my={4}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={4} sx={{
            mb: {
              'xs': '8px',
              'sm': '0',
            },
          }}>
            <Typography variant='h6'>
              診療時間
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Stack direction='row' alignItems='center' justifyContent='center'>
              <TimeSelectForm
                disabled={isSettingDisable}
                label='開始時刻'
                value={currentSetting?.openAt}
                onChange={onChangeOpenAt} />
              <Box mx={2}>〜</Box>
              <TimeSelectForm
                disabled={isSettingDisable}
                label='終了時刻'
                value={currentSetting?.closeAt}
                onChange={onChangeCloseAt} />
            </Stack>
          </Grid>
          <Grid item xs={12} my={4}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color='inherit'
              startIcon={<Add />}
              sx={{ px: '24px', py: '8px' }}
              onClick={handleOpenFrameDialog}
            >
              予約枠を追加
            </Button>
          </Grid>
          <Grid item xs={10} mt={4}>
            <Stack direction='column' spacing={4}>
              {
                currentFrames.map(
                  (item, index) =>
                    <ReservationFrameItem
                      key={item.id}
                      reservationFrame={item}
                      index={index}
                      onClick={() => onDeleteReservationFrame(index)}
                      disable={state.currentReservationList.filter((e) =>
                        (e.startHour === item.startAt.hour && e.startMinute === item.startAt.minute)).length > 0}
                    />
                )
              }
            </Stack>
          </Grid>
        </> : null}
    </Grid>
    
    <AppBar position='fixed' color='secondary' sx={{ top: 'auto', bottom: 0, }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction='row' spacing={4}>
          <Button variant='text' color='inherit' onClick={onClickCancel}>
            キャンセル
          </Button>
          <Box mx='24px'>
            <LoadingButton
              loading={isSaving}
              onClick={onClickSaveReservationDaySettings}
              color='inherit'
              variant='outlined'
              loadingIndicator='保存中...'
              sx={{ px: 4 }}
            >
              保存する
            </LoadingButton>
          </Box>
        </Stack>
      </Toolbar>
    </AppBar>
    <CreateReservationFrameDialog
      openAt={currentSetting?.openAt}
      closeAt={currentSetting?.closeAt}
      currentFrames={currentFrames}
      onClose={onCloseCreateReservationFrameDialog} />
    <NotSaveConfirmDialog onClickNotSave={onClickNotSave} />
  </Stack>
};

export default ReservationDaySettingsLayout;