import { useRecoilState } from "recoil";
import React, { useCallback } from "react";
import notificationDetailSettingsContentStateAtom
  from "../../../recoil/features/NotificationSettingsPage/NotificationDetailSettingsContent";

/**
 * 通知詳細設定の状態管理やロジックをまとめたHooks
 * @group Components
 * @category features/NotificationSettingsPage
 */
const useNotificationDetailSettingsContent = () => {
  const [ state, setState ] = useRecoilState(notificationDetailSettingsContentStateAtom);
  
  /**
   * 選択日の変更をONにする。
   */
  const openSelectDayMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setState((prev) => ({ ...prev, selectDayAnchorEl: event.currentTarget }))
  }, [ setState ]);
  
  /**
   * 選択日の変更をOFFにする。
   */
  const closeSelectDayMenu = useCallback(() => {
    setState((prev) => ({ ...prev, selectDayAnchorEl: null }))
  }, [ setState ]);
  
  return { state, openSelectDayMenu, closeSelectDayMenu };
};

export default useNotificationDetailSettingsContent;