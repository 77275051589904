import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  runTransaction,
  where,
} from "firebase/firestore";
import { addDays } from "date-fns";
import { httpsCallable } from "firebase/functions";
import { firestore, functions } from "../firebase";
import IReservationInformation, { reservationInformationConverter } from "../interfaces/IReservationInformation";
import { ReserveStatus } from "../types/ReserveStatus";
import { date2epoch, date2string, time2string } from "../utils/converter";
import { reserveStatusConverter } from "../interfaces/IReserveStatus";
import { getNotificationSetting } from "./notificationSettingRepository";
import Time from "../types/Time";

/**
 * 指定したClinic IDの指定日付の診察予約一覧を取得する。
 * @param clinicId クリニックのユーザーID
 * @param date 日付
 * @return {Promise<IReservationInformation[]>} 指定日付の診察予約一覧が返る。
 * @category repositories
 */
export const getReservationInformationAtDate = async (clinicId: string, date: Date): Promise<IReservationInformation[]> => {
  if (clinicId === '') {
    return [];
  }
  const ReservesCol = collection(firestore, 'reserves').withConverter(reservationInformationConverter);
  const q = query(ReservesCol, where('clinicId', '==', clinicId), where('reservationDate', '==', date2epoch(date)));
  const snapshot = await getDocs(q);
  const reservationInformationList: IReservationInformation[] = [];
  if (snapshot.docs.length === 0) {
    return reservationInformationList;
  }
  snapshot.docs.forEach(itemDoc => {
    const reservationInformation = itemDoc.data();
    reservationInformationList.push(reservationInformation);
  });
  return reservationInformationList;
};

/**
 * 指定したReservation IDの診察予約情報を取得する。
 * @param reservationId 診察予約ID
 * @return {Promise<IReservationInformation | undefined>} データが見つかれば診察予約情報が返る。
 * @category repositories
 */
export const getReservationInformation = async (reservationId: string): Promise<IReservationInformation | undefined> => {
  if (reservationId === '') {
    return undefined;
  }
  const docRef = doc(firestore, 'reserves', reservationId).withConverter(reservationInformationConverter);
  const snapshot = await getDoc(docRef);
  if (!snapshot.exists()) {
    return undefined;
  }
  return snapshot.data();
};

/**
 * 指定した予約枠に対する診察予約一覧を取得する。
 * @param clinicId クリニックのユーザーID
 * @param date 予約枠の日付
 * @param startAt 予約枠の開始時刻
 * @return {Promise<IReservationInformation[]>} 指定した予約枠に対する診察予約一覧が返る。
 * @category repositories
 */
export const getReservationsByReserveFrame = async (clinicId: string, date: Date, startAt: Time): Promise<IReservationInformation[]> => {
  const reservesCol = collection(firestore, 'reserves').withConverter(reservationInformationConverter);
  const q = query(
    reservesCol,
    where('clinicId', '==', clinicId),
    where('reservationDate', '==', date2epoch(date)),
    where('startHour', '==', startAt.hour),
    where('startMinute', '==', startAt.minute),
    orderBy('number')
  );
  const docs = await getDocs(q);
  if (docs.docs.length > 0) {
    return docs.docs.map((val) => val.data());
  }
  return [];
}

/**
 * 指定した日付に対する診察予約一覧を取得する。
 * @param clinicId クリニックのユーザーID
 * @param date 日付
 * @param onChange コールバック
 * @category repositories
 */
export const onSnapshotReservations = (clinicId: string, date: Date, onChange: (snapshot: QuerySnapshot<IReservationInformation>) => void) => {
  const reservesCol = collection(firestore, 'reserves').withConverter(reservationInformationConverter);
  const q = query(
    reservesCol,
    where('clinicId', '==', clinicId),
    where('reservationDate', '==', date2epoch(date)),
    orderBy('number')
  );
  return onSnapshot(q, (snapshot) => {
    onChange(snapshot)
  });
}

/**
 * 診察予約一覧を開始時刻順にソートする。
 * @param reservationInformationList
 * @return {IReservationInformation[]} ソート後の診察予約一覧が返る。
 * @category repositories
 */
const sortReservationsByFrame = (reservationInformationList: IReservationInformation[]): IReservationInformation[] => {
  const ret = [ ...reservationInformationList ]
  ret.sort((a, b) => {
    if (a.reservationDate < b.reservationDate) {
      return -1;
    }
    if (a.reservationDate === b.reservationDate) {
      if (a.startHour < b.startHour) {
        return -1;
      }
      if (a.startHour === b.startHour) {
        if (a.startMinute < b.startMinute) {
          return -1;
        }
        if (a.startMinute === b.startMinute) {
          return 0;
        }
        return 1;
      }
      return 1;
    }
    return 1;
  })
  return ret;
}

/**
 * 指定曜日の診察予約一覧を取得する。
 * @param clinicId クリニックのユーザーID
 * @param dayOfWeek 曜日
 * @return {Promise<IReservationInformation[]>} 指定曜日の診察予約一覧が返る。
 * @category repositories
 */
export const getReservationInformationByDayOfWeek = async (clinicId: string, dayOfWeek: number): Promise<IReservationInformation[]> => {
  const ReservesCol = collection(firestore, 'reserves').withConverter(reservationInformationConverter);
  const q = query(
    ReservesCol,
    where("clinicId", "==", clinicId),
    where('dayOfWeek', '==', dayOfWeek),
    where("reservationDate", ">", date2epoch(addDays(new Date(), -1)))
  );
  const snapshot = await getDocs(q);
  const reservationInformationList: IReservationInformation[] = [];
  if (snapshot.docs.length === 0) {
    return reservationInformationList;
  }
  snapshot.docs.forEach(itemDoc => {
    const reservationInformation = itemDoc.data();
    reservationInformationList.push(reservationInformation);
  });
  return sortReservationsByFrame(reservationInformationList);
};

/**
 * 電話番号を利用して診察予約一覧を取得する。
 * @param clinicId クリニックのユーザーID
 * @param phone 電話番号
 * @return {Promise<IReservationInformation[]>} 診察予約一覧が返る。
 * @category repositories
 */
export const getReservationInformationByPhone = async (clinicId: string, phone: string): Promise<IReservationInformation[]> => {
  const ReservesCol = collection(firestore, 'reserves').withConverter(reservationInformationConverter);
  const q = query(
    ReservesCol,
    where("clinicId", "==", clinicId),
    where('examineePhone', '==', phone),
    where("reservationDate", ">", date2epoch(addDays(new Date(), -1)))
  );
  const snapshot = await getDocs(q);
  const reservationInformationList: IReservationInformation[] = [];
  if (snapshot.docs.length === 0) {
    return reservationInformationList;
  }
  snapshot.docs.forEach(itemDoc => {
    const reservationInformation = itemDoc.data();
    reservationInformationList.push(reservationInformation);
  });
  return sortReservationsByFrame(reservationInformationList);
};

/**
 * 診察券番号を利用して診察予約一覧を取得する。
 * @param clinicId クリニックのユーザーID
 * @param patientId 診察券番号
 * @return {Promise<IReservationInformation[]>} 診察予約一覧が返る。
 * @category repositories
 */
export const getReservationInformationByPatientId = async (clinicId: string, patientId: string): Promise<IReservationInformation[]> => {
  const ReservesCol = collection(firestore, 'reserves').withConverter(reservationInformationConverter);
  const q = query(
    ReservesCol,
    where("clinicId", "==", clinicId),
    where('patientIdentification', '==', patientId),
    where("reservationDate", ">", date2epoch(addDays(new Date(), -1)))
  );
  const snapshot = await getDocs(q);
  const reservationInformationList: IReservationInformation[] = [];
  if (snapshot.docs.length === 0) {
    return reservationInformationList;
  }
  snapshot.docs.forEach(itemDoc => {
    const reservationInformation = itemDoc.data();
    reservationInformationList.push(reservationInformation);
  });
  return sortReservationsByFrame(reservationInformationList);
};

/**
 * 指定したReservation IDの診察予約を削除する。
 * @param reservationId 診察予約ID
 * @category repositories
 */
export const deleteReserve = async (reservationId: string) => {
  if (reservationId === '') {
    return;
  }
  const reserveDocRef = doc(firestore, 'reserves', reservationId).withConverter(reservationInformationConverter);
  await runTransaction(firestore, async (transaction) => {
    const reserveSnapshot = await transaction.get(reserveDocRef)
    if (!reserveSnapshot.exists()) {
      throw new Error("予約データが存在しません。")
    }
    const reserveData = reserveSnapshot.data();
    const { clinicId, startHour, startMinute } = reserveData;
    const docId = `${date2string(new Date(reserveData.reservationDate))} ${time2string({
      hour: startHour,
      minute: startMinute
    })}`
    const clinicStatusDocRef = doc(firestore, "clinics", clinicId, "status", docId).withConverter(reserveStatusConverter)
    const clinicStatusSnapshot = await transaction.get(clinicStatusDocRef);
    if (clinicStatusSnapshot.exists()) {
      const beforeStatus = clinicStatusSnapshot.data();
      if (beforeStatus.remainDataNum > 1) {
        transaction.update(clinicStatusDocRef, {
          remainDataNum: beforeStatus.remainDataNum - 1,
        })
      } else {
        transaction.delete(clinicStatusDocRef);
      }
    } else {
      // eslint-disable-next-line no-console
      console.error('予約ステータスデータが存在しません。')
    }
    transaction.delete(reserveDocRef);
  })
}

/**
 * X番前になった時の通知を送信する。
 * @param reserveInfo 予約情報
 * @category repositories
 */
const sendNotifyBeforeXNum = async (reserveInfo: IReservationInformation) => {
  const { clinicId } = reserveInfo;
  const notificationSetting = await getNotificationSetting(clinicId);
  if (!notificationSetting) {
    throw new Error("通知設定情報の取得に失敗しました。")
  }
  if (!notificationSetting.beforeXEnable) {
    return;
  }
  const xNum = notificationSetting.beforeXNum;
  const reserves = await getReservationsByReserveFrame(
    clinicId,
    new Date(reserveInfo.reservationDate),
    {
      hour: reserveInfo.startHour,
      minute: reserveInfo.startMinute
    }
  );
  const callNum = xNum + reserveInfo.number;
  const callReserveIndex = reserves.findIndex(value => value.number === callNum);
  if (callReserveIndex > -1) {
    const callReserve = reserves[callReserveIndex];
    // 呼び出すべき番号の予約が存在する場合
    const func = httpsCallable<{ clinicId: string, reservationId: string, email: string, lineUserId: string | undefined | null }, never>(
      functions,
      'sendNotificationBeforeX',
    );
    await func({
      clinicId: callReserve.clinicId,
      reservationId: callReserve.id,
      email: callReserve.examineeEmail,
      lineUserId: callReserve.lineUserId,
    });
  }
};

/**
 * 予約キャンセル時の通知を送信する。
 * @param reserveInfo 予約情報
 * @category repositories
 */
const sendNotifyCanceled = async (reserveInfo: IReservationInformation) => {
  const notificationSetting = await getNotificationSetting(reserveInfo.clinicId);
  if (!notificationSetting) {
    throw new Error("通知設定情報の取得に失敗しました。")
  }
  if (!notificationSetting.canceledEnable) {
    return;
  }
  const func = httpsCallable<{ clinicId: string, reservationId: string, email: string, lineUserId: string | undefined | null }, never>(
    functions,
    'sendNotificationCanceled',
  );
  await func({
    clinicId: reserveInfo.clinicId,
    reservationId: reserveInfo.id,
    email: reserveInfo.examineeEmail,
    lineUserId: reserveInfo.lineUserId,
  });
}

/**
 * 指定したReservation IDの診察ステータスを更新する。
 * @param reservationId 診察予約ID
 * @param reserveStatus 診察ステータス
 * @param before 前回のステータス
 * @category repositories
 */
export const updateReserveStatus = async (reservationId: string, reserveStatus: ReserveStatus, before?: number) => {
  if (reservationId === '') {
    return;
  }
  const reserveDocRef = doc(firestore, 'reserves', reservationId).withConverter(reservationInformationConverter);
  await runTransaction(firestore, async (transaction) => {
    const reserveSnapshot = await transaction.get(reserveDocRef)
    if (!reserveSnapshot.exists()) {
      throw new Error("予約データが存在しません。")
    }
    const reserveData = reserveSnapshot.data();
    const { clinicId, startHour, startMinute } = reserveData;
    const docId = `${date2string(new Date(reserveData.reservationDate))} ${time2string({
      hour: startHour,
      minute: startMinute
    })}`
    const clinicStatusDocRef = doc(firestore, "clinics", clinicId, "status", docId).withConverter(reserveStatusConverter)
    const clinicStatusSnapshot = await transaction.get(clinicStatusDocRef);
    if (!clinicStatusSnapshot.exists()) {
      throw new Error("予約ステータスデータが存在しません。")
    }
    const beforeStatus = clinicStatusSnapshot.data();
    switch (reserveStatus) {
      case 1:
        // 待機中へ移行
        // 予約キャンセルの取り消し時のみこのパターンに入る
        if (beforeStatus.currentPoints >= beforeStatus.totalPoints) {
          // キャンセル復活のためのポイントが足りない
          throw new Error("ポイントが足りない為、待機中へ移行できません。")
        }
        transaction.update(clinicStatusDocRef, {
          currentPoints: beforeStatus.currentPoints + beforeStatus.pointPerPeople,
        })
        break;
      case 2:
        // 待機中から不在へ移行
        transaction.update(clinicStatusDocRef, {
          absenteeNum: beforeStatus.absenteeNum + 1,
          calledPoints: beforeStatus.calledPoints + beforeStatus.pointPerPeople,
        })
        break;
      case 3:
        // 診察中へ移行（呼び出し実行）
        if (before && before === 2) {
          // 不在者の場合、呼び出し済みPointは加算済み
          transaction.update(clinicStatusDocRef, {
            currentNum: reserveData.number,
            absenteeNum: beforeStatus.absenteeNum - 1,
          })
        } else if (before && before === 4) {
          // 診察済みから移行してきた場合
          transaction.update(clinicStatusDocRef, {
            currentNum: reserveData.number,
          })
        } else {
          // 通常、待機中からの移行となる
          transaction.update(clinicStatusDocRef, {
            currentNum: reserveData.number,
            calledPoints: beforeStatus.calledPoints + beforeStatus.pointPerPeople,
          })
          void sendNotifyBeforeXNum(reserveData);
        }
        break;
      case 4:
        // 診察済へ移行
        // 特に何もしない
        break;
      case 9:
        // キャンセル
        if (before && before === 1) {
          // 待機中から移行してきた場合
          transaction.update(clinicStatusDocRef, {
            currentPoints: beforeStatus.currentPoints - beforeStatus.pointPerPeople
          })
        } else if (before && before === 2) {
          // 不在中から移行してきた場合
          transaction.update(clinicStatusDocRef, {
            currentPoints: beforeStatus.currentPoints - beforeStatus.pointPerPeople,
            absenteeNum: beforeStatus.absenteeNum - 1,
          })
        }
        void sendNotifyCanceled(reserveData);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('不正なステータスへの移行が行われました。')
    }
    transaction.update(reserveDocRef, { reserveStatus })
  })
}
