import { Container, Toolbar } from "@mui/material";
import { FC, ReactNode } from "react";
import { PatientAppBar } from "../../components/AppBar";

export type PatientPageBaseProps = {
  /** 子供コンポーネント */
  children: ReactNode;
};

/**
 * 患者側ページのベースコンポーネント
 * @param children
 * @constructor
 * @group Components
 * @category features/PageBase
 */
const PatientPageBase: FC<PatientPageBaseProps> = ({ children }) =>
  <>
    <PatientAppBar />
    <Container sx={{ py: 2, px: 0 }}>
      <Toolbar />
      {children}
    </Container>
  </>;

export default PatientPageBase;