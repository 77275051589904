import LoadingButton from "@mui/lab/LoadingButton";
import {
  AppBar,
  Box,
  Button, CircularProgress,
  Grid,
  Stack,
  Toolbar,
} from "@mui/material";
import { Suspense } from "react";
import ClinicPageBase from "../PageBase/ClinicPageBase";
import NotificationBasicSettingsContent from "./NotificationBasicSettingsContent";
import NotificationDetailSettingsContent from "./NotificationDetailSettingsContent/NotificationDetailSettingsContent";
import useNotificationSettingsPageState from "./useNotificationSettingsPageState";

/**
 * 通知設定ページ
 * @constructor
 * @group Components
 * @category features/NotificationSettingsPage
 */
const NotificationSettingsPage = () => {
  const { isSaving, onClickSaveSettings, onClickCancel } = useNotificationSettingsPageState();
  return <ClinicPageBase pageTitle='通知設定'>
    <Suspense
      fallback={<CircularProgress />}
    >
      <Grid container justifyContent='center' alignItems='start' rowSpacing={3}>
        <NotificationBasicSettingsContent />
        <NotificationDetailSettingsContent />
      </Grid>
    </Suspense>
    <Toolbar />
    <AppBar position='fixed' color='secondary' sx={{ top: 'auto', bottom: 0, }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction='row' spacing={4}>
          <Button variant='text' color='inherit' onClick={onClickCancel}>
            キャンセル
          </Button>
          <Box mx='24px'>
            <LoadingButton
              loading={isSaving}
              onClick={onClickSaveSettings}
              color='inherit'
              variant='outlined'
              loadingIndicator='保存中...'
              sx={{ px: 4 }}
            >
              保存する
            </LoadingButton>
          </Box>
        </Stack>
      </Toolbar>
    </AppBar>
  </ClinicPageBase>;
}

export default NotificationSettingsPage;