import './App.css';
import { CssBaseline } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  homePagePath,
  loginPagePath,
  resetPasswordPath,
  basicSettingsPath,
  notificationSettingsPath,
  reservationSettingsPath,
  registerPagePath,
  passwordSettingPath,
  emailSettingPath, errorPagePath
} from "./layout/urls";
import LoginPage from "./features/auth/LoginPage/LoginPage";
import ResetPasswordPage from "./features/auth/ResetPasswordPage/ResetPasswordPage";
import ClinicHomePage from "./features/HomePage/ClinicHomePage";
import ClinicBasicSettingsPage from "./features/BasicSettingsPage";
import RegisterPage from "./features/auth/RegisterPage/RegisterPage";
import AuthGuard from "./features/auth/AuthGuard/AuthGuard";
import ErrorPage from "./features/ErrorPage/ErrorPage";
import PasswordSettingPage from "./features/PasswordSettingPage/PasswordSettingPage";
import ReservationSettingsPage from "./features/ReservationSettingsPage/ReservationSettingsPage";
import NotificationSettingsPage from "./features/NotificationSettingsPage/NotificationSettingsPage";
import EmailSettingPage from "./features/EmailSettingPage/EmailSettingPage";

/**
 *
 * @group Components
 */
const App = () => (
  <div className="App">
    <CssBaseline>
      <BrowserRouter>
        <Routes>
          <Route path={loginPagePath} element={
            <AuthGuard
              isNotLoggedInOnly>
              <LoginPage />
            </AuthGuard>
          }
          />
          <Route path={resetPasswordPath} element={
            <ResetPasswordPage />
          } />
          <Route path={homePagePath} element={
            <AuthGuard>
              <ClinicHomePage />
            </AuthGuard>
          } />
          <Route path={registerPagePath} element={<AuthGuard><RegisterPage /></AuthGuard>} />
          <Route path={basicSettingsPath} element={<AuthGuard><ClinicBasicSettingsPage /></AuthGuard>} />
          <Route path={notificationSettingsPath} element={<AuthGuard><NotificationSettingsPage /></AuthGuard>} />
          <Route path={reservationSettingsPath} element={<AuthGuard><ReservationSettingsPage /></AuthGuard>} />
          <Route path={passwordSettingPath} element={<AuthGuard><PasswordSettingPage /></AuthGuard>} />
          <Route path={emailSettingPath} element={<AuthGuard><EmailSettingPage /></AuthGuard>} />
          <Route path={errorPagePath} element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </CssBaseline>
  </div>
);

export default App;
