import { FC } from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAuthUser } from "@react-query-firebase/auth";
import LoadingButton from "@mui/lab/LoadingButton";
import { useConfirm } from "material-ui-confirm";
import IReservationFrame from "../../interfaces/IReservationFrame";
import { time2string } from "../../utils/converter";
import {
  addStopAccepting,
  isStopAccepting,
  removeStopAccepting
} from "../../repositories/reservationSettingRepository";
import { auth } from "../../firebase";
import { calcCurrentNumberOfPeople, calcReservePoints, numOfPeopleStr } from "../../utils/points";

type ReservationFrameItemProps = {
  reservationFrame: IReservationFrame,
  sumPoints: number,
  date: Date,
  isReserveFramesShow: boolean,
}

/**
 * 予約枠アイテムのコンポーネント
 * @param reservationFrame 予約枠情報
 * @param sumPoints 予約されているポイント合計
 * @param date 予約枠の日付
 * @param isReserveFramesShow 予約枠の表示
 * @constructor
 * @group Components
 * @category features/HomePage
 */
const ReservationFrameItem: FC<ReservationFrameItemProps> = ({
  reservationFrame,
  sumPoints,
  date,
  isReserveFramesShow
}) => {
  const confirm = useConfirm();
  const clinicUser = useAuthUser([ 'user' ], auth);
  const queryClient = useQueryClient();
  
  /**
   * 新規予約受付状態を取得する処理
   */
  const { data: isStop, isLoading } = useQuery(
    [ 'getStopAccepting', clinicUser.data?.uid ?? '', date, reservationFrame.startAt ],
    () => isStopAccepting(clinicUser.data?.uid ?? '', date, reservationFrame.startAt),
    {
      suspense: false,
    }
  )
  
  /**
   * 新規予約受付停止中に変更するクエリ処理
   */
  const stopAcceptingMutate = useMutation(
    () => addStopAccepting(clinicUser.data?.uid ?? '', date, reservationFrame.startAt),
    {
      onSuccess: () => {
        void queryClient.resetQueries([ 'getStopAccepting', clinicUser.data?.uid ?? '', date, reservationFrame.startAt ])
      }
    }
  )
  
  /**
   * 新規予約受付中に変更するクエリ処理
   */
  const removeStopAcceptingMutate = useMutation(
    () => removeStopAccepting(clinicUser.data?.uid ?? '', date, reservationFrame.startAt),
    {
      onSuccess: () => {
        void queryClient.resetQueries([ 'getStopAccepting', clinicUser.data?.uid ?? '', date, reservationFrame.startAt ])
      }
    }
  )
  
  /**
   * 新規予約受付ステータスの変更の処理
   */
  const handleClick = () => {
    if (!clinicUser.data?.uid) {
      return
    }
    if (isStop) {
      confirm({
        title: "実行確認",
        description: "この予約枠の新規予約受付を再開します。よろしいですか？"
      })
        .then(() => {
          void removeStopAcceptingMutate.mutate();
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.debug("operation canceled")
        })
    } else {
      confirm({
        title: "実行確認",
        description: "この予約枠の新規予約受付を停止します。よろしいですか？"
      })
        .then(() => {
          void stopAcceptingMutate.mutate();
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.debug("operation canceled")
        })
    }
  }
  
  return <Stack direction='column'>
    <Divider>
      <Typography variant='h6' my={2} fontWeight='bold'>
        {`${time2string(reservationFrame.startAt)} ~ ${time2string(reservationFrame.endAt)}
      (${numOfPeopleStr(calcCurrentNumberOfPeople(sumPoints, calcReservePoints(reservationFrame.numberOfPeople, reservationFrame.points)))} / ${numOfPeopleStr(reservationFrame.numberOfPeople)})`}
      </Typography>
    </Divider>
    <Stack direction='row' alignItems='center' justifyContent='center' spacing={4}>
      {
        isStop || !isReserveFramesShow ?
          <Typography variant='body1' fontWeight='bold' color='error'>新規予約受付停止中</Typography>
          : <Typography variant='body1' fontWeight='w600'>新規予約受付中</Typography>
      }
      {
        isReserveFramesShow ? <LoadingButton
          variant='outlined'
          color='primary'
          loading={isLoading || removeStopAcceptingMutate.isLoading || stopAcceptingMutate.isLoading}
          loadingIndicator='読込中...'
          onClick={handleClick}
          sx={{ fontSize: 14 }}
        >
          {isStop ? "新規予約の受付を開始" : "新規予約の受付を停止"}
        </LoadingButton> : null
      }
    </Stack>
  </Stack>;
}

export default ReservationFrameItem;