import { atom } from "recoil";

export type NavDrawerMobileState = {
  /** ナビゲーションドロワーを開いたかどうか */
  isOpen: boolean;
};

const initialState: NavDrawerMobileState = {
  isOpen: false,
};

const navDrawerMobileStateAtom = atom({
  key: 'NavDrawerMobileState',
  default: initialState,
});

export default navDrawerMobileStateAtom;