import { string2time } from "../../utils/converter";
import Time from "../../types/Time";

/**
 * 時間選択フォームコンポーネント制御
 * @group Components
 * @category components
 */
const useTimeSelectFormState = () => {
  /**
   * 値を変化された時の状態制御
   * @param timeStr 選択された時間テキスト
   * @param onChange コールバック
   */
  const onChangeTimeValue = (timeStr: string, onChange?: (time: Time) => void) => {
    const time = string2time(timeStr);
    if (onChange) {
      onChange(time);
    }
  };
  return { onChangeTimeValue };
};

export default useTimeSelectFormState;