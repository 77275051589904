import axios from "axios";

/**
 * 郵便番号から住所を取得する。（zipcode呼び出し）
 * @param postalCode 郵便番号
 * @return {Promise<string>} 住所が返る。
 * @category repositories
 */
const getAddressByPostalCodeApi = async (postalCode: string): Promise<string> => {
  const res = await axios.get(
    "https://zipcloud.ibsnet.co.jp/api/search",
    {
      params: {
        zipcode: postalCode
      }
    }
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (res && res.data && res.data.results) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    const result = res.data.results[0];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/restrict-template-expressions
    return `${result.address1}${result.address2}${result.address3}`
  }
  return ''
};

export default getAddressByPostalCodeApi;