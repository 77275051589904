import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button, CircularProgress,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { FC, useCallback, useMemo, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { format, isSameDay } from "date-fns";
import { useConfirm } from "material-ui-confirm";
import IReservationInformation from "../../../interfaces/IReservationInformation";
import { genderIndex2string } from "../../../utils/converter";
import ReserveStatusChip from "../ReserveStatusChip";
import { getAgeFromBirthday } from "../../../utils/datetime";
import useReservationDetailDialogState from "../ReservationDetailDialog/useReservationDetailDialogState";
import IReservationFrame from "../../../interfaces/IReservationFrame";
import { ReserveStatus } from "../../../types/ReserveStatus";
import useReservationListItemState from "./useReservationListItemState";

type ReservationListItemProps = {
  /** 予約情報 */
  reservationInfo: IReservationInformation,
  /** 予約枠情報 */
  reservationFrame: IReservationFrame,
  /** 表示中の日付 */
  currentDate: Date,
}

/**
 * 予約リストのアイテムコンポーネント（モバイル表示）
 * @constructor
 * @group Components
 * @category features/HomePage
 */
const ReservationListMobileSizeItem: FC<ReservationListItemProps> = ({
  reservationInfo, reservationFrame, currentDate
}) => {
  const { onClickUpdateReserveStatus, onClickDeleteReserve, updateReserveStatusMutate } = useReservationListItemState();
  const { openDialog } = useReservationDetailDialogState();
  const confirm = useConfirm();
  const [ expanded, setExpanded ] = useState(false);
  
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  
  /**
   * 診察ステータス変更ボタン押下時
   */
  const handleNextStatus = useCallback((nextStatus: ReserveStatus, before?: number) => {
    const now = new Date();
    if (!isSameDay(now, currentDate)) {
      confirm({
        title: "本日以外の診察予約のステータス変更",
        description: `本日(${format(now, "yyyy/MM/dd")})以外の診察予約のステータスを変更しようとしています。本当に実行してもよろしいですか？`
      }).then(() => {
        onClickUpdateReserveStatus(reservationInfo, nextStatus, before);
      }).catch(() => {
        // eslint-disable-next-line no-console
        console.debug("operation canceled")
      })
      return;
    }
    onClickUpdateReserveStatus(reservationInfo, nextStatus, before);
  }, [ confirm, currentDate, onClickUpdateReserveStatus, reservationInfo ])
  
  /**
   * 予約キャンセル済みアイテムのアコーディオン内ウィジェット
   */
  const canceledItemAccordionDetail =
    <Stack direction='row' justifyContent='space-around'>
      <Button variant="text"
              sx={{ color: '#b30000' }}
              onClick={() => onClickDeleteReserve(reservationInfo)}
      >
        削除
      </Button>
      <Button variant="outlined" sx={{ fontSize: "16px" }}
              onClick={() => openDialog(reservationInfo, reservationFrame, currentDate)}>
        詳細
      </Button>
      <LoadingButton
        variant="outlined"
        loading={updateReserveStatusMutate.isLoading}
        loadingIndicator={<CircularProgress />}
        sx={{ color: '#32CEF0', fontSize: "16px" }}
        onClick={() => handleNextStatus(1)}
      >
        予約キャンセル<br />の取り消し
      </LoadingButton>
    </Stack>;
  
  /**
   * 診察ステータス変更ボタンウィジェット
   */
  const changeStatusButton = useMemo(() => {
    switch (reservationInfo.reserveStatus) {
      case 1:
        // 待機中
        return <LoadingButton
          variant="outlined"
          loading={updateReserveStatusMutate.isLoading}
          loadingIndicator={<CircularProgress />}
          sx={{ color: '#32CEF0', fontSize: "12px" }}
          onClick={() => handleNextStatus(3)}
        >
          診察中
        </LoadingButton>
      case 2:
        // 不在
        return <LoadingButton
          variant="outlined"
          loading={updateReserveStatusMutate.isLoading}
          loadingIndicator={<CircularProgress />}
          sx={{ color: '#32CEF0', fontSize: "12px" }}
          onClick={() => handleNextStatus(3, 2)}
        >
          診察中
        </LoadingButton>
      case 3:
        // 診察中
        return <LoadingButton
          variant="outlined"
          loading={updateReserveStatusMutate.isLoading}
          loadingIndicator={<CircularProgress />}
          sx={{ color: 'error', fontSize: "12px" }}
          onClick={() => handleNextStatus(4)}
        >
          診察終了
        </LoadingButton>
      case 4:
        // 診察済
        return <LoadingButton
          variant="outlined"
          loading={updateReserveStatusMutate.isLoading}
          loadingIndicator={<CircularProgress />}
          sx={{ color: 'error', fontSize: "12px" }}
          onClick={() => handleNextStatus(3, 4)}
        >
          診察中
        </LoadingButton>
      default:
        return <LoadingButton
          variant="outlined"
          loading={updateReserveStatusMutate.isLoading}
          loadingIndicator={<CircularProgress />}
          sx={{ color: '#32CEF0', fontSize: "12px" }}
          onClick={() => handleNextStatus(3)}
        >
          診察中
        </LoadingButton>
    }
  }, [ handleNextStatus, reservationInfo.reserveStatus, updateReserveStatusMutate.isLoading ])
  
  return (
    <Accordion expanded={expanded} onChange={handleExpandClick}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Grid container alignItems='center' justifyContent='start'>
          <Grid item xs={12} mb={1}>
            <Typography variant='h6' color="inherit" textAlign="start">
              {String(reservationInfo.number).padStart(3, '0')}
            </Typography>
          </Grid>
          <Grid item xs={12} mb={1}>
            <Stack alignItems='start' justifyContent='center'>
              <Typography variant='subtitle2' lineHeight="24px" color="inherit"
                          style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>
                {`${reservationInfo.examineeFirstKana} ${reservationInfo.examineeLastKana}`}
              </Typography>
              <Typography variant='h6' color="inherit" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>
                {`${reservationInfo.examineeFirstName} ${reservationInfo.examineeLastName}`}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1' lineHeight="24px" color="inherit" textAlign="start">
              {reservationInfo.examineePhone}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction='row' justifyContent='start' spacing={0}>
              <Grid item mr={2}>
                <Typography variant='subtitle1' lineHeight="24px" color="inherit">
                  {genderIndex2string(reservationInfo.examineeGender)}
                </Typography>
              </Grid>
              <Grid item mr={2}>
                <Typography variant='subtitle1' lineHeight="24px" color="inherit">
                  {`${getAgeFromBirthday(new Date(reservationInfo.examineeBirthday))}才`}
                </Typography>
              </Grid>
              <Grid item>
                <ReserveStatusChip reserveStatus={reservationInfo.reserveStatus} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: '#f9fbf9',
          borderRadius: "24px 24px 0 0"
        }}>
        {reservationInfo.reserveStatus === 9 ?
          canceledItemAccordionDetail :
          <Stack direction='row' justifyContent='space-around'>
            {reservationInfo.reserveStatus === 1 || reservationInfo.reserveStatus === 2 ?
              <LoadingButton
                loading={updateReserveStatusMutate.isLoading}
                loadingIndicator={<CircularProgress />}
                sx={{ color: '#b30000', fontSize: '12px' }}
                onClick={() => handleNextStatus(9)}
              >取消</LoadingButton> : null}
            <Button
              variant="outlined"
              sx={{ fontSize: "12px" }}
              onClick={() => openDialog(reservationInfo, reservationFrame, currentDate)}
            >
              詳細
            </Button>
            {
              reservationInfo.reserveStatus === 1 ?
                <LoadingButton
                  variant="outlined"
                  color='error'
                  loading={updateReserveStatusMutate.isLoading}
                  loadingIndicator={<CircularProgress />}
                  sx={{ fontSize: "12px" }}
                  onClick={() => handleNextStatus(2)}
                >
                  不在
                </LoadingButton> : null
            }
            {changeStatusButton}
          </Stack>
        }
      </AccordionDetails>
    </Accordion>
  );
}

export default ReservationListMobileSizeItem;