import { useRecoilState, useResetRecoilState } from "recoil";
import { addDays, addWeeks, format, getWeek, startOfWeek, subWeeks } from "date-fns";
import { useEffect, useMemo } from "react";
import { ja } from "date-fns/locale";
import weeklyCalendarStateAtom from "../../recoil/components/WeeklyCalendar/atom";

/**
 * 週カレンダー表示コンポーネント制御
 * （クリニック側Home画面用）
 * @group Components
 * @category components
 */
const useWeeklyCalendarState = () => {
  const [ state, setState ] = useRecoilState(weeklyCalendarStateAtom);
  const resetState = useResetRecoilState(weeklyCalendarStateAtom);
  
  useEffect(() => () => resetState(), [ resetState ]);
  
  /**
   * 前の週へ状態を変更
   */
  const setPrevWeek = () => {
    setState((prev) => ({ ...prev, currentMonth: subWeeks(prev.currentMonth, 1) }))
    setState((prev) => ({ ...prev, currentWeek: getWeek(subWeeks(prev.currentWeek, 1)) }))
  };
  
  /**
   * 次の週へ状態を変更
   */
  const setNextWeek = () => {
    setState((prev) => ({ ...prev, currentMonth: addWeeks(prev.currentMonth, 1) }))
    setState((prev) => ({ ...prev, currentWeek: getWeek(addWeeks(prev.currentWeek, 1)) }))
  }
  
  /**
   * 日付を選択された時の状態変化
   * @param day
   */
  const onClickDate = (day: Date) => {
    setState((prev) => ({ ...prev, selectedDate: day }))
  };
  
  const days = useMemo(() => {
    const dateFormat = 'EEE';
    const ret = [];
    const startDate = startOfWeek(state.currentMonth, { weekStartsOn: 0 });
    for (let i = 0; i < 7; i += 1) {
      ret.push(format(addDays(startDate, i), dateFormat, {
        locale: ja,
      }));
    }
    return ret;
  }, [ state.currentMonth ]);
  
  return { state, days, setNextWeek, setPrevWeek, onClickDate, }
};

export default useWeeklyCalendarState;