import { Box, Button, Container, Stack, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router";
import LoadingButton from "@mui/lab/LoadingButton";
import AuthPageBase from "../AuthPageBase";
import InputForm from "../../../components/InputForm/InputForm";
import useResetPasswordPageState from "./useResetPasswordPageState";
import PasswordResetArtSvg from "../../../assets/password_reset_art.svg";

/**
 * パスワードリセットページ
 * @constructor
 * @group Components
 * @category features/auth
 */
const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const { state, isLoading, onChangeEmail, onClickSendResetPasswordEmail } = useResetPasswordPageState();
  return <AuthPageBase row={5} svg={PasswordResetArtSvg}>
    <Container maxWidth='xs'>
      <Stack direction='column' style={{ height: '100vh' }} justifyContent='center' spacing={4}>
        <div style={{ textAlign: 'start' }}>
          <Button variant='outlined' onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </Button>
        </div>
        <Typography variant='h4'>
          パスワードリセット
        </Typography>
        <Typography variant='body1'>
          登録時に使用したメールアドレスを入力してください。<br />
          そのアドレス宛にパスワードリセットを行う為のメールを送付します。
        </Typography>
        <Box />
        <InputForm label='メールアドレス' onChange={onChangeEmail} error={state.emailValidateError} />
        <LoadingButton
          fullWidth
          variant="contained"
          sx={{
            borderRadius: '24px',
            color: '#FFFFFF',
            elevation: 3,
            py: '8px',
            fontSize: 16,
          }}
          loadingIndicator="読込中..."
          loading={isLoading}
          onClick={onClickSendResetPasswordEmail}
        >
          送信する
        </LoadingButton>
      </Stack>
    </Container>
  </AuthPageBase>;
};

export default ResetPasswordPage;