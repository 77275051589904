import { atom } from "recoil";
import IReservationSetting from "../../../../interfaces/IReservationSetting";
import IReservationFrame from "../../../../interfaces/IReservationFrame";
import IReservationInformation from "../../../../interfaces/IReservationInformation";

export type DaySettingsContentState = {
  /** 表示中の日付 */
  currentDate: Date;
  /** 表示中の日別の予約枠設定 */
  currentSetting: IReservationSetting | undefined;
  /** 表示中の日別の予約枠一覧 */
  currentFrames: IReservationFrame[];
  /** 初回取得時の日別の予約枠設定 */
  initSetting: IReservationSetting | undefined;
  /** 初回取得時の日別の予約枠一覧 */
  initFrames: IReservationFrame[];
  /** 表示中の日別の予約枠に対する予約一覧 */
  currentReservationList: IReservationInformation[];
};

const initialState: DaySettingsContentState = {
  currentDate: new Date(),
  currentSetting: undefined,
  currentFrames: [],
  initSetting: undefined,
  initFrames: [],
  currentReservationList: [],
};

const daySettingsContentStateAtom = atom({
  key: 'DaySettingContentState',
  default: initialState,
});

export default daySettingsContentStateAtom;