import { useRecoilState } from "recoil";
import { useCallback, useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { useAuthSignOut, useAuthUser } from "@react-query-firebase/auth";
import { useNavigate } from "react-router";
import { auth } from "../../../firebase";
import registerPageStateAtom from "../../../recoil/features/auth/RegisterPage";
import useResultAlertState from "../../../components/ResultAlert/useResultAlertState";
import IClinicInformation, { validateClinicInformation } from "../../../interfaces/IClinicInformation";
import {
  setReservationBaseFrames,
  setReservationBaseSettings
} from "../../../repositories/reservationSettingRepository";
import { updateClinicInformation } from "../../../repositories/clinicRepository";
import { homePagePath } from "../../../layout/urls";
import { initialReservationSettings } from "../../../interfaces/IReservationSetting";
import getAddressByPostalCodeApi from "../../../repositories/axiosRepository";
import { setNotificationSetting } from "../../../repositories/notificationSettingRepository";
import { initialNotificationSettings } from "../../../interfaces/INotificationSetting";
import { dayOfWeekNum2string } from "../../../utils/converter";
import { initialReservationBaseFrames } from "../../../interfaces/IReservationFrame";

/**
 * クリニック登録ページの状態管理やロジックをまとめたHooks
 * @group Components
 * @category features/auth
 */
const useRegisterPageState = () => {
  // ログイン中のユーザー情報の監視
  const clinicUser = useAuthUser([ 'user' ], auth);
  const navigate = useNavigate();
  const [ state, setState ] = useRecoilState(registerPageStateAtom);
  const { openAlert } = useResultAlertState();
  
  // InputFormの入力値の状態をonChangeで保持
  /**
   * クリニック名の変更を状態に反映させる。
   */
  const onChangeClinicName = useCallback((clinicName: string) => {
    setState((prev) => ({ ...prev, clinicName }))
  }, [ setState ]);
  /**
   * 郵便番号の変更を状態に反映させる。
   */
  const onChangePostalCode = useCallback((postalCode: string) => {
    setState((prev) => ({ ...prev, postalCode }))
  }, [ setState ]);
  /**
   * 住所の変更を状態に反映させる。
   */
  const onChangeAddress = useCallback((address: string) => {
    setState((prev) => ({ ...prev, address }))
  }, [ setState ]);
  /**
   * 電話番号を状態に反映させる。
   */
  const onChangePhoneNumber = useCallback((phoneNumber: string) => {
    setState((prev) => ({ ...prev, phoneNumber }))
  }, [ setState ]);
  /**
   * 診療科目を状態に反映させる。
   */
  const onChangeDepartments = useCallback((departments: string) => {
    setState((prev) => ({ ...prev, departments }))
  }, [ setState ]);
  /**
   * 自由入力欄の内容を状態に反映させる。
   */
  const onChangeFreeInput = useCallback((freeInput: string) => {
    setState((prev) => ({ ...prev, freeInputs: freeInput }))
  }, [ setState ]);
  /**
   * 何か月先の予約を受け付けるかの設定を状態に反映させる。
   */
  const onChangeReservationPeriod = useCallback((reservationPeriod: string) => {
    setState((prev) => ({ ...prev, reservationPeriod }))
  }, [ setState ]);
  /**
   * 日曜日スタートか、月曜日スタートかの設定を状態に反映させる。
   */
  const onChangeCalendarStart = useCallback((calendarStart: number) => {
    setState((prev) => ({ ...prev, calendarStart }))
  }, [ setState ])
  /**
   * 当日予約を受け付けるかの設定を状態に反映させる。
   */
  const onChangeIsAvailableSameDayReserve = useCallback((isAvailable: string) => {
    setState((prev) => ({ ...prev, isAvailableSameDayReserve: isAvailable }))
  }, [ setState ]);
  /**
   * 当日予約を受け付ける場合の締切時間（分）を状態に反映させる。
   */
  const onChangeSameDayReservePeriod = useCallback((period: string) => {
    setState((prev) => ({ ...prev, sameDayReservePeriod: period }))
  }, [ setState ]);
  
  /**
   * クリニック情報の登録する。
   */
  const register = useCallback(async () => {
    if (!clinicUser.data?.uid) {
      navigate('/login');
      return;
    }
    const clinicInfo: IClinicInformation = {
      id: clinicUser.data?.uid,
      clinicName: state.clinicName,
      postalCode: state.postalCode,
      address: state.address,
      phoneNumber: state.phoneNumber,
      departments: state.departments,
      freeInputs: state.freeInputs,
      reservationPeriod: state.reservationPeriod,
      isAvailableSameDayReserve: state.isAvailableSameDayReserve === "enable",
      sameDayReservePeriod: state.sameDayReservePeriod,
      calendarStart: state.calendarStart,
      clinicStatus: 2,
      isInitialized: true,
    };
    const errors = validateClinicInformation(clinicInfo);
    if (errors) {
      setState((prev) => ({ ...prev, errors }))
      openAlert('error', '入力情報を確認してください。')
      return;
    }
    setState((prev) => ({ ...prev, errors: undefined }))
    await updateClinicInformation(clinicUser.data.uid, clinicInfo);
    // 月曜～日曜の曜日予約枠設定の初期値セット
    await setReservationBaseSettings(clinicUser.data.uid, initialReservationSettings(false,'base', 0));
    await setReservationBaseSettings(clinicUser.data.uid, initialReservationSettings(true,'base', 1));
    await setReservationBaseSettings(clinicUser.data.uid, initialReservationSettings(true,'base', 2));
    await setReservationBaseSettings(clinicUser.data.uid, initialReservationSettings(true,'base', 3));
    await setReservationBaseSettings(clinicUser.data.uid, initialReservationSettings(true,'base', 4));
    await setReservationBaseSettings(clinicUser.data.uid, initialReservationSettings(true,'base', 5));
    await setReservationBaseSettings(clinicUser.data.uid, initialReservationSettings(false,'base', 6));
    // 通知設定の初期値セット
    await setNotificationSetting(clinicUser.data.uid, initialNotificationSettings(clinicUser.data.uid));
    // 基本予約枠の初期値セット
    await setReservationBaseFrames(clinicUser.data.uid, dayOfWeekNum2string(1), initialReservationBaseFrames());
    await setReservationBaseFrames(clinicUser.data.uid, dayOfWeekNum2string(2), initialReservationBaseFrames());
    await setReservationBaseFrames(clinicUser.data.uid, dayOfWeekNum2string(3), initialReservationBaseFrames());
    await setReservationBaseFrames(clinicUser.data.uid, dayOfWeekNum2string(4), initialReservationBaseFrames());
    await setReservationBaseFrames(clinicUser.data.uid, dayOfWeekNum2string(5), initialReservationBaseFrames());
    openAlert('success', 'クリニック情報を登録しました。');
    window.location.replace(homePagePath);
  }, [ clinicUser.data?.uid, navigate, openAlert, setState, state ]);
  
  /**
   * クリニック情報登録のクエリ定義
   */
  const {
    isLoading: isLoadingRegister,
    isRefetching: isRefetchingRegister,
    refetch: refetchRegister
  } = useQuery('ClinicRegister', () => register(), {
    enabled: false,
    suspense: false,
  });
  
  /**
   * 保存ボタンクリック時の処理
   */
  const onClickRegister = useCallback(() => {
    void refetchRegister();
  }, [ refetchRegister ]);
  
  /**
   * 郵便番号から住所をセットする。
   */
  const getAddress = useCallback(async () => {
    const addressStr = await getAddressByPostalCodeApi(state.postalCode)
    if (addressStr === '') {
      openAlert('error', '郵便番号からの住所取得に失敗しました。');
      return
    }
    setState((prev) => ({ ...prev, address: addressStr }));
    openAlert('success', '郵便番号から住所を取得しました。');
  }, [ openAlert, setState, state.postalCode ])
  
  /**
   * APIから住所取得のクエリ定義
   */
  const {
    isLoading: isLoadingGetAddress,
    isRefetching: isRefetchingGetAddress,
    refetch: refetchGetAddress
  } = useQuery('GetAddress', () => getAddress(), {
    enabled: false,
    suspense: false,
  });
  
  /**
   * 郵便番号から住所取得ボタンクリック時の処理
   */
  const getAddressByPostalCode = useCallback(() => {
    void refetchGetAddress();
  }, [ refetchGetAddress ])
  
  // FirebaseAuthログアウト処理Mutation
  const authLogoutMutation = useAuthSignOut(auth);
  
  // ログアウト処理
  const signOutMutation = useMutation(async () => {
    await authLogoutMutation.mutateAsync();
    window.location.reload();
  })
  
  /**
   * ログアウトボタンクリック時
   */
  const onClickLogOut = useCallback(() => {
    void signOutMutation.mutate();
  }, [ signOutMutation ]);
  
  /**
   * クリニック設定ページ上でのローディング状態
   */
  const isSaving = useMemo(() => isLoadingRegister || isRefetchingRegister ||
      isLoadingGetAddress || isRefetchingGetAddress,
    [ isLoadingGetAddress, isLoadingRegister, isRefetchingGetAddress, isRefetchingRegister ]
  );
  
  return {
    state,
    isSaving,
    onChangeClinicName,
    onChangePostalCode,
    onChangeAddress,
    onChangePhoneNumber,
    onChangeDepartments,
    onChangeFreeInput,
    onChangeReservationPeriod,
    onChangeIsAvailableSameDayReserve,
    onChangeSameDayReservePeriod,
    onChangeCalendarStart,
    getAddressByPostalCode,
    onClickRegister,
    onClickLogOut,
  }
};

export default useRegisterPageState;
