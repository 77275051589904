import { ReactNode, useMemo } from "react";
import { useNavigate } from "react-router";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import ScheduleIcon from "@mui/icons-material/Schedule";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Box, Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon, ListItemText,
  Stack,
  Toolbar,
  Typography
} from "@mui/material";
import {
  basicSettingsPath, emailSettingPath,
  notificationSettingsPath,
  passwordSettingPath,
  reservationSettingsPath
} from "../../layout/urls";
import useCurrentAuthState from "../../features/auth/CurrentAuthState/useCurrentAuthState";
import useNavDrawerMobileState from "./useNavDrawerMobileState";

type MenuItemType = {
  label: string;
  icon: ReactNode;
  onClick?: () => void;
};

/**
 * スマートフォン向けナビゲーションドロワー
 * @constructor
 * @group Components
 * @category components
 */
const NavDrawerMobile = () => {
  const drawerWidth = 240;
  const navigate = useNavigate();
  const { state: authState, isLoading } = useCurrentAuthState();
  const { state, handleClose } = useNavDrawerMobileState();
  
  const clinicName = useMemo(() => {
    if (isLoading) {
      return 'Loading...';
    }
    if (authState.clinicInfo) {
      return authState.clinicInfo.clinicName;
    }
    return 'データ取得エラー';
  }, [ isLoading, authState.clinicInfo ]);
  
  /**
   * ドロワー表示メニュー項目
   */
  const menuItems: MenuItemType[] = [
    {
      label: 'ホーム',
      icon: <HomeIcon />,
      onClick: () => {
        handleClose();
        navigate('/');
      },
    },
    {
      label: '予約枠設定',
      icon: <ScheduleIcon />,
      onClick: () => {
        handleClose();
        navigate(reservationSettingsPath)
      }
    },
    {
      label: '基本設定',
      icon: <SettingsIcon />,
      onClick: () => {
        handleClose();
        navigate(basicSettingsPath);
      },
    },
    {
      label: '通知設定',
      icon: <NotificationsIcon />,
      onClick: () => {
        handleClose();
        navigate(notificationSettingsPath)
      }
    }
  ];
  
  return <Drawer
    variant='temporary'
    open={state.isOpen}
    onClose={handleClose}
    sx={{
      width: drawerWidth,
    }}>
    <Toolbar />
    <Box sx={{ overflow: 'auto' }}>
      <Box my={4} mx={2}>
        <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
          <Typography variant='h6' fontWeight='bold'>{clinicName}</Typography>
        </Stack>
      </Box>
      <Divider />
      <List>
        {menuItems.map((item) => <ListItem key={item.label}>
          <ListItemButton onClick={item.onClick}>
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItemButton>
        </ListItem>)
        }
      </List>
      <Divider />
      <Stack pt={4} spacing={1} justifyContent='start' alignItems='start'>
        <Button fullWidth variant='text' onClick={() => {
          handleClose();
          navigate(passwordSettingPath)
        }}>
          パスワード設定
        </Button>
        <Button fullWidth variant='text' onClick={() => {
          handleClose();
          navigate(emailSettingPath)
        }}>
          メールアドレス設定
        </Button>
        <Button fullWidth variant='text' onClick={() => {
          handleClose();
          window.open(`https://clinic-reserve-patient.web.app/${authState.clinicInfo?.id ?? ""}`)
        }}>
          予約画面を開く
        </Button>
      </Stack>
    </Box>
  </Drawer>
};

export default NavDrawerMobile;