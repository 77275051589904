import { useRecoilState } from "recoil";
import { useCallback } from "react";
import reservationDetailDialogStateAtom from "../../../recoil/features/HomePage/ReservationDetailDialog";
import IReservationInformation from "../../../interfaces/IReservationInformation";
import IReservationFrame from "../../../interfaces/IReservationFrame";

/**
 * 予約詳細表示ダイアログの状態管理やロジックをまとめたHooks
 * @group Components
 * @category features/HomePage
 */
const useReservationDetailDialogState = () => {
  const [ state, setState ] = useRecoilState(reservationDetailDialogStateAtom);
  
  /**
   * 予約詳細表示ダイアログの表示の時の処理
   */
  const openDialog = useCallback(
    (reservationInfo: IReservationInformation, reservationFrame: IReservationFrame, currentDate: Date) => {
      setState((prev) =>
        ({ ...prev, isOpen: true, reservationInfo, reservationFrame, currentDate }))
    }, [ setState ]);
  
  /**
   * 予約詳細表示ダイアログの閉じる時の処理
   */
  const closeDialog = useCallback(() => {
    setState((prev) => ({
      ...prev, isOpen: false,
      reservationInfo: undefined, reservationFrame: undefined, currentDate: undefined
    }))
  }, [ setState ]);
  
  return {
    state,
    openDialog,
    closeDialog,
  };
};

export default useReservationDetailDialogState;