import {
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText, Stack,
  Toolbar, Typography
} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import ScheduleIcon from '@mui/icons-material/Schedule';
import HomeIcon from '@mui/icons-material/Home';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { ReactNode, useMemo } from "react";
import { useNavigate } from "react-router";
import useCurrentAuthState from "../../features/auth/CurrentAuthState/useCurrentAuthState";
import {
  basicSettingsPath, emailSettingPath,
  notificationSettingsPath,
  passwordSettingPath,
  reservationSettingsPath
} from "../../layout/urls";

type MenuItemType = {
  label: string;
  icon: ReactNode;
  onClick?: () => void;
};

/**
 * PC・タブレット向けナビゲーションドロワー
 * @constructor
 * @group Components
 * @category components
 */
const NavDrawer = () => {
  const drawerWidth = 280;
  const navigate = useNavigate();
  const { state, isLoading } = useCurrentAuthState();
  
  const clinicName = useMemo(() => {
    if (isLoading) {
      return 'Loading...';
    }
    if (state.clinicInfo) {
      return state.clinicInfo.clinicName;
    }
    return 'データ取得エラー';
  }, [ isLoading, state.clinicInfo ]);
  
  /**
   * ドロワー表示メニュー項目
   */
  const menuItems: MenuItemType[] = [
    {
      label: 'ホーム',
      icon: <HomeIcon />,
      onClick: () => {
        navigate('/');
      },
    },
    {
      label: '予約枠設定',
      icon: <ScheduleIcon />,
      onClick: () => {
        navigate(reservationSettingsPath)
      }
    },
    {
      label: '基本設定',
      icon: <SettingsIcon />,
      onClick: () => {
        navigate(basicSettingsPath);
      },
    },
    {
      label: '通知設定',
      icon: <NotificationsIcon />,
      onClick: () => {
        navigate(notificationSettingsPath)
      }
    },
  ];
  
  return <Drawer
    variant='permanent'
    sx={{
      width: drawerWidth,
      flexShrink: 0,
      [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
    }}>
    <Toolbar />
    <Box sx={{ overflow: 'auto' }}>
      <Box my={4} mx={2}>
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center' spacing={2}
        >
          <Typography
            variant='h5'
            fontWeight='bold'
          >
            {clinicName}
          </Typography>
        </Stack>
      </Box>
      <Divider />
      <List>
        {menuItems.map(
          (item) => <ListItem
            key={item.label}
          >
            <ListItemButton
              onClick={item.onClick}
            >
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.label}
                primaryTypographyProps={{
                  fontSize: 20,
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
      </List>
      <Divider />
      <Stack pt={4} spacing={1} justifyContent='start' alignItems='start'>
        <Button
          fullWidth
          variant='text'
          sx={{
            fontSize: 16
          }}
          onClick={() => {
            navigate(passwordSettingPath)
          }}
        >
          パスワード設定
        </Button>
        <Button
          fullWidth
          variant='text'
          sx={{
            fontSize: 16
          }}
          onClick={() => {
            navigate(emailSettingPath)
          }}
        >
          メールアドレス設定
        </Button>
        <Button
          fullWidth
          variant='text'
          sx={{
            fontSize: 16
          }}
          onClick={() => {
            window.open(`https://clinic-reserve-patient.web.app/${state.clinicInfo?.id ?? ""}`)
          }}
        >
          予約画面を開く
        </Button>
      </Stack>
    </Box>
  </Drawer>
}

export default NavDrawer;