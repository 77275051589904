import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Switch,
  Toolbar,
  Typography
} from "@mui/material";
import { ja } from "date-fns/locale";
import { format } from "date-fns";
import LoadingButton from "@mui/lab/LoadingButton";
import { Add, Info } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WeeklyCalendar from "../../../components/WeeklyCalendar/WeeklyCalendar";
import useReservationBaseSettingsContentState from "./useReservationBaseSettingsContentState";
import TimeSelectForm from "../../../components/TimeSelectForm/TimeSelectForm";
import ReservationFrameItem from "./ReservationFrameItem";
import CreateReservationFrameDialog from "./CreateReservationFrameDialog/CreateReservationFrameDialog";
import useCreateReservationFrameDialogState from "./CreateReservationFrameDialog/useCreateReservationFrameDialogState";
import NotSaveConfirmDialog from "./ChangeDayConfirmDialog/NotSaveConfirmDialog";
import useResultAlertState from "../../../components/ResultAlert/useResultAlertState";

/**
 * 曜日単位の予約枠設定のレイアウト
 * @constructor
 * @group Components
 * @category features/ReservationSettingsPage
 */
const ReservationBaseSettingsContent = () => {
  const {
    state,
    clinicInfo,
    isSave,
    baseSettings,
    currentSetting,
    currentFrames,
    onClickDay,
    onClickNotSave,
    isSaving,
    onClickCancel,
    onClickSaveReservationBaseSettings,
    onChangeOpenAt,
    onChangeCloseAt,
    onChangeIsOpen,
    onChangeIsReserveFramesShow,
    onCloseCreateReservationFrameDialog,
    onDeleteReservationFrame,
    onClickCopy,
    onClickPaste,
  } = useReservationBaseSettingsContentState();
  
  const { openDialog: openReservationFrameDialog } = useCreateReservationFrameDialogState();
  const { openAlert } = useResultAlertState();
  
  const dayOfWeekFormat = 'EEE';
  return <>
    <Stack direction='column' spacing={4} mt={4}>
      <Typography variant='body1'>
        予約枠のベースを曜日ごとに設定します。
      </Typography>
      <Divider />
      <WeeklyCalendar
        calendarStart={clinicInfo?.calendarStart ?? 0}
        currentDate={state.currentDate}
        isDayOfWeekOnly
        onClickDay={onClickDay}
        isOpens={baseSettings?.map((val) => val.isOpen)}
        reservationSettings={baseSettings}
      />
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item xs={12} sm={4} sx={{
          mb: {
            'xs': '8px',
            'sm': '0',
          },
        }}>
          <div>
            <Typography variant='h5' display='inline'
                        fontWeight='bold'>{format(state.currentDate, dayOfWeekFormat, { locale: ja })}曜日</Typography>
            <Typography variant='h6' display='inline'>の予約枠設定</Typography>
            <Stack direction='row' spacing={0.5} justifyContent='center' alignItems='center'>
              {isSave ? <CheckCircleIcon fontSize="small" sx={{ color: '#898989' }} /> :
                <Info fontSize="small" color='error' />}
              <Typography mt={1} variant='body1' fontWeight='bold' color={isSave ? '#898989' : 'error'}>
                {`${isSave ? "設定が最新状態です。" : "未保存の設定があります。"}`}
              </Typography>
            </Stack>
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Stack direction='row' alignItems='center' justifyContent='center'>
            <Typography variant='body1'>休診日</Typography>
            <Switch checked={currentSetting?.isOpen ?? false} onChange={onChangeIsOpen} />
            <Typography variant='body1'>診療日</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box sx={{
            display: {
              xs: 'none',
              sm: 'block',
            }
          }}>
            <Stack direction='column' alignItems='center' justifyContent='center' spacing={1}>
              <Button
                variant="outlined"
                onClick={() => {
                  onClickCopy();
                  openAlert("success", "予約枠設定をコピーしました。")
                }}
              >
                設定をコピー
              </Button>
              <Button
                variant="outlined"
                disabled={!(state.copySetting && state.copyFrames)}
                onClick={() => {
                  onClickPaste();
                  openAlert("success", "予約枠設定を貼り付けました。")
                }}
              >
                設定を貼り付け
              </Button>
            </Stack>
          </Box>
          <Box sx={{
            display: {
              xs: 'block',
              sm: 'none',
            }
          }}>
            <Stack direction='row' alignItems='center' justifyContent='center' spacing={1} mt={1}>
              <Button
                variant="outlined"
                onClick={() => {
                  onClickCopy();
                  openAlert("success", "予約枠設定をコピーしました。")
                }}
              >
                設定をコピー
              </Button>
              <Button
                variant="outlined"
                disabled={!(state.copySetting && state.copyFrames)}
                onClick={() => {
                  onClickPaste();
                  openAlert("success", "予約枠設定を貼り付けました。")
                }}
              >
                設定を貼り付け
              </Button>
            </Stack>
          </Box>
        </Grid>
        
        {currentSetting?.isOpen ?
          <>
            <Grid item xs={12} my={4}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={4} sx={{
              mb: {
                'xs': '8px',
                'sm': '0',
              },
            }}>
              <Typography variant='h6'>
                診療時間
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Stack direction='row' alignItems='center' justifyContent='center'>
                <TimeSelectForm
                  label='開始時刻'
                  value={currentSetting?.openAt}
                  onChange={onChangeOpenAt} />
                <Box mx={2}>〜</Box>
                <TimeSelectForm
                  label='終了時刻'
                  value={currentSetting?.closeAt}
                  onChange={onChangeCloseAt} />
              </Stack>
            </Grid>
            <Grid item xs={12} my={4}>
              <Divider />
            </Grid>
            <Grid container justifyContent='center' alignItems='center'>
              <Grid item xs={12} sm={state.currentFrames.length > 0 ? 6 : 12} sx={{
                mb: {
                  'xs': '15px',
                  'sm': '0',
                },
              }}>
                <Button
                  variant="outlined"
                  color='inherit'
                  startIcon={<Add />}
                  sx={{ px: '24px', py: '8px' }}
                  onClick={openReservationFrameDialog}
                >
                  予約枠を追加
                </Button>
              </Grid>
              {state.currentFrames.length > 0
                ?
                <Grid item xs={12} sm={6}>
                  <Stack spacing={0}>
                    <Typography variant='caption'>新規予約受付させますか？</Typography>
                    <Stack direction='row' alignItems='center' justifyContent='center'>
                      <Typography variant='body1'>停止中</Typography>
                      <Switch checked={currentSetting?.isReserveFramesShow ?? true}
                              onChange={onChangeIsReserveFramesShow} />
                      <Typography variant='body1'>受付中</Typography>
                    </Stack>
                  </Stack>
                </Grid>
                : null
              }
            </Grid>
            <Grid item xs={10} mt={4}>
              <Stack direction='column' spacing={4}>
                {state.currentFrames.map((item, index) =>
                  <ReservationFrameItem
                    key={JSON.stringify(item)}
                    reservationFrame={item}
                    index={index}
                    onClick={() => onDeleteReservationFrame(index)}
                  />
                )}
              </Stack>
            </Grid>
          </> : null}
      </Grid>
    </Stack>
    <AppBar position='fixed' color='secondary' sx={{ top: 'auto', bottom: 0, }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction='row' spacing={4}>
          <Button variant='text' color='inherit' onClick={onClickCancel}>
            キャンセル
          </Button>
          <Box mx='24px'>
            <LoadingButton
              loading={isSaving}
              onClick={onClickSaveReservationBaseSettings}
              color='inherit'
              variant='outlined'
              loadingIndicator='保存中...'
              sx={{ px: 4 }}
            >
              保存する
            </LoadingButton>
          </Box>
        </Stack>
      </Toolbar>
    </AppBar>
    <CreateReservationFrameDialog
      openAt={currentSetting?.openAt}
      closeAt={currentSetting?.closeAt}
      currentFrames={currentFrames}
      onClose={onCloseCreateReservationFrameDialog}
    />
    <NotSaveConfirmDialog onClickNotSave={onClickNotSave} />
  </>
};

export default ReservationBaseSettingsContent;