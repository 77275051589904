import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { FC } from "react";
import IReservationFrame from "../../../interfaces/IReservationFrame";
import { time2string } from "../../../utils/converter";

export type ReservationFrameItemProps = {
  reservationFrame: IReservationFrame;
  index: number,
  onClick: ((index: number) => void);
  disable?: boolean;
};

/**
 * 予約枠アイテムのコンポーネント
 * @param reservationFrame 1件の予約枠情報
 * @param index インデックス
 * @param onClick アイテムクリック時のコールバック
 * @param disable 変更可能かどうか
 * @constructor
 * @group Components
 * @category features/ReservationSettingsPage
 */
const ReservationFrameItem: FC<ReservationFrameItemProps> = ({
  reservationFrame, index, onClick, disable = false
}) =>
  <Card sx={{ paddingTop: '8px', backgroundColor: disable ? '#F5F5F5' : 'white' }}>
    <CardContent>
      <Grid container alignItems='center' justifyContent='center' spacing={2}>
        <Grid item xs={12} sm={7}>
          <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
            <Stack direction='column'>
              <Typography variant='caption'>開始時刻</Typography>
              <Typography variant='body1'>{time2string(reservationFrame.startAt)}</Typography>
            </Stack>
            <Stack direction='column'>
              <Typography variant='caption'>&nbsp;</Typography>
              <Typography variant='body1'>～</Typography>
            </Stack>
            <Stack direction='column'>
              <Typography variant='caption'>終了時刻</Typography>
              <Typography variant='body1'>{time2string(reservationFrame.endAt)}</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Stack direction='column'>
            <Typography variant='caption'>予約枠ポイント</Typography>
            <Typography
              variant='body1'>{`${reservationFrame.points}Pt (${reservationFrame.numberOfPeople})人`}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={1}>
          {!disable ?
            <IconButton onClick={() => onClick(index)}>
              <CloseIcon />
            </IconButton>
            : null
          }
        </Grid>
      </Grid>
    </CardContent>
  </Card>;

export default ReservationFrameItem;