import { atom } from "recoil";

export type NotSaveConfirmDialogState = {
  /** 指定中の日付 */
  day: Date | undefined;
  /** ダイアログ表示かどうか */
  isOpen: boolean;
};

const initialState: NotSaveConfirmDialogState = {
  day: undefined,
  isOpen: false,
};

const notSaveConfirmDialogStateAtom = atom({
  key: 'NotSaveConfirmDialogState',
  default: initialState,
});

export default notSaveConfirmDialogStateAtom;