import { Box, Container, Grid, Stack } from "@mui/material";
import SVG from "react-inlinesvg";
import { FC, ReactNode } from "react";
import LoginSideArtSvg from "../../assets/login_side_art.svg";
import useBreakpoint from "../../utils/useBreakPoint";

/**
 * 認証後のUI共通レイアウト
 * @param children 子供コンポーネント
 * @param row row比率
 * @param svg 画像素材
 * @constructor
 * @group Components
 * @category features/auth
 */
const AuthPageBase: FC<{ children?: ReactNode, row: number, svg?: string }> =
  ({
    children = null, row, svg = undefined
  }) => {
    const { innerHeight, breakpoint } = useBreakpoint();
    
    const desktopSizeWidgets = <Container maxWidth={false} style={{ padding: 0 }}>
      <Grid container alignItems='center' justifyContent='center' direction='row'>
        <Grid item alignItems='center' justifyContent='center' xs={row}>
          <Box bgcolor='#FFFFFF' minHeight='100vh'>
            <Grid container style={{
              minHeight: '100%'
            }}>
              <Grid item xs={12}>
                {children}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={12 - row}>
          <Box minHeight='100vh'>
            <Grid container alignItems='end' justifyContent='center' style={{
              minHeight: '100%'
            }}>
              <Grid item xs={12} mb={8} style={{
                position: 'fixed',
                bottom: '0',
              }}>
                <SVG src={svg !== undefined ? svg : LoginSideArtSvg} height='30vh' width='100%' />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>;
    
    const tabletSizeWidgets = <Container maxWidth={false} style={{ padding: 0 }}>
      <Stack direction='column' justifyContent='center' minHeight={innerHeight}>
        {children}
      </Stack>
    </Container>;
    
    switch (breakpoint) {
      // Mobile
      // Tablet size
      case "xs":
      case "sm":
        return tabletSizeWidgets;
      case "md":
      case "lg":
      case "xl":
        return desktopSizeWidgets;
      default:
        return desktopSizeWidgets;
    }
  }

export default AuthPageBase;