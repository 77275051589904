import { atom } from "recoil";

export type NotificationDetailSettingsContentState = {
  /** 選択日 */
  selectDayAnchorEl: null | HTMLElement;
};

const initialState: NotificationDetailSettingsContentState = {
  selectDayAnchorEl: null,
};

const notificationDetailSettingsContentStateAtom = atom({
  key: 'NotificationDetailSettingsContentState',
  default: initialState,
});

export default notificationDetailSettingsContentStateAtom;