import { atom } from "recoil";
import INotificationSetting, {
  initialNotificationSettings,
  NotificationSettingErrors
} from "../../../interfaces/INotificationSetting";

export type NotificationSettingsPageState = {
  /** 現在の通知設定 */
  currentSetting: INotificationSetting;
  /** バリデーションエラー */
  errors: NotificationSettingErrors | undefined;
};

const initialState: NotificationSettingsPageState = {
  currentSetting: initialNotificationSettings(''),
  errors: undefined,
};

const notificationSettingsPageStateAtom = atom({
  key: 'NotificationSettingsPageState',
  default: initialState,
});

export default notificationSettingsPageStateAtom;