import { useQuery } from "react-query";
import { useCallback, useEffect, useMemo } from "react";
import {
  useAuthSignInWithEmailAndPassword, useAuthUpdateEmail
} from "@react-query-firebase/auth";
import { useRecoilState, useResetRecoilState } from "recoil";
import { FirebaseError } from "firebase/app";
import useResultAlertState from "../../components/ResultAlert/useResultAlertState";
import { auth } from "../../firebase";
import { IEmailSetting, validateEmailSetting } from "../../interfaces/IAuthInformation";
import emailSettingPageStateAtom from "../../recoil/features/EmailSettingPage";
import { updateEmailClinicInfo } from "../../repositories/clinicRepository";
import { firebaseError2ErrorMessage } from "../../utils/converter";

/**
 * メールアドレス設定ページの状態管理やロジックをまとめたHooks
 * @group Components
 * @category features/BasicSettingsPage
 */
const useEmailSettingPageState = () => {
  const [ state, setState ] = useRecoilState(emailSettingPageStateAtom);
  const resetState = useResetRecoilState(emailSettingPageStateAtom);
  useEffect(() => () => resetState(), [ resetState ]);
  
  const { openAlert } = useResultAlertState();
  
  /**
   * 現在のパスワードの変更を状態に反映させる。
   */
  const onChangeCurrentPassword = useCallback((currentPassword: string) => {
    setState((prev) => ({ ...prev, currentPassword }))
  }, [ setState ]);
  /**
   * 新しいメールアドレスの変更を状態に反映させる。
   */
  const onChangeNewEmail = useCallback((newEmail: string) => {
    setState((prev) => ({ ...prev, newEmail }))
  }, [ setState ]);
  /**
   * 現在のメールアドレスの変更を状態に反映させる。
   */
  const currentEmail = useMemo(() => {
    if (!auth.currentUser || !auth.currentUser.email) {
      return '';
    }
    if (state.resultEmail !== '') {
      return state.resultEmail;
    }
    return auth.currentUser.email
  }, [ state.resultEmail ])
  
  const reSignInMutation = useAuthSignInWithEmailAndPassword(auth);
  const emailUpdateMutation = useAuthUpdateEmail();
  
  /**
   * メールアドレス変更の実行処理。
   */
  const emailSetting = useCallback(async () => {
    if (!auth.currentUser || !auth.currentUser.email) {
      return;
    }
    const emailSettingItem: IEmailSetting = {
      currentPassword: state.currentPassword,
      newEmail: state.newEmail,
    };
    const errors = validateEmailSetting(emailSettingItem);
    if (errors) {
      setState((prev) => ({ ...prev, errors }))
      return;
    }
    try {
      const res = await reSignInMutation.mutateAsync({
        email: auth.currentUser.email,
        password: state.currentPassword
      });
      if (res.user) {
        await emailUpdateMutation.mutateAsync({
          user: auth.currentUser,
          newEmail: state.newEmail
        });
        await updateEmailClinicInfo(auth.currentUser.uid, state.newEmail);
        await auth.currentUser.reload();
        openAlert('success', 'メールアドレスの変更に成功しました。');
        const tmpCurrentEmail = state.newEmail;
        resetState()
        setState((prev) => ({ ...prev, resultEmail: tmpCurrentEmail }))
      }
    } catch (err) {
      if (err instanceof FirebaseError) {
        const message = firebaseError2ErrorMessage(err);
        openAlert("error", message);
        return;
      }
      openAlert('error', 'メールアドレスの変更に失敗しました。');
    }
  }, [ state.currentPassword, state.newEmail, setState, reSignInMutation, emailUpdateMutation, openAlert, resetState ]);
  
  /**
   * メールアドレス変更のクエリ定義
   */
  const {
    isLoading: isLoadingEmailSetting,
    isRefetching: isRefetchingEmailSetting,
    refetch,
  } = useQuery('emailSetting', emailSetting, { enabled: false, suspense: false });
  
  /**
   * この内容で変更するボタンのクリック時の処理
   */
  const onClickEmailSetting = useCallback(() => {
    void refetch();
  }, [ refetch ]);
  
  const isLoading = useMemo(() => isLoadingEmailSetting || isRefetchingEmailSetting,
    [ isLoadingEmailSetting, isRefetchingEmailSetting ]);
  
  return {
    state, currentEmail, isLoading, onChangeCurrentPassword, onChangeNewEmail, onClickEmailSetting
  };
};

export default useEmailSettingPageState;