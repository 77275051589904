import {
  Box, Button,
  Card,
  CardContent,
  FormControlLabel,
  FormGroup,
  Grid,
  Menu, MenuItem,
  Stack,
  Switch,
  Typography
} from "@mui/material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import InputForm from "../../../components/InputForm/InputForm";
import useNotificationDetailSettingsContent from "./useNotificationDetailSettingsContent";
import useNotificationSettingsPageState from "../useNotificationSettingsPageState";

/**
 * 通知詳細設定のコンテンツコンポーネント
 * @constructor
 * @group Components
 * @category features/NotificationSettingsPage
 */
const NotificationDetailSettingsContent = () => {
  const {
    state,
    onChangeBeforeXNumber,
    onChangeBeforeXEnabled,
    onChangeBeforeXMessage,
    onChangeCompletedEnabled,
    onChangeCompletedMessage,
    onChangeCanceledEnabled,
    onChangeCanceledMessage,
    onChangePrevDayEnabled,
    onChangePrevDayMessage,
    messagePreview,
  } = useNotificationSettingsPageState();
  const {
    state: selectDayState,
    openSelectDayMenu,
    closeSelectDayMenu,
  } = useNotificationDetailSettingsContent();
  return <>
    <Grid item xs={12} md={4}>
      <Box mx={2} mt={2}>
        <Stack direction='column' spacing={2} sx={{
          textAlign: {
            'xs': 'center',
            'md': 'start',
          },
        }}>
          <Typography variant='h6' fontWeight='bold'>
            通知詳細設定
          </Typography>
          <Typography variant='caption'>
            通知に関する詳細な設定を行います。<br />
            通知を送信するタイミングや、メッセージ内容を設定できます。<br />
            ここで設定した内容は、患者様へ送信される通知のタイミングや内容に影響します。
          </Typography>
          <Typography variant='caption' textAlign='start'>
            メッセージの設定には、下記のコマンドを使うことが出来ます。<br />
            {"{beforeNum} => "}{state.currentSetting.beforeXNum} (何番前かの数値)<br />
            {"{beforeMinutes} => "}何分後に呼び出し予定かの数値<br />
            {"{time} => "}何月何日 何時何分頃に呼び出し予定かを伝えるメッセージ文<br />(YYYY年MM月dd日 HH:mm 頃に来院して下さい。)
          </Typography>
        </Stack>
      </Box>
    </Grid>
    <Grid item xs={12} md={8} mb={2}>
      <Card sx={{ padding: 2 }}>
        <CardContent>
          <Stack direction='column' spacing={2} mb={6}>
            <Stack direction='row' alignItems='center'>
              <Stack direction='column' spacing={2} mb={2}>
                <Typography variant='h6' fontWeight='bold' textAlign='start'>
                  予約順の
                  <Button
                    variant='text'
                    color='inherit'
                    disableElevation
                    endIcon={<KeyboardArrowDown />}
                    onClick={openSelectDayMenu}
                  >
                    {state.currentSetting.beforeXNum}
                  </Button>
                  <Menu
                    open={Boolean(selectDayState.selectDayAnchorEl)}
                    anchorEl={selectDayState.selectDayAnchorEl}
                    elevation={0}
                    onClose={closeSelectDayMenu}
                  >
                    {
                      [ 1, 2, 3, 4, 5 ].map((value) => <MenuItem
                        key={value}
                        disableRipple
                        onClick={
                          () => {
                            onChangeBeforeXNumber(value);
                            closeSelectDayMenu();
                          }
                        }>
                        {value}
                      </MenuItem>)
                    }
                  </Menu>
                  番前になったとき
                </Typography>
                <Typography variant='body1' textAlign='start'>
                  予約順が {state.currentSetting.beforeXNum} 番前になった患者様に通知を送信するかどうかを設定します。<br />
                  また、その際に送信されるメッセージの内容も設定出来ます。
                </Typography>
                <Typography variant='caption' textAlign='start'>
                  メッセージ例：<br />
                  {"あなたの診察順が、{beforeNum} 番前になりました。"}<br />
                  {"約 {beforeMinutes} 分後にお呼び出し予定です。"}
                </Typography>
              </Stack>
              <Box sx={{ flexGrow: 1 }} />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch checked={state.currentSetting.beforeXEnable} onChange={onChangeBeforeXEnabled} />
                  }
                  label={`現在の設定：${state.currentSetting.beforeXEnable ? "ON" : "OFF"}`}
                  labelPlacement='start'
                />
              </FormGroup>
            </Stack>
            <InputForm
              label='通知メッセージ'
              multiline
              minLines={3}
              value={state.currentSetting.beforeXMessage}
              onChange={onChangeBeforeXMessage}
              error={state.errors?.beforeXMessage}
            />
            <Typography variant='caption' textAlign='start' whiteSpace="pre-wrap">
              メッセージプレビュー：<br />
              {messagePreview(state.currentSetting.beforeXMessage)}
            </Typography>
          </Stack>
          
          <Stack direction='column' spacing={2} mb={6}>
            <Stack direction='row' alignItems='center'>
              <Stack direction='column' spacing={2} mb={2}>
                <Typography variant='h6' fontWeight='bold' textAlign='start'>予約完了時</Typography>
                <Typography variant='body1' textAlign='start'>
                  予約が完了した患者様に通知を送信するかどうかを設定します。<br />
                  また、その際に送信されるメッセージの内容も設定出来ます。
                </Typography>
                <Typography variant='caption' textAlign='start'>
                  メッセージ例：<br />
                  診察予約が完了しました。<br />
                  {"{time}"}
                </Typography>
              </Stack>
              <Box sx={{ flexGrow: 1 }} />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.currentSetting.completedEnable}
                      onChange={onChangeCompletedEnabled}
                    />
                  }
                  label={`現在の設定：${state.currentSetting.completedEnable ? "ON" : "OFF"}`}
                  labelPlacement='start'
                />
              </FormGroup>
            </Stack>
            <InputForm
              label='通知メッセージ'
              multiline
              minLines={3}
              value={state.currentSetting.completedMessage}
              onChange={onChangeCompletedMessage}
              error={state.errors?.completedMessage}
            />
            <Typography variant='caption' textAlign='start' whiteSpace="pre-wrap">
              メッセージプレビュー：<br />
              {messagePreview(state.currentSetting.completedMessage)}
            </Typography>
          </Stack>
          
          <Stack direction='column' spacing={2} mb={6}>
            <Stack direction='row' alignItems='center'>
              <Stack direction='column' spacing={2}>
                <Typography variant='h6' fontWeight='bold' textAlign='start'>予約キャンセル時</Typography>
                <Typography variant='body1' textAlign='start'>
                  予約キャンセルが完了した患者様に通知を送信するかどうかを設定します。<br />
                  また、その際に送信されるメッセージの内容も設定出来ます。
                </Typography>
              </Stack>
              <Box sx={{ flexGrow: 1 }} />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch checked={state.currentSetting.canceledEnable} onChange={onChangeCanceledEnabled} />
                  }
                  label={`現在の設定：${state.currentSetting.canceledEnable ? "ON" : "OFF"}`}
                  labelPlacement='start'
                />
              </FormGroup>
            </Stack>
            <InputForm
              label='通知メッセージ'
              multiline
              minLines={3}
              value={state.currentSetting.canceledMessage}
              onChange={onChangeCanceledMessage}
              error={state.errors?.canceledMessage}
            />
          </Stack>
          
          <Stack direction='column' spacing={2} mb={2}>
            <Stack direction='row' alignItems='center'>
              <Stack direction='column' spacing={2}>
                <Typography variant='h6' fontWeight='bold' textAlign='start'>予約日の前日</Typography>
                <Typography variant='body1' textAlign='start'>
                  予約の前日に、患者様に通知を送信するかどうかを設定します。<br />
                  また、その際に送信されるメッセージの内容も設定出来ます。
                </Typography>
              </Stack>
              <Box sx={{ flexGrow: 1 }} />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch checked={state.currentSetting.prevDayEnable} onChange={onChangePrevDayEnabled} />
                  }
                  label={`現在の設定：${state.currentSetting.prevDayEnable ? "ON" : "OFF"}`}
                  labelPlacement='start'
                />
              </FormGroup>
            </Stack>
            <InputForm
              label='通知メッセージ'
              multiline
              minLines={3}
              value={state.currentSetting.prevDayMessage}
              onChange={onChangePrevDayMessage}
              error={state.errors?.prevDayMessage}
            />
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  </>
}

export default NotificationDetailSettingsContent;