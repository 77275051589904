import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { validateRequired } from "../utils/validator";

/**
 * 通知設定を表現するインターフェース
 */
export default interface INotificationSetting {
  /** クリニックID */
  clinicId: string,
  /** 通知機能全般ON/OFF */
  isEnable: boolean,
  /** 予約順の変化による通知ON/OFF */
  beforeXEnable: boolean,
  /** 予約順のX番前 */
  beforeXNum: number,
  /** X番前通知メッセージ */
  beforeXMessage: string,
  /** 予約完了時通知ON/OFF */
  completedEnable: boolean,
  /** 予約完了時通知メッセージ */
  completedMessage: string,
  /** 予約キャンセル時通知ON/OFF */
  canceledEnable: boolean,
  /** 予約キャンセル時通知メッセージ */
  canceledMessage: string,
  /** 予約日の前日リマインド通知ON/OFF */
  prevDayEnable: boolean,
  /** 予約日の前日リマインド通知メッセージ */
  prevDayMessage: string,
}

/**
 * 通知設定の初期設定を返す。
 * @param clinicId クリニックID
 * @return {INotificationSetting} 通知設定の初期設定が返る。
 */
export const initialNotificationSettings = (clinicId: string) => ({
  clinicId,
  isEnable: true,
  beforeXEnable: true,
  beforeXNum: 3,
  beforeXMessage: "あなたの診察順が、{beforeNum} 番前になりました。\n約 {beforeMinutes} 分後にお呼び出し予定です。",
  completedEnable: true,
  completedMessage: "診察予約が完了しました。\n{time}\n\n※当日の診察状況により、時間は前後する場合がございます。",
  canceledEnable: true,
  canceledMessage: "診察予約をキャンセルしました。",
  prevDayEnable: true,
  prevDayMessage: "明日は診察日です。診察予約を変更する際は、お電話にてご連絡をお願いいたします。",
} as INotificationSetting)

/**
 * Firestoreからの情報取得用、データコンバータ。
 * Firestoreからのsnapshotと、通知設定の型を変換する。
 */
export const notificationSettingConverter: FirestoreDataConverter<INotificationSetting> = {
  toFirestore: (notificationSetting: INotificationSetting): DocumentData => ({
    isEnable: notificationSetting.isEnable,
    beforeXEnable: notificationSetting.beforeXEnable,
    beforeXNum: notificationSetting.beforeXNum,
    beforeXMessage: notificationSetting.beforeXMessage,
    completedEnable: notificationSetting.completedEnable,
    completedMessage: notificationSetting.completedMessage,
    canceledEnable: notificationSetting.canceledEnable,
    canceledMessage: notificationSetting.canceledMessage,
    prevDayEnable: notificationSetting.prevDayEnable,
    prevDayMessage: notificationSetting.prevDayMessage,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): INotificationSetting => {
    const data = snapshot.data(options);
    return {
      clinicId: snapshot.id,
      isEnable: data.isEnable as boolean,
      beforeXEnable: data.beforeXEnable as boolean,
      beforeXNum: data.beforeXNum as number,
      beforeXMessage: data.beforeXMessage as string,
      completedEnable: data.completedEnable as boolean,
      completedMessage: data.completedMessage as string,
      canceledEnable: data.canceledEnable as boolean,
      canceledMessage: data.canceledMessage as string,
      prevDayEnable: data.prevDayEnable as boolean,
      prevDayMessage: data.prevDayMessage as string,
    } as INotificationSetting;
  },
};


export type NotificationSettingErrors = {
  beforeXMessage: string | undefined;
  completedMessage: string | undefined;
  canceledMessage: string | undefined;
  prevDayMessage: string | undefined;
};

/**
 * 通知設定のバリデーション。
 * @param notificationSetting 通知設定
 * @return {NotificationSettingErrors | undefined} バリデーションエラーが無い場合は、undefinedが返る。
 */
export const validateNotificationSetting = (notificationSetting: INotificationSetting): NotificationSettingErrors | undefined => {
  const errors: NotificationSettingErrors = {
    beforeXMessage: undefined,
    completedMessage: undefined,
    canceledMessage: undefined,
    prevDayMessage: undefined,
  };
  if (!notificationSetting.isEnable) {
    return undefined;
  }
  if (notificationSetting.beforeXEnable) {
    errors.beforeXMessage = validateRequired(notificationSetting.beforeXMessage)
  }
  if (notificationSetting.completedEnable) {
    errors.completedMessage = validateRequired(notificationSetting.completedMessage)
  }
  if (notificationSetting.canceledEnable) {
    errors.canceledMessage = validateRequired(notificationSetting.canceledMessage)
  }
  if (notificationSetting.prevDayEnable) {
    errors.prevDayMessage = validateRequired(notificationSetting.prevDayMessage)
  }
  if (Object.values(errors).findIndex((element) => typeof element === 'string') !== -1) {
    return errors;
  }
  return undefined;
};
