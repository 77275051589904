import { useRecoilState } from "recoil";
import { useAuthUser } from "@react-query-firebase/auth";
import { useCallback, useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { useQuery } from "react-query";
import { auth, firestore } from "../../../firebase";
import currentAuthStateAtom from "../../../recoil/features/auth/CurrentAuthState";
import { clinicInformationConverter } from "../../../interfaces/IClinicInformation";
import { getClinicInformation } from "../../../repositories/clinicRepository";

/**
 * 認証状態の状態管理やロジックをまとめたHooks
 * @group Components
 * @category features/auth
 */
const useCurrentAuthState = () => {
  const [ state, setState ] = useRecoilState(currentAuthStateAtom);
  const user = useAuthUser([ 'user' ], auth);
  
  const { data: clinicInformation, isLoading } = useQuery(
    [ 'getClinicInformation', user.data?.uid ],
    () => getClinicInformation(user.data?.uid ?? ''));
  
  useEffect(() => {
    setState((prev) => ({ ...prev, clinicInfo: clinicInformation ?? undefined }))
  }, [ clinicInformation, setState ]);
  
  /**
   * 認証状態の確認する。
   */
  const unsub = useCallback(() => {
    onSnapshot(
      doc(firestore, 'clinics', user.data?.uid ?? '').withConverter(clinicInformationConverter),
      (data) => {
        if (data.exists()) {
          setState((prev) => ({ ...prev, clinicInfo: data.data() }))
          return;
        }
        setState((prev) => ({ ...prev, clinicInfo: undefined }))
      });
  }, [ setState, user.data?.uid ]);
  
  useEffect(() => () => unsub(), [ unsub ]);
  
  return { state, user, isLoading };
};

export default useCurrentAuthState;