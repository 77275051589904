import { Box, Card, CardContent, FormControlLabel, FormGroup, Grid, Stack, Switch, Typography } from "@mui/material";
import useNotificationSettingsPageState from "./useNotificationSettingsPageState";

/**
 * 通知基本設定のコンテンツコンポーネント
 * @constructor
 * @group Components
 * @category features/NotificationSettingsPage
 */
const NotificationBasicSettingsContent = () => {
  const { state, onChangeIsEnabled } = useNotificationSettingsPageState();
  
  return <>
    <Grid item xs={12} md={4}>
      <Box mx={2} mt={2}>
        <Stack direction='column' spacing={2} sx={{
          textAlign: {
            'xs': 'center',
            'md': 'start',
          },
        }}>
          <Typography variant='h6' fontWeight='bold'>
            通知設定
          </Typography>
          <Typography variant='caption'>
            患者様への診察予約順等の通知に関する設定を行います。<br />
            ここで設定した内容は、患者様へ送信される通知のタイミングや内容に影響します。
          </Typography>
        </Stack>
      </Box>
    </Grid>
    <Grid item xs={12} md={8}>
      <Card sx={{ padding: 2 }}>
        <CardContent>
          <Stack direction='row' alignItems='center' mb={2}>
            <Stack direction='column' spacing={2}>
              <Typography variant='h6' fontWeight='bold' textAlign='start'>通知機能 ON / OFF</Typography>
              <Typography variant='body1' textAlign='start'>
                通知機能を利用するかどうかを設定します。
              </Typography>
            </Stack>
            <Box sx={{ flexGrow: 1 }} />
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch onChange={onChangeIsEnabled} checked={state.currentSetting.isEnable} />
                }
                label={`現在の設定：${state.currentSetting.isEnable ? "ON" : "OFF"}`}
                labelPlacement='start'
              />
            </FormGroup>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  </>;
}

export default NotificationBasicSettingsContent;