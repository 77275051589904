import { format } from "date-fns";

/**
 * 指定開始日から指定終了日までの期間のDateListを返す。
 * @param firstDate 指定開始日
 * @param lastDate 指定終了日
 * @return {Date[]} 指定期間のDateListが返る。
 * @category utils
 */
export const getPeriodDateList = (firstDate: Date, lastDate: Date): Date[] => {
  const first = new Date(firstDate);
  const last = new Date(lastDate);
  first.setHours(0, 0, 0, 0);
  last.setHours(0, 0, 0, 0);
  const dateList: Date[] = [];
  let isContinue = true;
  dateList.push(new Date(first));
  while (isContinue) {
    first.setDate(first.getDate() + 1);
    if (first >= last) {
      dateList.push(last);
      isContinue = false;
    } else {
      dateList.push(new Date(first));
    }
  }
  return dateList;
};

/**
 * 今日のDateをyyyy-MM-ddのフォーマットにして返す。
 * @return {Date} 今日の日付が返る。
 * @category utils
 */
export const getToday = (): Date => new Date(format(new Date(), 'yyyy-MM-dd'));

/**
 * 指定日の次の日のDateを返す。
 * @param date 指定日
 * @return {Date} 次の日のDateが返る。
 * @category utils
 */
export const getNextDate = (date: Date): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() + 1);
  return result;
};

/**
 * 指定日の前の日のDateを返す。
 * @param date 指定日
 * @return {Date} 前の日のDateが返る。
 * @category utils
 */
export const getPrevDate = (date: Date): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() - 1);
  return result;
};

/**
 * 生年月日から年齢を算出して返す。
 * @param birthday 生年月日
 * @return {number} 年齢が返る。
 * @category utils
 */
export const getAgeFromBirthday = (birthday: Date): number => {
  const now = new Date();
  const age = now.getFullYear() - birthday.getFullYear();
  const thisYearsBirthday = new Date(now.getFullYear(), birthday.getMonth(), birthday.getDate());
  return age + (thisYearsBirthday.getTime() > now.getTime() ? -1 : 0);
};