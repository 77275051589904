import { atom } from "recoil";
import IClinicInformation, { ClinicInformationErrors } from "../../../../interfaces/IClinicInformation";

export type RegisterPageState = {
  /** クリニック名 */
  clinicName: string;
  /** 郵便番号 */
  postalCode: string;
  /** 住所 */
  address: string;
  /** 電話番号 */
  phoneNumber: string;
  /** 診療科目 */
  departments: string;
  /** 自由入力 */
  freeInputs: string;
  /** 予約可能期間 */
  reservationPeriod: string;
  /** 当日予約可能か */
  isAvailableSameDayReserve: string;
  /** 当日予約の受付締切 */
  sameDayReservePeriod: string;
  /** 日曜日スタート: 0, 月曜日スタート: 1 */
  calendarStart: number;
  /** クリニック基本設定 */
  clinicInfo: IClinicInformation | undefined;
  /** バリデーションエラー */
  errors: ClinicInformationErrors | undefined;
};

const initialState: RegisterPageState = {
  clinicName: '',
  postalCode: '',
  address: '',
  phoneNumber: '',
  departments: '',
  freeInputs: '',
  reservationPeriod: '',
  isAvailableSameDayReserve: "enable",
  sameDayReservePeriod: '',
  calendarStart: 0,
  clinicInfo: undefined,
  errors: undefined,
};

const registerPageStateAtom = atom({
  key: 'RegisterPageState',
  default: initialState,
});

export default registerPageStateAtom;