/**
 * ルーティングのURLの設定
 */
export const loginPagePath = '/login';
export const resetPasswordPath = '/reset-password';
export const homePagePath = '/';
export const basicSettingsPath = '/settings';
export const notificationSettingsPath = '/notification';
export const reservationSettingsPath = '/reservation';
export const registerPagePath = '/register';
export const passwordSettingPath = '/password';
export const emailSettingPath = '/email';

// with search params
export const errorPagePath = '/error';
