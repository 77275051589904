import { Container, Stack, Typography } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { Link } from "react-router-dom";
import InputForm from "../../../components/InputForm/InputForm";
import AuthPageBase from "../AuthPageBase";
import useLoginPageState from "./useLoginPageState";
import { resetPasswordPath } from "../../../layout/urls";

/**
 * ログインページ
 * @constructor
 * @group Components
 * @category features/auth
 */
const LoginPage = () => {
  const { state, isLoading, onChangeEmail, onChangePassword, onClickLogin } = useLoginPageState();
  return <AuthPageBase row={5}>
    <Container maxWidth='xs'>
      <Stack direction='column' style={{ height: '100vh' }} justifyContent='center' spacing={4}>
        <Stack direction="column" spacing={1} alignItems='center' justifyContent='center'>
          <Typography variant='h3'>
            coMect
          </Typography>
          <Typography variant='subtitle1'>
            ～ クリニック・病院の診察予約サービス ～
          </Typography>
        </Stack>
        <InputForm
          label='メールアドレス'
          required
          type='email'
          onChange={onChangeEmail}
          error={state.errors?.email}
        />
        <InputForm
          label='パスワード'
          required
          password
          onChange={onChangePassword}
          error={state.errors?.password}
        />
        <LoadingButton
          fullWidth
          variant="contained"
          sx={{
            borderRadius: '24px',
            color: '#FFFFFF',
            elevation: 3,
            py: '8px',
            fontSize: 16,
          }}
          loadingIndicator="読込中..."
          loading={isLoading}
          onClick={onClickLogin}
        >
          ログイン
        </LoadingButton>
        <Typography
          component={Link}
          to={resetPasswordPath}
          variant="body2"
        >
          パスワードを忘れた方はこちら
        </Typography>
      </Stack>
    </Container>
  </AuthPageBase>
};

export default LoginPage;
