import { atom } from "recoil";
import { getWeek } from "date-fns";

export type WeeklyCalendarState = {
  /** 表示中の月 */
  currentMonth: Date;
  /** 表示中の週 */
  currentWeek: number;
  /** 選択中の日 */
  selectedDate: Date;
};

const initialState: WeeklyCalendarState = {
  currentMonth: new Date(),
  currentWeek: getWeek(new Date()),
  selectedDate: new Date(),
};

const weeklyCalendarStateAtom = atom({
  key: 'WeeklyCalendarState',
  default: initialState,
});

export default weeklyCalendarStateAtom;