import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
  Grid, Stack, Table, TableBody, TableCell, TableRow, Typography
} from "@mui/material";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import useReservationDetailDialogState from "./useReservationDetailDialogState";
import { genderIndex2string, time2string } from "../../../utils/converter";
import ReserveStatusChip from "../ReserveStatusChip";

/**
 * 予約詳細表示ダイアログのコンポーネント
 * @constructor
 * @group Components
 * @category features/HomePage
 */
const ReservationDetailDialog = () => {
  const { state, closeDialog } = useReservationDetailDialogState();
  
  if (!state.reservationInfo || !state.reservationFrame || !state.currentDate) {
    return null;
  }
  const { isOpen, reservationInfo, reservationFrame, currentDate } = state;
  return <Dialog open={isOpen} onClose={() => closeDialog()}>
    <DialogTitle fontWeight="bold">
      {`予約枠: ${format(currentDate, `yyyy年M月d日(E)`, { locale: ja })} ${time2string(reservationFrame.startAt)}~${time2string(reservationFrame.endAt)}`}
    </DialogTitle>
    <DialogContent dividers>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item xs={12} my={2}>
          <Stack spacing={6}>
            <Grid container justifyContent='space-between' alignItems='center' spacing={0}>
              <Grid item mb={2}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  受付情報
                </Typography>
              </Grid>
              <Grid item mb={2}>
                <Typography variant='caption'>
                  ステータス：<ReserveStatusChip reserveStatus={reservationInfo.reserveStatus}
                                           fontSize="subtitle1" />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Table sx={{ width: "100%", minWidth: "320px" }}>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                        診察ID
                      </TableCell>
                      <TableCell sx={{ width: "60%" }} align="right">
                        {reservationInfo.id}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                        診察日時
                      </TableCell>
                      <TableCell sx={{ width: "60%" }} align="right">
                        {`${format(new Date(reservationInfo.reservationDate), `yyyy年M月d日(E)`, { locale: ja })}
                      ${time2string({
                          hour: reservationInfo.startHour,
                          minute: reservationInfo.startMinute
                        })}~${time2string({ hour: reservationInfo.endHour, minute: reservationInfo.endMinute })}`}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                        当医院の受診
                      </TableCell>
                      <TableCell sx={{ width: "60%" }} align="right">
                        {reservationInfo?.hasPatientIdentification ? ' 診察券をお持ちの方' : '診察券をお持ちでない方'}
                      </TableCell>
                    </TableRow>
                    {reservationInfo?.hasPatientIdentification ? <TableRow>
                      <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                        診察券番号
                      </TableCell>
                      <TableCell sx={{ width: "60%" }} align="right">
                        {reservationInfo?.patientIdentification}
                      </TableCell>
                    </TableRow> : null}
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                        症状(自由入力)
                      </TableCell>
                      <TableCell sx={{ width: "60%" }} align="right"
                                 style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>
                        {reservationInfo?.reservationDetail}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                        予約日時
                      </TableCell>
                      <TableCell sx={{ width: "60%" }} align="right">
                        {format(reservationInfo.createdAt, `yyyy年M月d日 HH:mm`)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
            
            <Grid container justifyContent='start' alignItems='center' spacing={0}>
              <Grid item xs={12} mb={2}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  受診者情報
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Table sx={{ width: "100%", minWidth: "320px" }}>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                        お名前
                      </TableCell>
                      <TableCell sx={{ width: "60%" }} align="right">
                        {reservationInfo?.examineeFirstName} {reservationInfo?.examineeLastName}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                        よみがな
                      </TableCell>
                      <TableCell sx={{ width: "60%" }} align="right">
                        {reservationInfo?.examineeFirstKana} {reservationInfo?.examineeLastKana}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                        メールアドレス
                      </TableCell>
                      <TableCell sx={{ width: "60%" }} align="right">
                        {reservationInfo?.examineeEmail}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                        電話番号
                      </TableCell>
                      <TableCell sx={{ width: "60%" }} align="right">
                        {reservationInfo?.examineePhone}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                        性別
                      </TableCell>
                      <TableCell sx={{ width: "60%" }} align="right">
                        {genderIndex2string(reservationInfo?.examineeGender)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ width: "40%" }}>
                        生年月日
                      </TableCell>
                      <TableCell sx={{ width: "60%" }} align="right">
                        {format(new Date(reservationInfo.examineeBirthday), `yyyy年M月d日`)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={() => closeDialog()}>
        閉じる
      </Button>
    </DialogActions>
  </Dialog>
};

export default ReservationDetailDialog;