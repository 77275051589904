import { doc, getDoc, setDoc } from "firebase/firestore";
import { firestore } from "../firebase";
import INotificationSetting, { notificationSettingConverter } from "../interfaces/INotificationSetting";

/**
 * 指定したClinic IDの通知設定情報をセットする。
 * @param clinicId クリニックのユーザーID
 * @param notificationSetting 通知設定
 * @category repositories
 */
export const setNotificationSetting = async (clinicId: string, notificationSetting: INotificationSetting) => {
  const docRef = doc(firestore, 'notification_setting', clinicId).withConverter(notificationSettingConverter);
  return setDoc(docRef, notificationSetting);
};

/**
 * 指定したClinic IDの通知設定情報を取得する。
 * @param clinicId クリニックのユーザーID
 * @return {Promise<INotificationSetting | null>} データが見つかれば通知設定が返る。
 * @category repositories
 */
export const getNotificationSetting = async (clinicId: string): Promise<INotificationSetting | null> => {
  if (clinicId === '') {
    return null;
  }
  const docRef = doc(firestore, 'notification_setting', clinicId).withConverter(notificationSettingConverter);
  const snapshot = await getDoc(docRef);
  if (!snapshot.exists()) {
    return null;
  }
  return snapshot.data();
}
