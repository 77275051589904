import { atom } from "recoil";
import IReservationSetting from "../../../../interfaces/IReservationSetting";
import IReservationFrame from "../../../../interfaces/IReservationFrame";

export type BaseSettingsContentState = {
  /** 表示中の日付 */
  currentDate: Date;
  /** 表示中の曜日の予約枠一覧 */
  currentFrames: IReservationFrame[];
  /** 初回取得時の曜日の予約枠一覧 */
  initFrames: IReservationFrame[];
  /** 表示中の曜日の予約枠設定 */
  currentSetting: IReservationSetting | undefined;
  /** 初回取得時の曜日の予約枠設定 */
  initSetting: IReservationSetting | undefined;
  /** コピー中の曜日の予約枠設定 */
  copySetting: IReservationSetting | undefined;
  /** コピー中の曜日の予約枠一覧 */
  copyFrames: IReservationFrame[] | undefined;
};

const initialState: BaseSettingsContentState = {
  currentDate: new Date(),
  currentFrames: [],
  initFrames: [],
  currentSetting: undefined,
  initSetting: undefined,
  copySetting: undefined,
  copyFrames: undefined,
};

const reservationBaseSettingsContentStateAtom = atom({
  key: 'BaseSettingsContentState',
  default: initialState,
});

export default reservationBaseSettingsContentStateAtom;
