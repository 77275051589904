import { useRecoilState } from "recoil";
import React, { useCallback } from "react";
import reservationSettingsPageStateAtom from "../../recoil/features/ReservationSettingsPage";
import { ReservationSettingsPageTabsType } from "../../recoil/features/ReservationSettingsPage/atom";

/**
 * 予約枠設定ページの状態管理やロジックをまとめたHooks
 * @group Components
 * @category features/ReservationSettingsPage
 */
const useReservationSettingsPageState = () => {
  const [ state, setState ] = useRecoilState(reservationSettingsPageStateAtom);
  
  /**
   * タブの変更を状態に反映させる。
   */
  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: ReservationSettingsPageTabsType) => {
      setState((prev) => ({ ...prev, currentTab: newValue }))
    }, [ setState ]
  );
  
  return { state, handleTabChange };
};

export default useReservationSettingsPageState;