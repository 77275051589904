import { Box, Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import { addMonths, format, isAfter } from "date-fns";
import { ja } from "date-fns/locale";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useMemo } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ReservationFrameItem from "../ReservationFrameItem";
import ReservationListItem from "../ReservationListItem/ReservationListItem";
import useClinicHomePageState from "../useClinicHomePageState";
import { getNextDate, getPrevDate } from "../../../utils/datetime";
import { time2string } from "../../../utils/converter";
import useListContentState from "./useListContentState";
import ReservationDetailDialog from "../ReservationDetailDialog/ReservationDetailDialog";
import useBreakpoint from "../../../utils/useBreakPoint";
import ReservationListMobileSizeItem from "../ReservationListItem/ReservationListMobileSizeItem";
import { numOfPeopleStr } from "../../../utils/points";

/**
 * 予約枠アイテム＆予約アイテムのリスト表示のコンポーネント
 * @constructor
 * @group Components
 * @category features/HomePage
 */
const ListContent = () => {
  const { breakpoint } = useBreakpoint();
  const {
    state: {
      currentDate,
      isOpenDatePicker,
      anchorEl,
    },
    setState,
    onChangeCurrentDate
  } = useClinicHomePageState();
  
  const {
    clinicInfo,
    getReservationsByStartTime,
    dayFrameSumPeople,
    daySumPeople,
    frameSumPoints,
    reservationSetting,
    reservationFrames,
  } = useListContentState();
  
  /**
   * 患者側に予約枠を表示しているかどうか
   */
  const isReserveFramesShow = useMemo(() => {
    if (!(reservationSetting?.isReserveFramesShow ?? true)) {
      return false;
    }
    if (!clinicInfo) {
      return false;
    }
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const availableReserveDate = addMonths(today, parseInt(clinicInfo.reservationPeriod, 10));
    return !isAfter(currentDate, availableReserveDate);
  }, [ clinicInfo, currentDate, reservationSetting?.isReserveFramesShow ])
  
  /**
   * レスポンシブ対応
   */
  const renderHeader = () => {
    switch (breakpoint) {
      case "xs":
      case "sm":
      case "md":
        return <Stack>
          <Stack
            direction='column'
            spacing={1}
            alignItems={reservationSetting && reservationSetting.isOpen ? "center" : "end"}
          >
            <Stack direction='row' alignItems='center'>
              <Typography variant='h2' fontSize='1.75em' fontWeight='bold'>
                {`${format(currentDate, `yyyy年M月d日(E)`, { locale: ja })}`}
              </Typography>
              <DatePicker
                open={isOpenDatePicker}
                onChange={(value) => {
                  setState((prev) => ({ ...prev, isOpenDatePicker: false }))
                  if (value) {
                    onChangeCurrentDate(value)
                  }
                }}
                onClose={() => {
                  setState((prev) => ({ ...prev, isOpenDatePicker: false }))
                }}
                PopperProps={{
                  placement: "bottom",
                  anchorEl,
                }}
                renderInput={() => <IconButton
                  onClick={(event) => {
                    setState((prev) => ({ ...prev, isOpenDatePicker: true, anchorEl: event.currentTarget }))
                  }}>
                  <CalendarMonthIcon />
                </IconButton>}
                value={currentDate}
              />
            </Stack>
            {reservationSetting && reservationSetting.isOpen
              ?
              <Stack direction='row' spacing={1}>
                <Box
                  sx={{ border: 1, borderColor: "primary", height: "28px", paddingX: "6px", borderRadius: '8px' }}
                  justifyItems="center"
                  alignItems="center"
                  textAlign="center"
                >
                  <Typography variant='caption' fontSize={12} lineHeight="28px" fontWeight="bold" color="primary">
                    診療日
                  </Typography>
                </Box>
                <Typography variant='caption' lineHeight="29px" color="primary">
                  {`${time2string(reservationSetting.openAt)}~${time2string(reservationSetting.closeAt)} (${numOfPeopleStr(daySumPeople)} / ${numOfPeopleStr(dayFrameSumPeople)})`}
                </Typography>
              </Stack>
              : <Box
                sx={{ border: 1, borderColor: '#898989', height: "28px", paddingX: "6px", borderRadius: '8px' }}
                justifyItems="center"
                alignItems="center"
                textAlign="center"
              >
                <Typography variant='body2' lineHeight="28px" fontWeight="bold" color="#898989">
                  休診日
                </Typography>
              </Box>}
          </Stack>
          <Grid container justifyContent='space-around' mt={2}>
            <Grid item>
              <Button
                variant='contained'
                onClick={
                  () => onChangeCurrentDate(getPrevDate(currentDate))
                }
                sx={{ fontSize: breakpoint === 'md' ? 20 : 14 }}
              >
                ≺ 前の日へ
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                onClick={
                  () => onChangeCurrentDate(getNextDate(currentDate))
                }
                sx={{ fontSize: breakpoint === 'md' ? 20 : 14 }}
              >
                次の日へ ≻
              </Button>
            </Grid>
          </Grid>
        </Stack>
      case "lg":
      case "xl":
      default:
        return <Grid container justifyContent='space-around' alignItems="center">
          <Grid item>
            <Button
              variant='contained'
              sx={{ fontSize: 20 }}
              onClick={
                () => onChangeCurrentDate(getPrevDate(currentDate))
              }
              startIcon={<ArrowBackIosIcon />}
            >
              前の日へ
            </Button>
          </Grid>
          <Grid item px={4} py={1} sx={{ backgroundColor: "info", borderRadius: '16px' }}>
            <Stack
              direction='column'
              spacing={1}
              alignItems={reservationSetting && reservationSetting.isOpen ? "center" : "end"}
            >
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography variant='h2' fontSize='2.0em' fontWeight='bold'>
                  {`${format(currentDate, `yyyy年M月d日(E)`, { locale: ja })}`}
                </Typography>
                <DatePicker
                  open={isOpenDatePicker}
                  onChange={(value) => {
                    setState((prev) => ({ ...prev, isOpenDatePicker: false }))
                    if (value) {
                      onChangeCurrentDate(value)
                    }
                  }}
                  onClose={() => {
                    setState((prev) => ({ ...prev, isOpenDatePicker: false }))
                  }}
                  PopperProps={{
                    placement: "bottom",
                    anchorEl,
                  }}
                  renderInput={() => <IconButton
                    size='medium'
                    onClick={(event) => {
                      setState((prev) => ({ ...prev, isOpenDatePicker: true, anchorEl: event.currentTarget }))
                    }}>
                    <CalendarMonthIcon fontSize='large' />
                  </IconButton>}
                  value={currentDate}
                />
              </Stack>
              {reservationSetting && reservationSetting.isOpen
                ?
                <Stack direction='row' spacing={1}>
                  <Box
                    sx={{ border: 1, borderColor: "primary", height: "28px", paddingX: "6px", borderRadius: '8px' }}
                    justifyItems="center"
                    alignItems="center"
                    textAlign="center"
                  >
                    <Typography variant='body2' lineHeight="28px" fontWeight="bold" color="primary">
                      診療日
                    </Typography>
                  </Box>
                  <Typography variant='subtitle1' lineHeight="29px" color="primary">
                    {`${time2string(reservationSetting.openAt)}~${time2string(reservationSetting.closeAt)} (${numOfPeopleStr(daySumPeople)} / ${numOfPeopleStr(dayFrameSumPeople)})`}
                  </Typography>
                </Stack>
                : <Box
                  sx={{ border: 1, borderColor: '#898989', height: "28px", paddingX: "6px", borderRadius: '8px' }}
                  justifyItems="center"
                  alignItems="center"
                  textAlign="center"
                >
                  <Typography variant='body2' lineHeight="28px" fontWeight="bold" color="#898989">
                    休診日
                  </Typography>
                </Box>}
            </Stack>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              sx={{ fontSize: 20 }}
              onClick={
                () => onChangeCurrentDate(getNextDate(currentDate))
              }
              endIcon={<ArrowForwardIosIcon />}
            >
              次の日へ
            </Button>
          </Grid>
        </Grid>
    }
  };
  
  return (
    <>
      <Stack spacing={4}>
        {renderHeader()}
        <Stack direction='column' spacing={4}>
          {
            reservationFrames && reservationFrames?.length > 0 ?
              (reservationFrames.map(
                (reservationFrame) =>
                  <div key={`${reservationFrame.startAt.hour}:${reservationFrame.startAt.minute}`}>
                    <ReservationFrameItem
                      key={`${reservationFrame.startAt.hour}:${reservationFrame.startAt.minute}`}
                      reservationFrame={reservationFrame}
                      sumPoints={frameSumPoints(reservationFrame.startAt)}
                      date={currentDate}
                      isReserveFramesShow={isReserveFramesShow}
                    />
                    <Stack direction='column' spacing={0} pt={2}>
                      {
                        getReservationsByStartTime(reservationFrame.startAt).length > 0
                          ?
                          getReservationsByStartTime(reservationFrame.startAt).map(
                            (reservation) => {
                              switch (breakpoint) {
                                case "xs":
                                  return <ReservationListMobileSizeItem
                                    key={reservation.id}
                                    reservationInfo={reservation}
                                    reservationFrame={reservationFrame}
                                    currentDate={currentDate}
                                  />
                                case "sm":
                                case "md":
                                case "lg":
                                case "xl":
                                default:
                                  return <ReservationListItem
                                    key={reservation.id}
                                    reservationInfo={reservation}
                                    reservationFrame={reservationFrame}
                                    currentDate={currentDate}
                                  />
                              }
                            }
                          ) :
                          <Typography variant='h6' color="inherit" mt={1}>
                            予約情報がありません。
                          </Typography>
                      }
                    </Stack>
                  </div>
              )) :
              <Typography variant='h6' color='#898989' mt={2}>
                予約枠がありません。
              </Typography>
          }
        </Stack>
      </Stack>
      <ReservationDetailDialog />
    </>
  )
}

export default ListContent;