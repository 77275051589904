import { atom } from "recoil";
import IClinicInformation from "../../../../interfaces/IClinicInformation";

export type CurrentAuthState = {
  /** クリニック基本設定 */
  clinicInfo: IClinicInformation | undefined;
};

const initialState: CurrentAuthState = {
  clinicInfo: undefined,
};

const currentAuthStateAtom = atom({
  key: 'CurrentAuthState',
  default: initialState,
});

export default currentAuthStateAtom;