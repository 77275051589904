import { useRecoilState } from "recoil";
import { useCallback, useEffect, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAuthUser } from "@react-query-firebase/auth";
import { useNavigate } from "react-router";
import { FirebaseError } from "firebase/app";
import basicSettingsPageStateAtom from "../../recoil/features/BasicSettingsPage";
import useResultAlertState from "../../components/ResultAlert/useResultAlertState";
import IClinicInformation, { validateClinicInformation } from "../../interfaces/IClinicInformation";
import { auth } from "../../firebase";
import { getClinicInformation, updateClinicInformation } from "../../repositories/clinicRepository";
import getAddressByPostalCodeApi from "../../repositories/axiosRepository";
import { firebaseError2ErrorMessage } from "../../utils/converter";

/**
 * クリニック基本情報ページの状態管理やロジックをまとめたHooks
 * @group Components
 * @category features/BasicSettingsPage
 */
const useBasicSettingsPageState = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  
  // ログイン中のユーザー情報の監視
  const clinicUser = useAuthUser([ 'user' ], auth);
  const [ state, setState ] = useRecoilState(basicSettingsPageStateAtom);
  const { openAlert } = useResultAlertState();
  
  const { data: initialClinicInformation } = useQuery(
    [ 'getClinicInformation', clinicUser.data?.uid ],
    () => getClinicInformation(clinicUser.data?.uid ?? '')
  );
  
  useEffect(() => {
    if (initialClinicInformation) {
      setState((prev) => ({
        ...prev,
        clinicName: initialClinicInformation.clinicName,
        postalCode: initialClinicInformation.postalCode,
        address: initialClinicInformation.address,
        phoneNumber: initialClinicInformation.phoneNumber,
        departments: initialClinicInformation.departments,
        freeInputs: initialClinicInformation.freeInputs,
        reservationPeriod: initialClinicInformation.reservationPeriod,
        isAvailableSameDayReserve: initialClinicInformation.isAvailableSameDayReserve ? "enable" : "disable",
        sameDayReservePeriod: initialClinicInformation.sameDayReservePeriod,
        calendarStart: initialClinicInformation.calendarStart,
      }))
    }
  }, [ initialClinicInformation, setState ]);
  
  // 入力値の状態をonChangeで状態に保持
  /**
   * クリニック名の変更を状態に反映させる。
   */
  const onChangeClinicName = useCallback((clinicName: string) => {
    setState((prev) => ({ ...prev, clinicName }))
  }, [ setState ]);
  /**
   * 郵便番号の変更を状態に反映させる。
   */
  const onChangePostalCode = useCallback((postalCode: string) => {
    setState((prev) => ({ ...prev, postalCode }))
  }, [ setState ]);
  /**
   * 住所の変更を状態に反映させる。
   */
  const onChangeAddress = useCallback((address: string) => {
    setState((prev) => ({ ...prev, address }))
  }, [ setState ]);
  /**
   * 電話番号を状態に反映させる。
   */
  const onChangePhoneNumber = useCallback((phoneNumber: string) => {
    setState((prev) => ({ ...prev, phoneNumber }))
  }, [ setState ]);
  /**
   * 診療科目を状態に反映させる。
   */
  const onChangeDepartments = useCallback((departments: string) => {
    setState((prev) => ({ ...prev, departments }))
  }, [ setState ]);
  /**
   * 自由入力欄の内容を状態に反映させる。
   */
  const onChangeFreeInput = useCallback((freeInput: string) => {
    setState((prev) => ({ ...prev, freeInputs: freeInput }))
  }, [ setState ]);
  /**
   * 何か月先の予約を受け付けるかの設定を状態に反映させる。
   */
  const onChangeReservationPeriod = useCallback((reservationPeriod: string) => {
    setState((prev) => ({ ...prev, reservationPeriod }))
  }, [ setState ])
  /**
   * 当日予約を受け付けるかの設定を状態に反映させる。
   */
  const onChangeIsAvailableSameDayReserve = useCallback((isAvailable: string) => {
    setState((prev) => ({ ...prev, isAvailableSameDayReserve: isAvailable }))
  }, [ setState ])
  /**
   * 当日予約を受け付ける場合の締切時間（分）を状態に反映させる。
   */
  const onChangeSameDayReservePeriod = useCallback((period: string) => {
    setState((prev) => ({ ...prev, sameDayReservePeriod: period }))
  }, [ setState ])
  /**
   * 日曜日スタートか、月曜日スタートかの設定を状態に反映させる。
   */
  const onChangeCalendarStart = useCallback((calendarStart: number) => {
    setState((prev) => ({ ...prev, calendarStart }))
  }, [ setState ])
  
  /**
   * クリニック情報のリフレッシュトリガー
   * useQueryのkeyにisRefreshを設定し、更新のトリガーとする。
   */
  const onClickRefresh = () => {
    setState((prev) => ({ ...prev, errors: undefined }));
    void queryClient.resetQueries([ 'getClinicInformation', clinicUser.data?.uid ])
  }
  
  /**
   * 郵便番号から住所をセットする。
   */
  const getAddress = useCallback(async () => {
    const addressStr = await getAddressByPostalCodeApi(state.postalCode)
    if (addressStr === '') {
      openAlert('error', '郵便番号からの住所取得に失敗しました。');
      return
    }
    setState((prev) => ({ ...prev, address: addressStr }));
    openAlert('success', '郵便番号から住所を取得しました。');
  }, [ openAlert, setState, state.postalCode ])
  
  /**
   * APIから住所取得のクエリ定義
   */
  const {
    isLoading: isLoadingGetAddress,
    isRefetching: isRefetchingGetAddress,
    refetch: refetchGetAddress
  } = useQuery('GetAddress', () => getAddress(), {
    enabled: false,
    suspense: false,
  });
  
  /**
   * 郵便番号から住所取得ボタンクリック時の処理
   */
  const getAddressByPostalCode = useCallback(() => {
    void refetchGetAddress();
  }, [ refetchGetAddress ])
  
  /**
   * クリニック情報の更新を保存する。
   */
  const saveSettings = useCallback(async () => {
    if (!clinicUser.data?.uid) {
      navigate('/login');
      throw new Error("再認証してからお試しください。");
    }
    const clinicInfo: IClinicInformation = {
      id: clinicUser.data?.uid,
      clinicName: state.clinicName,
      postalCode: state.postalCode,
      address: state.address,
      phoneNumber: state.phoneNumber,
      departments: state.departments,
      freeInputs: state.freeInputs,
      reservationPeriod: state.reservationPeriod,
      isAvailableSameDayReserve: state.isAvailableSameDayReserve === "enable",
      sameDayReservePeriod: state.sameDayReservePeriod,
      calendarStart: state.calendarStart,
      clinicStatus: 2,
      isInitialized: true,
    };
    const errors = validateClinicInformation(clinicInfo);
    if (errors) {
      setState((prev) => ({ ...prev, errors }))
      throw new Error("入力情報を確認してください。");
    }
    setState((prev) => ({ ...prev, errors: undefined }))
    await updateClinicInformation(clinicUser.data.uid, clinicInfo);
  }, [ clinicUser.data?.uid, navigate, setState, state ]);
  
  /**
   * クリニック情報更新のクエリ定義
   */
  const saveClinicSettingMutate = useMutation<void, Error>(
    () => saveSettings(),
    {
      onSuccess: () => {
        void queryClient.resetQueries([ 'getClinicInformation', clinicUser.data?.uid ])
        openAlert('success', '設定を保存しました。');
      },
      onError: (error) => {
        if (error instanceof FirebaseError) {
          const message = firebaseError2ErrorMessage(error);
          openAlert("error", message);
          return;
        }
        openAlert('error', error.message);
      }
    }
  )
  
  /**
   * 保存ボタンクリック時の処理
   */
  const onClickSaveSettings = useCallback(() => {
    void saveClinicSettingMutate.mutate();
  }, [ saveClinicSettingMutate ]);
  
  /**
   * クリニック設定ページ上でのローディング状態
   */
  const isSaving = useMemo(() => saveClinicSettingMutate.isLoading || isLoadingGetAddress || isRefetchingGetAddress,
    [ isLoadingGetAddress, isRefetchingGetAddress, saveClinicSettingMutate.isLoading ]
  );
  
  return {
    state,
    isSaving,
    onChangeClinicName,
    onChangePostalCode,
    onChangeAddress,
    onChangePhoneNumber,
    onChangeDepartments,
    onChangeFreeInput,
    onChangeReservationPeriod,
    onChangeIsAvailableSameDayReserve,
    onChangeSameDayReservePeriod,
    onChangeCalendarStart,
    onClickSaveSettings,
    getAddressByPostalCode,
    onClickRefresh,
  }
};

export default useBasicSettingsPageState;
