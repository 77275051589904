import {
  Container,
  Stack,
  Typography,
  AppBar,
  Toolbar,
  Box,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel, Radio, Button
} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import LoadingButton from "@mui/lab/LoadingButton";
import { useMemo } from "react";
import InputForm from "../../../components/InputForm/InputForm";
import AuthPageBase from "../AuthPageBase";
import useRegisterPageState from "./useRegisterPageState";
import RegisterSideArtSvg from "../../../assets/register_side_art.svg";
import useBreakpoint from "../../../utils/useBreakPoint";

/**
 * クリニック初回登録ページ
 * @constructor
 * @group Components
 * @category features/auth
 */
const RegisterPage = () => {
  const { breakpoint } = useBreakpoint();
  const {
    state,
    isSaving,
    onChangeClinicName,
    onChangePostalCode,
    onChangeAddress,
    onChangePhoneNumber,
    onChangeDepartments,
    onChangeFreeInput,
    onChangeReservationPeriod,
    onChangeCalendarStart,
    onChangeIsAvailableSameDayReserve,
    onChangeSameDayReservePeriod,
    getAddressByPostalCode,
    onClickRegister,
    onClickLogOut,
  } = useRegisterPageState();
  
  const bottomBarContent = useMemo(() => {
    const saveButton = <LoadingButton
      fullWidth
      variant="contained"
      sx={{
        borderRadius: '24px',
        color: '#FFFFFF',
        elevation: 3,
        py: '8px',
        fontSize: 16,
      }}
      loadingIndicator="保存中..."
      loading={isSaving}
      onClick={onClickRegister}
    >
      保存する
    </LoadingButton>
    
    switch (breakpoint) {
      case "xs":
      case "sm":
        return <Grid container direction='row'>
          <Grid xs={12} spacing={6}>
            <Container maxWidth='xs'>
              {saveButton}
            </Container>
          </Grid>
          <Box sx={{ flexGrow: 1 }} />
        </Grid>;
      case "md":
      case "lg":
      case "xl":
      default:
        return <Grid container direction='row'>
          <Grid xs={6} spacing={6}>
            <Container maxWidth='xs'>
              {saveButton}
            </Container>
          </Grid>
          <Box sx={{ flexGrow: 1 }} />
        </Grid>;
    }
  }, [ breakpoint, isSaving, onClickRegister ])
  
  return <AuthPageBase row={6} svg={RegisterSideArtSvg}>
    <Container maxWidth='xs'>
      <Stack direction='column' spacing={4} mb={12}>
        
        <div style={{ textAlign: 'start', marginTop: "16px" }}>
          <Button variant='outlined' onClick={onClickLogOut}>
            <LogoutIcon />
          </Button>
        </div>
        
        <Typography variant='h4' textAlign="start">
          基本設定
        </Typography>
        <Typography variant='body1' textAlign='start'>
          基本情報の設定を行います。<br />この設定は後から変更することが可能です。
        </Typography>
        
        <Stack direction='column' spacing={2} mb={6}>
          <Stack direction='row' justifyContent="start" alignItems="center">
            <Typography variant='h5' fontWeight='bold' textAlign='start'>
              名称
            </Typography>
            <Typography variant='h5' component='div'
                        sx={{ lineHeight: "14px", height: "16px", color: 'red', paddingLeft: "2px" }}>
              *
            </Typography>
          </Stack>
          <Typography variant='body1' textAlign='start'>
            名称を設定します。
          </Typography>
          <InputForm
            label='名称'
            required
            maxLines={1}
            value={state.clinicName}
            onChange={onChangeClinicName}
            error={state.errors?.clinicName} />
        </Stack>
        
        <Stack direction='column' spacing={2} mb={6}>
          <Stack direction='row' justifyContent="start" alignItems="center">
            <Typography variant='h5' fontWeight='bold' textAlign='start'>
              住所
            </Typography>
            <Typography variant='h5' component='div'
                        sx={{ lineHeight: "14px", height: "16px", color: 'red', paddingLeft: "2px" }}>
              *
            </Typography>
          </Stack>
          <Typography variant='body1' textAlign='start'>
            住所（所在地）を設定します。
          </Typography>
          <Stack direction='row' justifyContent="start" alignItems="center" spacing={2}>
            <InputForm
              label='郵便番号'
              maxLines={1}
              type='number'
              value={state.postalCode}
              onChange={onChangePostalCode}
              error={state.errors?.postalCode}
              startAdornmentStr="〒"
            />
            <LoadingButton
              variant='outlined'
              loading={isSaving}
              sx={{ borderRadius: '8px', fontSize: "14px" }}
              onClick={getAddressByPostalCode}
            >
              郵便番号から住所取得
            </LoadingButton>
          </Stack>
          <InputForm
            label='住所'
            multiline
            minLines={2}
            value={state.address}
            onChange={onChangeAddress}
            error={state.errors?.address}
          />
        </Stack>
        
        <Stack direction='column' spacing={2} mb={6}>
          <Stack direction='row' justifyContent="start" alignItems="center">
            <Typography variant='h5' fontWeight='bold' textAlign='start'>
              電話番号
            </Typography>
            <Typography variant='h5' component='div'
                        sx={{ lineHeight: "14px", height: "16px", color: 'red', paddingLeft: "2px" }}>
              *
            </Typography>
          </Stack>
          <Typography variant='body1' textAlign='start'>
            電話番号を設定します。
          </Typography>
          <InputForm
            label='電話番号'
            type='tel'
            maxLines={1}
            value={state.phoneNumber}
            onChange={onChangePhoneNumber}
            error={state.errors?.phoneNumber}
          />
        </Stack>
        
        <Stack direction='column' spacing={2} mb={6}>
          <Typography variant='h5' fontWeight='bold' textAlign='start'>診療科目</Typography>
          <Typography variant='body1' textAlign='start'>
            診療科目を設定します。
          </Typography>
          <InputForm
            label='診療科目'
            value={state.departments}
            onChange={onChangeDepartments}
            error={state.errors?.departments}
            maxLines={1}
          />
        </Stack>
        
        <Stack direction='column' spacing={2} mb={6}>
          <Stack direction='row' justifyContent="start" alignItems="center">
            <Typography variant='h5' fontWeight='bold' textAlign='start'>
              予約可能期間
            </Typography>
            <Typography variant='h5' component='div'
                        sx={{ lineHeight: "14px", height: "16px", color: 'red', paddingLeft: "2px" }}>
              *
            </Typography>
          </Stack>
          <Typography variant='body1' textAlign='start'>
            何か月先まで予約を受け付けるかを設定します。
          </Typography>
          <InputForm
            label='予約可能期間'
            type='number'
            value={state.reservationPeriod}
            onChange={onChangeReservationPeriod}
            error={state.errors?.reservationPeriod}
            maxLines={1}
            endAdornmentStr="ヵ月"
          />
        </Stack>
        
        <Stack direction='column' spacing={2} mb={6}>
          <Typography variant='h5' fontWeight='bold' textAlign='start'>当日予約受付</Typography>
          <Typography variant='body1' textAlign='start'>
            当日の予約を受け付けるかどうかを設定します。
          </Typography>
          <FormControl>
            <RadioGroup
              aria-labelledby="same-day-reserve-radio"
              defaultValue={state.isAvailableSameDayReserve}
              name="same-day-reserve-radio"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChangeIsAvailableSameDayReserve(event.target.value);
              }}
            >
              <FormControlLabel value="enable" control={<Radio />} label="当日予約を受け付ける" />
              <FormControlLabel value="disable" control={<Radio />} label="当日予約は受け付けない" />
            </RadioGroup>
          </FormControl>
        </Stack>
        
        <Stack direction='column' spacing={2} mb={6}>
          <Stack direction='row' justifyContent="start" alignItems="center">
            <Typography variant='h5' fontWeight='bold' textAlign='start'>
              当日予約受付締切時間
            </Typography>
            <Typography variant='h5' component='div'
                        sx={{ lineHeight: "14px", height: "16px", color: 'red', paddingLeft: "2px" }}>
              *
            </Typography>
          </Stack>
          <Typography variant='body1' textAlign='start'>
            当日予約を受け付ける場合に、予約枠の終了時間から何分前まで当日予約を受け付けるかを設定します。
          </Typography>
          <InputForm
            label='当日予約受付締切時間'
            type='number'
            value={state.sameDayReservePeriod}
            onChange={onChangeSameDayReservePeriod}
            error={state.errors?.sameDayReservePeriod}
            maxLines={1}
            endAdornmentStr="分"
          />
        </Stack>
        
        <Stack direction='column' spacing={2} mb={6}>
          <Stack direction='row' justifyContent="start" alignItems="center">
            <Typography variant='h5' fontWeight='bold' textAlign='start'>
              カレンダー開始曜日設定
            </Typography>
            <Typography variant='h5' component='div'
                        sx={{ lineHeight: "14px", height: "16px", color: 'red', paddingLeft: "2px" }}>
              *
            </Typography>
          </Stack>
          <Typography variant='body1' textAlign='start'>
            カレンダー表示の開始曜日を設定します。
          </Typography>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={state.calendarStart}
              value={state.calendarStart}
              name="radio-buttons-group"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChangeCalendarStart(parseInt(event.target.value, 10))
              }}
            >
              <FormControlLabel value={0} control={<Radio />} label="日曜日" />
              <FormControlLabel value={1} control={<Radio />} label="月曜日" />
            </RadioGroup>
          </FormControl>
        </Stack>
        
        <Stack direction='column' spacing={2} mb={2}>
          <Typography variant='h5' fontWeight='bold' textAlign='start'>自由入力欄</Typography>
          <Typography variant='body1' textAlign='start'>
            アクセス情報や、駐車場情報など、患者様にお知らせしたい内容を設定します。
          </Typography>
          <InputForm
            label='自由入力欄'
            multiline
            minLines={3}
            value={state.freeInputs}
            onChange={onChangeFreeInput}
            error={state.errors?.freeInputs}
          />
        </Stack>
      </Stack>
    </Container>
    <AppBar position='fixed' color='secondary' sx={{ top: 'auto', bottom: 0, }}>
      <Toolbar>
        {bottomBarContent}
      </Toolbar>
    </AppBar>
  </AuthPageBase>
}

export default RegisterPage;
