import { useRecoilState } from "recoil";
import { useAuthSendPasswordResetEmail } from "@react-query-firebase/auth";
import { useCallback, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import resetPasswordPageStateAtom from "../../../recoil/features/auth/ResetPasswordPage";
import { auth } from "../../../firebase";
import { validateEmail } from "../../../utils/validator";
import useResultAlertState from "../../../components/ResultAlert/useResultAlertState";

/**
 * パスワードリセットページの状態管理やロジックをまとめたHooks
 * @group Components
 * @category features/auth
 */
const useResetPasswordPageState = () => {
  const [ state, setState ] = useRecoilState(resetPasswordPageStateAtom);
  const resetPasswordMutation = useAuthSendPasswordResetEmail(auth);
  const { openAlert } = useResultAlertState();
  
  /**
   * メールアドレスの変更を状態に反映させる。
   */
  const onChangeEmail = useCallback((email: string) => {
    setState((prev) => ({ ...prev, email }))
  }, [ setState ]);
  
  /**
   * パスワードリセットに関するメール送信の処理
   */
  const sendResetPasswordEmail = useCallback(async () => {
    const emailValidateError = validateEmail(state.email);
    setState((prev) => ({ ...prev, emailValidateError }))
    if (emailValidateError) {
      openAlert('error', '登録時に使用したメールアドレスを入力してください。');
      return;
    }
    await resetPasswordMutation.mutateAsync({
      email: state.email,
    });
  }, [ openAlert, resetPasswordMutation, setState, state.email ]);
  
  const {
    refetch,
    error: passwordResetError,
    isSuccess: isSuccessSendResetPasswordEmail,
    isLoading: isLoadingSendResetPasswordEmail,
    isRefetching: isRefetchingSendResetPasswordEmail
  } = useQuery('sendResetPasswordEmail', () => sendResetPasswordEmail(), {
    enabled: false,
    suspense: false,
  });
  
  /**
   * 送信するボタンをクリック時の処理
   */
  const onClickSendResetPasswordEmail = useCallback(() => {
    void refetch();
  }, [ refetch ]);
  
  useEffect(() => {
    if (passwordResetError) {
      openAlert('error', 'パスワードリセットのメール送信に失敗しました。');
      return
    }
    if (isSuccessSendResetPasswordEmail) {
      openAlert('success', 'パスワードリセットに関するメールが送信されました。');
    }
  }, [passwordResetError, openAlert, isSuccessSendResetPasswordEmail]);
  
  const isLoading = useMemo(
    () => isLoadingSendResetPasswordEmail || isRefetchingSendResetPasswordEmail,
    [ isLoadingSendResetPasswordEmail, isRefetchingSendResetPasswordEmail ]
  );
  
  return { state, isLoading, onChangeEmail, onClickSendResetPasswordEmail };
};

export default useResetPasswordPageState;