import { CircularProgress } from "@mui/material";
import { Suspense } from "react";
import BasicSettingsPageLayout from "./BasicSettingsPageLayout";

/**
 * クリニック基本情報の設定ページ
 * @group Components
 * @category features/BasicSettingsPage
 */
const ClinicBasicSettingsPage = () => <Suspense
  fallback={<CircularProgress />}>
  <BasicSettingsPageLayout />
</Suspense>

export default ClinicBasicSettingsPage;