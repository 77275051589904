import { useEffect, useState } from "react";
import { format } from 'date-fns';
import { ja } from "date-fns/locale";

/**
 * 現在時間表示コンポーネント制御
 * @group Components
 * @category components
 */
const useTimeTextState = () => {
  const [ time, updateTime ] = useState(new Date())
  useEffect(() => {
    const timeoutId = setTimeout(() => updateTime(new Date()), 500);
    return () => clearTimeout(timeoutId)
  }, [ time ]);
  
  return format(time, `yyyy年M月d日(E)  H時m分`,{ locale: ja })
};

export default useTimeTextState;